import React from "react";
import { get, isEmpty } from "lodash";

import { currencyToNumber, formatDollar } from "../../../shared/utility";
import { calculateAdjustedCommission } from "../RSheetsLogic";

// This is used to render the Commission Adjustments section for FullForm
const CommissionAdjustmentTable = (props) => {
    const { data, commissionField = "flat_fee_commission_amount" } = props;

    const commission_adjustment = get(data, "commission_adjustment", null);
    const commission_adjustment_details = get(data, "commission_adjustment_details", []);
    const hasCommissionAdjustments = commission_adjustment === "Yes" && !isEmpty(commission_adjustment_details);

    let result = [];
    if (hasCommissionAdjustments) {
        commission_adjustment_details.forEach((item, index) => {
            result.push(
                <p key={`${index}-adjustment`}>
                    #{index + 1} - {item.option} - Adjusted Commission: <strong>{item.value}</strong>
                </p>
            );
        });

        result.push(
            <p key="p-11">
                Total Adjusted Commission:{" "}
                <span className="bold">
                    {formatDollar(currencyToNumber(get(data, commissionField, 0)) + calculateAdjustedCommission(data))}
                </span>
            </p>
        );

        return (
            <>
                <div className="the-table">
                    <table>
                        <tbody>
                            <tr>
                                <td className="col-100 no-bt no-br no-bl no-bb">
                                    <h4 style={{ textDecoration: "underline" }}>COMMISSION ADJUSTMENTS</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="the-table">
                    <table>
                        <tbody>
                            <tr>
                                <td className="col-100">{result}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br />
                <br />
            </>
        );
    } else {
        return null;
    }
};

export default CommissionAdjustmentTable;

// This is used to render Commission Adjustments section in HTML form
export const renderCommissionAdjustmentsHTML = (data, commissionField = "flat_fee_commission_amount") => {

    const commission_adjustment = get(data, "commission_adjustment", null);
    const commission_adjustment_details = get(data, "commission_adjustment_details", []);
    const hasCommissionAdjustments = commission_adjustment === "Yes" && !isEmpty(commission_adjustment_details);

    let result = [];
    if (hasCommissionAdjustments) {
        commission_adjustment_details.forEach((item, index) => {
            result.push(
                `<p>
                    #${index + 1} - ${item.option} - Adjusted Commission: <strong>${item.value}</strong>
                </p>`
            );
        });

        result.push(`<p>
            Total Adjusted Commission:
            <span class="bold">${formatDollar(
                currencyToNumber(get(data, commissionField, 0)) + calculateAdjustedCommission(data)
            )}</span>
        </p>`);

        return `
        <br />
        <br />
        <div class="the-table">
            <table>
                <tbody>
                    <tr>
                        <td class="col-100 no-bt no-br no-bl no-bb">
                            <h4 class="underlined">COMMISSION ADJUSTMENTS</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="the-table">
            <table>
                <tbody>
                    <tr>
                        <td class="col-100">
                            ${result.join(" ")}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />
        <br />
        `;
    } else {
        return "";
    }
};
