import React, { useState, useEffect, useRef } from 'react';
import Close from '@material-ui/icons/Close';
import Notification from 'components/Notification/Notification';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { clone } from 'shared/utility';
import { getUsername } from 'shared/utility';
import { getUserID } from 'shared/authValidation';
import { List, ListItem, ListItemText, ListItemIcon, ListItemAvatar } from '@material-ui/core';
import { IconButton, TextField } from '@material-ui/core';
import ClipLoader from "react-spinners/ClipLoader";
import download from 'downloadjs';

const PhotosForm = (props) => {
    const { dataObj, createCrmFile, downloadedFile, contactSuccess,
        contactError, propertyAction, fileList, formDataObj, setFormDataObj, contactLoading,
        action } = props;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [successStatus, setSuccessStatus] = useState(null);
    const [currDescription, setCurrDescription] = useState('');
    const [noDescription, setNoDescription] = useState(false);
    const [errorFile, setErrorFile] = useState(null);
    const [downloadFileName, setDownloadFileName] = useState(null);
    const [currFiles, setCurrFiles] = useState(null);
    const [thumbnailArr, setThumbnailArr] = useState([]);

    useEffect(() => {
        if (contactSuccess === 'Successfully uploaded document') {
            setSuccessStatus(contactSuccess);
        }
        if (contactError === 'Failed to upload document') {
            setSuccessStatus('Failed to upload document');
        }
        if (contactSuccess === 'Successfully deleted CRM file.') {
            let name = props.dataObj.id + '-' + dataObj.name
            props.getCrmFileList({ id: `${name}/Property_Images`, userId: getUserID() });
        }
    }, [contactSuccess, contactError]);

    useEffect(() => {
        if (downloadedFile && downloadedFile.data && downloadedFile.data.length && downloadFileName) {
            let fileBuffer = Buffer.from(props.downloadedFile, 'ArrayBuffer').toString('binary');
            download(fileBuffer, downloadFileName);
        }
    }, [downloadedFile])

    useEffect(() => {
        setDownloadFileName(null);
        if (props.dataObj) {
            let name = props.dataObj.id + '-' + dataObj.name
            props.getCrmFileList({ id: `${name}/Property_Images`, userId: getUserID() });
        }
    }, [])

    useEffect(() => {
        // Download each photo in filelist by file.id
        let namesArr = [];
        let idsArr = [];
        if (fileList && fileList.length) {
            for (let i = 0; i < fileList.length; i++) {
                namesArr.push(fileList[i].name);
                idsArr.push(fileList[i].id);
            }
            props.getCrmFile({ id: idsArr, name: namesArr });
        }
    }, [fileList])

    useEffect(() => {
        if (downloadedFile && downloadedFile.length) {

            // Create array of full sized image data urls
            let downloadedFileDataUrlArr = [];
            for (let i = 0; i < downloadedFile.length; i++) {
                let newDataUrl = toDataURL(
                    downloadedFile[i].data,
                    downloadedFile[i].filename
                );
                downloadedFileDataUrlArr.push(newDataUrl);
            }

            // Get thumbnail data url
            async function callThumbnailify(newDataUrl, value) {
                let response = thumbnailify(newDataUrl, value);
                return response;
            }

            // Resolve an array of promises, inside a function for useEffect
            const resolvePromises = async (promiseArr) => {
                return Promise.all(promiseArr);
            }

            // Creates an array of promises by calling the thumbnailify function
            const promisesArr = downloadedFileDataUrlArr.map(value => callThumbnailify(value, 60));

            // Resolve the promises and set the thumbnailArr
            resolvePromises(promisesArr).then(res => {
                setThumbnailArr(res);
            })
        }
    }, [downloadedFile])

    const cancel = () => {
        props.closeForm()
    }

    const handleFile = (e) => {
        const allowableFileExtensions = ['.png', '.jpg', '.jpeg', '.gif'];
        let extension = e.target.files[0].name.match(/(?:\.([^.]+))?$/);
        if (allowableFileExtensions.includes(extension[0])) {
            setCurrFiles(e.target.files);
        } else {
            document.getElementById('multi_docs').value = ''
            e.target.files = null;
            setSelectedFiles(null);
            setErrorFile(true);
        }
    }

    const handleDescription = (e) => {
        setNoDescription(false);
        setCurrDescription(e.target.value);
    }

    const generateFormData = (files) => {
        setSelectedFiles([])
        let selectedArray = [...selectedFiles];
        let formData = new FormData();
        Object.keys(files).forEach((key) => {
            let file = files[key];
            let extension = file.name.match(/(?:\.([^.]+))?$/);
            let originalName = file.name.replace(extension[0], '');
            const createdDate = new Date().toISOString().split('T')[0];
            originalName = originalName.replace(/'/g, "\\'");
            let currentFileName = originalName + '-' + createdDate + extension[0];
            formData.append('filename', currentFileName);
            let user = getUsername().replace(/ /g, '.');
            formData.append('User', user);
            formData.append('name', dataObj.name);
            formData.append('Folder', 'Properties');
            formData.append('SystemNumber', dataObj.id)
            formData.append('Photo', 'true')
            formData.append('UserId', getUserID());
            formData.append('Description', currDescription);
            formData.append('file', files[key], currentFileName);
            selectedArray.push(formData);
            setSelectedFiles(formData);
            let newFormDataObj = clone(formDataObj) || {};

            // Replacing existing file if it's already on the formDataObj.
            for (const key in newFormDataObj) {
                if (key.substring(0, key.indexOf("-")) === currentFileName.substring(0, currentFileName.indexOf("-"))) {
                    delete newFormDataObj[key];
                }
            }
            newFormDataObj[currentFileName] = formData;
            setFormDataObj(newFormDataObj)
        });

        return formData;
    }

    const closeAlert = () => {
        setSuccessStatus(null);
        setSelectedFiles([]);
        setErrorFile(null);
        if (props.dataObj) {
            let name = props.dataObj.id + '-' + dataObj.name
            props.getCrmFileList({ id: `${name}/Property_Images`, userId: getUserID() });
        }
    }

    function toDataURL(src, name) {
        let extension = name.match(/(?:\.([^.]+))?$/);
        let fileBuffer = Buffer.from(src, 'ArrayBuffer').toString('binary');
        const b64 = new Buffer.from(fileBuffer, 'binary').toString('base64');
        let mimeType = 'image/jpg'; // Default
        if (extension === '.png') {
            mimeType = 'image/png';
        } else if (extension === '.jpg' || extension === '.jpeg') {
            mimeType = 'image/jpg';
        }
        return `data:${mimeType};base64,${b64}`;
    }

    function thumbnailify(base64Image, targetSize) {
        return new Promise(resolve => {
            var img = new Image();
            img.src = base64Image;
            img.onload = function () {
                let canvas = document.createElement('canvas'),
                    ctx = canvas.getContext("2d");

                canvas.width = canvas.height = targetSize;
                ctx.drawImage(img, 0, 0, targetSize, targetSize);
                let newDataUrl = canvas.toDataURL();
                resolve(newDataUrl);
            };
        })
    };

    return (
        <div>
            {props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}

            <GridContainer spacing={0} align="center" justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    {propertyAction === 'Edit' ? <h3>Upload Property Photos</h3> : <h3>Property Photos</h3>}
                </GridItem>

                {propertyAction === 'Edit' &&
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField type='file' name="file" id="multi_docs" onChange={handleFile}
                            accept="application/pdf, image/jpeg, image/png" multiple />
                        <br /> <br />
                        <TextField type='text' name="description" id="description" placeholder="Description"
                            onChange={handleDescription} />
                        <br />
                        {noDescription && <span className="error-text">Please enter a description</span>}
                        <br />
                        <Button color="rose" onClick={() => {
                            if (!currDescription) {
                                setNoDescription(true);
                            } else {
                                let innerFormData = generateFormData(currFiles);
                                createCrmFile(innerFormData)
                            }
                        }}>
                            Upload
                        </Button>
                    </GridItem>
                }
                {propertyAction === 'Add' &&
                    <GridItem xs={12} sm={12} md={12}>
                        <TextField type='file' name="file" id="multi_docs" onChange={handleFile}
                            accept="application/pdf, image/jpeg, image/png" multiple></TextField>
                        <Button
                            color="rose"
                            onClick={() => {
                                
                            }}>
                            Select File
                        </Button>
                    </GridItem>
                }
                {fileList && !fileList.length && !formDataObj && !contactLoading &&
                    <GridItem>
                        <p><em>No photos to display.</em></p>
                    </GridItem>
                }
                {contactLoading &&
                    <ClipLoader />
                }
                {fileList && (fileList.length > 0) && !contactLoading &&
                    <GridItem container xs={6} sm={6} md={6} justify="center" alignItems="center" direction="column">
                        <h3>Uploaded Photos</h3>
                        <List>
                            {
                                fileList.map((file, index) => {
                                    return (
                                        <ListItem
                                            key={`${index}-${file.name}`}
                                            role={undefined}
                                            dense
                                        >
                                            {action === 'Edit' &&
                                                <ListItemIcon>
                                                    <IconButton
                                                        onClick={() => {
                                                            props.deleteCrmFile(file.id);
                                                        }}
                                                    >
                                                        <i className="fas fa-trash-alt red-text"></i>
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        // Download the file from backend / google drive
                                                        setDownloadFileName(file.name);
                                                        props.getCrmFile({ name: file.name, id: file.id });
                                                    }}
                                                >
                                                    <i className="fas fa-arrow-down blue-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemAvatar>
                                                <img src={thumbnailArr[index]} alt="test" width="50" height="50" style={{ border: '1px solid rgba(0, 0, 0)' }} />
                                            </ListItemAvatar>
                                            <ListItemText primary={`${file.name}`} secondary={file.description ? `${file.description}` : ''} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </GridItem>
                }
                {formDataObj && Object.keys(formDataObj).length > 0 && propertyAction === 'Add' &&
                    <GridItem container xs={6} sm={6} md={6} justify="center" alignItems="center" direction="column">
                        <h3>Uploaded Files</h3>
                        <List>
                            {
                                Object.keys(formDataObj).map((key, index) => {
                                    return (
                                        <ListItem
                                            key={`${index}-${key}`}
                                            role={undefined}
                                            dense
                                        >
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        let newFormDataObj = clone(formDataObj) || {};
                                                        delete newFormDataObj[key];
                                                        setFormDataObj(newFormDataObj);
                                                    }}
                                                >
                                                    <i className="fas fa-trash-alt red-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        download(formDataObj[key].get('file'), key);
                                                    }}
                                                >
                                                    <i className="fas fa-arrow-down blue-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText primary={`${key}`} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </GridItem>
                }
                <GridItem xs={12} sm={12} md={12}>
                    {successStatus === 'Successfully uploaded document' &&
                        <Notification
                            open={true}
                            message="Successfully uploaded document"
                            close={closeAlert}
                        />}
                    {successStatus === 'Failed to upload document' &&
                        <Notification
                            open={true}
                            message="Failed to upload document"
                            close={closeAlert}
                        />}
                    {errorFile &&
                        <Notification
                            open={true}
                            message="Please upload a valid file with the following extensions: png, jpg, jpeg, or gif"
                            close={closeAlert}
                        />}
                </GridItem>
            </GridContainer>
        </div>
    )

}

export default PhotosForm;