import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import ReactTable from "react-table";

import Button from "components/CustomButtons/Button.jsx";
import { generateInvoiceHTML, INVOICE_STATUS } from "./InvoiceObj";
import InvoicePdfModal from "./InvoicePdfModal";
import { clone, get, orderBy, sortBy } from "lodash";
import moment from 'moment';
import Notification from "../../components/Notification/Notification";
import { FadeLoader } from "react-spinners";
import InvoicePaymentHistory from './InvoicePaymentHistory';

const InvoiceList = (props) => {
    const {
        data,
        tab,
        openForm,
        parentData,
        setParentData,
        deleteInvoiceEntry,
        getInvoiceEntries,
        getInvoicePdf,
        pdfData,
        updateInvoiceEntry,
        clearInvoicePdfData,
        resendInvoiceEmail,
        success,
        permissions,
        getInvoicePayments,
        invoicePayments
    } = props;

    const [listItems, setListItems] = useState([]);
    const [columns, setColumns] = useState([]);
    const [idToDelete, setIdToDelete] = useState(null);

    const [openedPdfView, setOpenedPdfView] = useState(false);
    const [localPdfData, setLocalPdfData] = useState(null);
    const [invoiceToReview, setInvoiceToReview] = useState(null);

    const [openedSuccessModal, setOpenedSuccessModal] = useState(false);

    const [openPaymentHistory, setOpenPaymentHistory] = useState(false);

    const [waitForInvoicePayments, setWaitForInvoicePayments] = useState(false);

    const [isDeleting, setIsDeleting] = useState(false);

    const handleOpenPaymentHistory = () => setOpenPaymentHistory(true);
    const handleClosePaymentHistory = () => setOpenPaymentHistory(false);

    const openPdfView = () => setOpenedPdfView(true);
    const closePdfView = () => setOpenedPdfView(false);

    const handleResend = (item) => {
        resendInvoiceEmail({
            html: generateInvoiceHTML(item.data),
            data: item.data,
        })
    }

    useEffect(() => {
        if (waitForInvoicePayments && props.invoicePayments) {
            handleOpenPdfView(parentData);
            setWaitForInvoicePayments(false);
        }
    }, [props.invoicePayments]);

    useEffect(() => {
        if (invoiceToReview) {
            const found = data.find(item => item.id === invoiceToReview.id);

            if (found) {
                setInvoiceToReview(found);
            }
        }
    }, [data])

    useEffect(() => {
        if (success && success === 'Sent Invoice to email successfully') {
            setOpenedSuccessModal(true)
        }
        if (isDeleting && success === 'Successfully deleted') {
            closeDeleteModal();
            getInvoiceEntries(tab);
            setIsDeleting(false);
        }
    }, [success])

    const handleOpenPdfView = (data) => {
        console.log('opening pdf view data', data);
        const formData = clone(data.data);
        formData.rsData = data.json_data;
        const index = get(data, 'data.payment_schedule_data.index', null);
        const html = generateInvoiceHTML(formData, null, index, invoicePayments, parentData ? parentData.id : null);
        getInvoicePdf({ htmlTemplate: html });
        setInvoiceToReview(data);
        openPdfView(true);
    };

    const [openedDeleteModal, setOpenedDeleteModal] = useState(false);

    const openDeleteModal = (id) => {
        setOpenedDeleteModal(true);
        setIdToDelete(id);
    };
    const closeDeleteModal = () => setOpenedDeleteModal(false);

    const handleClose = () => {
        setIdToDelete(null);
        closeDeleteModal();
    };

    const handleApprove = () => {
        const approvedInvoice = clone(invoiceToReview);
        approvedInvoice.status = INVOICE_STATUS.APPROVED;
        approvedInvoice.html = generateInvoiceHTML(approvedInvoice.data)
        updateInvoiceEntry(approvedInvoice);
    };

    const handleDecline = () => {
        const declinedInvoice = clone(invoiceToReview);
        declinedInvoice.status = INVOICE_STATUS.DECLINE;
        updateInvoiceEntry(declinedInvoice);
    };

    useEffect(() => {
        if (pdfData && typeof pdfData === "string") {
            const parsedPdf = JSON.parse(pdfData);
            setLocalPdfData(parsedPdf);
            clearInvoicePdfData();
        }
    }, [pdfData]);

    const load = (theTab) => {
        getInvoiceEntries(theTab);
    }

    useEffect(() => {
        load(tab);
    }, [tab]);

    useEffect(() => {
        if (props.openPDFitemID) {
            let itemToOpen = null;
            if (data) {
                for (let i = 0; i < data.length; i++) {
                    const dat = data[i];
                    if (dat.id === parseInt(props.openPDFitemID)) {
                        itemToOpen = dat;
                        break;
                    }
                }
            }
            if (itemToOpen) {
                handleOpenPdfView(itemToOpen);
                props.resetInvoiceID();
                props.resetOpenPDFitemID();
            }
        }
    }, [props.openPDFitemID, data]);

    useEffect(() => {
        let list = [];

        setColumns([
            {
                Header: "ID",
                accessor: "id",
                maxWidth: 50
            },
            {
                Header: "RS ID",
                accessor: "rs_id",
                maxWidth: 50
            },
            {
                Header: "Title",
                accessor: "title",
            },
            {
                Header: "Project Name",
                accessor: "project_name",
            },
            {
                Header: "Submitter",
                accessor: "submitter",
            },
            {
                Header: "Date Created",
                accessor: "format_date_created",
            },
            {
                Header: "Actions",
                accessor: "actions",
            },
        ]);

        const sortedData = orderBy(data, ['date_created'], 'desc');
       
        if (tab === INVOICE_STATUS.DRAFT) {
            list = sortedData.map((item) => {
                const format_date_created = moment(item.date_created).format('MM/DD/YYYY');
                return {
                    ...item,
                    format_date_created,
                    project_name: get(item, 'json_data.project_name', ''),
                    actions: (
                        <div>
                            <IconButton
                                onClick={() => {
                                    setParentData(item);
                                    openForm();
                                }}
                            >
                                <Tooltip title="Edit">
                                    <Edit />
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    openDeleteModal(item.id);
                                }}
                            >
                                <Tooltip title="Delete">
                                    <Delete />
                                </Tooltip>
                            </IconButton>
                        </div>
                    ),
                };
            });
        } else if (tab === INVOICE_STATUS.PENDING) {
            list = sortedData.map((item) => {
                const format_date_created = moment(item.date_created).format('MM/DD/YYYY');
                return {
                    ...item,
                    format_date_created,
                    project_name: get(item, 'json_data.project_name', ''),
                    actions: (
                        <div>
                            <IconButton
                                onClick={() => {
                                    setParentData(item);
                                    getInvoicePayments({
                                        rs_id: item.rs_id
                                    });
                                    openForm();
                                }}
                            >
                                <Tooltip title="Edit">
                                    <i className="fas fa-edit font-22" />
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    openDeleteModal(item.id);
                                }}
                            >
                                <Delete />
                            </IconButton>
                        </div>
                    ),
                };
            });
        } else if (tab === INVOICE_STATUS.APPROVED) {
            list = sortedData.map((item) => {
                const format_date_created = moment(item.date_created).format('MM/DD/YYYY');
                return {
                    ...item,
                    format_date_created,
                    project_name: get(item, 'json_data.project_name', ''),
                    actions: (
                        <div>
                            <IconButton
                                onClick={() => {
                                    handleResend(item);
                                }}
                            >
                                <Tooltip title="Re-send" placement="bottom">
                                    <i className="fa fa-paper-plane"></i>
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    getInvoicePayments({
                                        rs_id: item.rs_id
                                    });
                                    setWaitForInvoicePayments(true);
                                    setParentData(item);
                                }}
                            >
                                <Tooltip title="View" placement="bottom">
                                    <i className="fa fa-eye font-22 " />
                                </Tooltip>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setParentData(item);
                                    getInvoicePayments({
                                        rs_id: item.rs_id
                                    });
                                    openForm();
                                }}
                            >
                                <Tooltip title="Edit">
                                    <i className="fas fa-edit font-22" />
                                </Tooltip>
                            </IconButton>
                            {permissions.indexOf('edit_delete_payment_history') !== -1 && <IconButton
                                onClick={() => {
                                    setInvoiceToReview(item);
                                    getInvoicePayments({
                                        rs_id: item.rs_id
                                    });
                                    handleOpenPaymentHistory();
                                }}
                            >   
                                <Tooltip title="Open Payments">
                                    <i className="fa fa-money-bill-alt font-22 " />
                                </Tooltip>
                            </IconButton>}
                        </div>
                    ),
                };
            });
        } else if (tab === INVOICE_STATUS.DECLINE) {
            list = sortedData.map((item) => {
                const format_date_created = moment(item.date_created).format('MM/DD/YYYY');
                return {
                    ...item,
                    format_date_created,
                    project_name: get(item, 'json_data.project_name', ''),
                    actions: (
                        <div>
                            <IconButton
                                onClick={() => {
                                    handleOpenPdfView(item);
                                }}
                            >
                                <i class="fa fa-eye font-22 " />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setParentData(item);
                                    openForm();
                                }}
                            >
                                <Edit />
                            </IconButton>
                        </div>
                    ),
                };
            });
        } else if (tab === INVOICE_STATUS.VOIDED) {
            list = sortedData.map((item) => {
                const format_date_created = moment(item.date_created).format('MM/DD/YYYY');
                return {
                    ...item,
                    format_date_created,
                    project_name: get(item, 'json_data.project_name', ''),
                    actions: (
                        <div>
                            <IconButton
                                onClick={() => {
                                    handleOpenPdfView(item);
                                }}
                            >
                                <i class="fa fa-eye font-22 " />
                            </IconButton>
                        </div>
                    ),
                };
            });
        }
        setListItems(list);
    }, [data]);

    return (
        <div className="table-adjust2">
            <ReactTable
                filterable
                className="-striped -highlight"
                showPaginationTop
                showPaginationBottom
                resizable={true}
                defaultPageSize={10}
                pageSizeOptions={[10, 20, 30]}
                data={listItems}
                columns={columns}
            />

            <Dialog open={openedDeleteModal} onClose={closeDeleteModal}>
                <DialogTitle>
                    Are you sure want to delete this invoice?
                </DialogTitle>

                <div className="text-center">
                    <Button onClick={handleClose}>Close</Button>
                    <Button
                        color="danger"
                        onClick={() => {
                            if (idToDelete) {
                                deleteInvoiceEntry(idToDelete);
                                setIsDeleting(true);
                            }
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Dialog>

            {!localPdfData && openedPdfView &&<Notification 
                open={true}
                close={() => {

                }}
                no_ok_button={true}
                message={<div><div className="fade-loader-center"><FadeLoader /></div><br />Loading PDF...</div>}
            />}

            {localPdfData && openedPdfView && (
                <InvoicePdfModal
                    pdfData={localPdfData}
                    invoice={invoiceToReview}
                    open={true}
                    close={() => {
                        setLocalPdfData(null);
                        setInvoiceToReview(null);
                        closePdfView();
                    }}
                    handleDecline={handleDecline}
                    handleApprove={handleApprove}
                />
            )}

            {openPaymentHistory && (
                <InvoicePaymentHistory 
                    invoice={invoiceToReview} 
                    open={openPaymentHistory} 
                    close={handleClosePaymentHistory} 
                />
            )}

            {openedSuccessModal &&<Notification 
                open={openedSuccessModal}
                close={() => setOpenedSuccessModal(false)}
                no_ok_button={false}
                message={success}
            />}
        </div>
    );
};

export default InvoiceList;
