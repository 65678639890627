import React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Delete from "@material-ui/icons/Delete";
import { ordinalInWord } from "../../../shared/utility";

const DragHandle = SortableHandle(() => <i className="fa fa-ellipsis-v"></i>);

const SortableItem = SortableElement((props) => {
    const { action, cardinal, onDelete, onItemClick, value } = props;
    const o = value.contact_data ? value.contact_data : {};
    const name = o.name ? o.name : `${o.first_name ? o.first_name : ""} ${o.last_name ? o.last_name : ""}`;

    const cardinalWord = ordinalInWord(cardinal, true);

    return (
        <ListItem button style={{ zIndex: 99999999 }}>
            <DragHandle />
            <ListItemText
                inset
                onClick={() => onItemClick(o)}
                primary={name}
                secondary={<span style={{ textTransform: "capitalize" }}>{cardinalWord} agent 
                    {value.subtype ? ` - Real Estate ${value.subtype}` : ''}</span>}
            />
            {action !== "View" && (
                <ListItemIcon>
                    <div className="hoverable red-text" onClick={() => onDelete(value)}>
                        <Delete />
                    </div>
                </ListItemIcon>
            )}
        </ListItem>
    );
});

const SortableList = SortableContainer((props) => {
    const { action, items, onDelete, onItemClick } = props;
    return (
        <List component="nav">
            {items.map((value, index) => (
                <SortableItem
                    action={action}
                    cardinal={index + 1}
                    disabled={action === "View"}
                    key={`sorted-item-${index}`}
                    index={index}
                    onDelete={onDelete}
                    onItemClick={onItemClick}
                    value={value}
                />
            ))}
        </List>
    );
});

const PropertiesFormAgentSortableList = (props) => {
    const { action, items, onDelete, onItemClick, onSortEnd } = props;

    const handleSortEnd = ({ oldIndex, newIndex }) => {
        const newList = arrayMoveImmutable(items, oldIndex, newIndex);
        onSortEnd(newList);
    };

    return (
        <SortableList
            action={action}
            items={items}
            onDelete={onDelete}
            onItemClick={onItemClick}
            onSortEnd={handleSortEnd}
            useDragHandle
        />
    );
};

export default PropertiesFormAgentSortableList;
