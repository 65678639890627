import { put } from "redux-saga/effects";
import axios from "../axios/axios-crm";
import * as actions from "../actions/crm_properties";
import { COSTAR_IMPORTER_FIELDS, extractAddress, extractCityStateZip, findData, getFirstNameLastName, sanitizePropertyType } from "../../containers/CRM/Properties/PropertiesObj";
import {
	createCrmFileStart,
	createCrmFileSuccess,
} from "../actions/crm_contacts";
import { clone } from "../../shared/utility";
const getError = (err) => {
	if (err.response) {
		if (err.response.data) {
			return err.response.data;
		}
	}
	return null;
};

export function* getPropertiesSaga(action) {
	yield put(actions.getPropertiesStart());
	try {
		let url = "/properties?q=get";
		
		if (action.params) {
			const fields = Object.keys(action.params);
			for (let i = 0; i < fields.length; i++) {
				const f = fields[i];
				if (action.params[f]) url += `&${f}=${action.params[f]}`;
			}
		}
		
		const response = yield axios.get(url);
		
		yield put(actions.getPropertiesSuccess(response.data));
	} catch (err) {
		const errData = getError(err);
		if (errData) return yield put(actions.getPropertiesFail(errData));
		yield put(actions.getPropertiesFail("Failed to get properties"));
	}
}

export function* getPropertySaga(action) {
	yield put(actions.getPropertyStart());
	try {
		let url = "/property?id=" + action.id;
		const response = yield axios.get(url);
		if (response.data) {
			yield put(actions.getPropertySuccess(response.data));
		} else {
			yield put(actions.getPropertyFail('Not found'));
		}
	} catch (err) {
		const errData = getError(err);
		if (errData) return yield put(actions.getPropertyFail(errData));
		yield put(actions.getPropertyFail("Failed to get property"));
	}
}

export function* getPropertyContactsSaga(action) {
	yield put(actions.getPropertyContactsStart());
	try {
		const response = yield axios.get(
			"/property_contacts?contact_id=" + action.data
		);
		if (response.data) {
			const obj = response.data;
			for (let i = 0; i < obj.property_contacts.length; i++) {
				const o = obj.property_contacts[i];
				const resp = yield axios.get("/property?id=" + o.id);
				if (resp.data) {
					obj.property_contacts[i].data = resp.data;
				}
			}
			for (let i = 0; i < obj.leases.length; i++) {
				const o = obj.leases[i];
				const resp = yield axios.get("/property?id=" + o.id);
				if (resp.data) {
					obj.leases[i].data = resp.data;
				}
			}
			for (let i = 0; i < obj.listings.length; i++) {
				const o = obj.listings[i];
				const resp = yield axios.get("/property?id=" + o.id);
				if (resp.data) {
					obj.listings[i].data = resp.data;
				}
			}
			for (let i = 0; i < obj.meetings.length; i++) {
				const o = obj.meetings[i];
				const resp = yield axios.get("/property?id=" + o.id);
				if (resp.data) {
					obj.meetings[i].data = resp.data;
				}
			}
			yield put(actions.getPropertyContactsSuccess(obj));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getPropertyContactsFail(e.response.data));
			}
		}
		yield put(actions.getPropertyContactsFail(e));
	}
}

export function* getPropertyNamesSaga(action) {
	yield put(actions.getPropertyNamesStart());
	try {
		const type = action.data ? action.data : "all";
		const response = yield axios.get("/properties?names_only=" + type);
		yield put(actions.getPropertyNamesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getPropertyNamesFail(e.response.data));
			}
		}
		yield put(actions.getPropertyNamesFail(e));
	}
}

export function* createPropertySaga(action) {
	yield put(actions.createPropertyStart());

	let newID = null;
	let success_data = null;
	
	try {
		let url = "/property";
		const data = clone(action.data);
		if (data.address_is_range === '') {
			data.street_range1 = '';
			data.street_range2 = '';
		}
		if (data.metadata) {
            data.metadata = JSON.stringify(data.metadata);
        }
		const response = yield axios.post(url, data);
		
		if (response.data) {
			success_data = response.data;
			if (response.data.insertId) newID = response.data.insertId;
			if (!action.otherData) {
				return yield put(actions.createPropertySuccess(response.data));
			}
		}
	} catch (err) {
		const errData = getError(err);
		if (errData) return yield put(actions.createPropertyFail(errData));
		yield put(actions.createPropertyFail("Failed to create property"));
	}

	if (newID) {
		if (action.data) {
			if (action.data.owner_id) {
				const owners = action.data.owner_id.split(',');
				for (let o = 0; o < owners.length; o++) {
					const contact_id = owners[o];
					const insertOwner = yield axios.post('/property_to_contact', {
						contact_id,
						property_id: newID,
						contact_type: 'OWN'
					})
					
				}
			}
		}
	}

	if (action.otherData) {
		
		const o = action.otherData;
		// Leases
		if (o.leasesForAdd) {
			for (let i = 0; i < o.leasesForAdd.length; i++) {
				const l = clone(o.leasesForAdd[i]);
				l.property_id = newID;
				delete l.id;
				try {
					const response = yield axios.post("/lease", l);
					
				} catch (e) {
					
				}
			}
		}
		// Listings
		if (o.listingsForAdd) {
			for (let i = 0; i < o.listingsForAdd.length; i++) {
				const l = clone(o.listingsForAdd[i]);
				l.property_id = newID;
				delete l.id;
				try {
					const response = yield axios.post("/listing", l);
					
				} catch (e) {
					
				}
			}
		}
		// Meetings
		if (o.meetingsForAdd) {
			for (let i = 0; i < o.meetingsForAdd.length; i++) {
				const l = clone(o.meetingsForAdd[i]);
				l.property_id = newID;
				delete l.id;
				try {
					const response = yield axios.post("/meeting", l);
					
				} catch (e) {
					
				}
			}
		}
		// Offers
		if (o.offersForAdd) {
			for (let i = 0; i < o.offersForAdd.length; i++) {
				const l = clone(o.offersForAdd[i]);
				l.property_id = newID;
				delete l.id;
				try {
					const response = yield axios.post("/offer", l);
					
				} catch (e) {
					
				}
			}
		}
		// Showings
		if (o.showingsForAdd) {
			for (let i = 0; i < o.showingsForAdd.length; i++) {
				const l = clone(o.showingsForAdd[i]);
				l.property_id = newID;
				delete l.id;
				try {
					const response = yield axios.post("/showing", l);
					
				} catch (e) {
					
				}
			}
		}
		// Shares
		if (o.sharesForAdd) {
			for (let i = 0; i < o.sharesForAdd.length; i++) {
				const l = clone(o.sharesForAdd[i]);
				l.property_id = newID;
				delete l.id;
				delete l.name;
				try {
					const response = yield axios.post("/property_share", l);
					
				} catch (e) {
					
				}
			}
		}

		// Contacts 
		if (o.contactsForAdd) {
			for (let i = 0; i < o.contactsForAdd.length; i++) {
				const l = clone(o.contactsForAdd[i]);
				l.property_id = newID;
				if (l.id) delete l.id;
				try {
					const response = yield axios.post('/property_to_contact', l);
					
				} catch (e) {
					
				}
			}
		}

		// Companies 
		if (o.companyForAdd) {
			for (let i = 0; i < o.companyForAdd.length; i++) {
				const l = clone(o.companyForAdd[i]);
				l.property_id = newID;
				if (l.id) delete l.id;
				try {
					const response = yield axios.post('/company_properties', l);
					
				} catch (e) {
					
				}
			}
		}

		if (action.otherData["formDataObj"]) {
			yield put(createCrmFileStart());
			let combinedFormData = new FormData();
			combinedFormData.append("SystemNumber", newID);
			for (const [key, value] of Object.entries(
				action.otherData["formDataObj"]
			)) {
				for (var pair of value.entries()) {
					combinedFormData.append(pair[0], pair[1]);
				}
			}
			const responseTwo = yield axios.post("/upload", combinedFormData);
			yield put(createCrmFileSuccess(responseTwo.data));
		}
	}

	yield put(actions.createPropertySuccess(success_data));
}

export function* deletePropertySaga(action) {
	yield put(actions.deletePropertyStart());
	try {
		let url = "/property?id=" + action.id;
		const response = yield axios.delete(url);
		
		yield put(actions.deletePropertySuccess(response.data));
	} catch (err) {
		const errData = getError(err);
		if (errData) return yield put(actions.deletePropertyFail(errData));
		yield put(actions.deletePropertyFail("Failed to delete property"));
	}
}

export function* updatePropertySaga(action) {
	yield put(actions.updatePropertyStart());
	try {
		let url = "/property";
		const data = clone(action.data);
		if (data.address_is_range === '') {
			data.street_range1 = '';
			data.street_range2 = '';
		}
		if (data.insertId) delete data.insertId; // make sure insertId is not sent
		const response = yield axios.put(url, data);
		
		const newID = action.data ? action.data.id : null;
		if (newID) {
			if (action.data) {
				if (action.data.owner_id) {
					const owners = action.data.owner_id.split(',');
					for (let o = 0; o < owners.length; o++) {
						const contact_id = owners[o];
						const insertOwner = yield axios.post('/property_to_contact', {
							contact_id,
							property_id: newID,
							contact_type: 'OWN'
						})
						
					}
				}
			}
		}
		yield put(actions.updatePropertySuccess(response.data));
	} catch (err) {
		const errData = getError(err);
		if (errData) return yield put(actions.updatePropertyFail(errData));
		yield put(actions.updatePropertyFail("Failed to create property"));
	}
}

export function* getPropertySharesSaga(action) {
	yield put(actions.getPropertySharesStart());
	try {
		const response = yield axios.get(
			`/property_shares?property_id=${action.data}`
		);
		
		yield put(actions.getPropertySharesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getPropertySharesFail(e.response.data));
			}
		}
		yield put(actions.getPropertySharesFail(e));
	}
}

export function* createPropertyShareSaga(action) {
	yield put(actions.createPropertyShareStart());
	try {
		const response = yield axios.post(`/property_share`, action.data);
		yield put(actions.createPropertyShareSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createPropertyShareFail(e.response.data));
			}
		}
		yield put(actions.createPropertyShareFail(e));
	}
}

export function* deletePropertyShareSaga(action) {
	yield put(actions.deletePropertyShareStart());
	try {
		
		const response = yield axios.delete(`/property_share?id=${action.data}`);
		yield put(actions.deletePropertyShareSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deletePropertyShareFail(e.response.data));
			}
		}
		yield put(actions.deletePropertyShareFail(e));
	}
}

export function* getLeasesSaga(action) {
	yield put(actions.getLeasesStart());
	try {
		const response = yield axios.get(`/leases?id=${action.data}`);
		yield put(actions.getLeasesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getLeasesFail(e.response.data));
			}
		}
		yield put(actions.getLeasesFail(e));
	}
}

export function* getLeaseSaga(action) {
	yield put(actions.getLeaseStart());
	try {
		const response = yield axios.get("/lease?id=" + action.data);
		yield put(actions.getLeaseSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getLeaseFail(e.response.data));
			}
		}
		yield put(actions.getLeaseFail(e));
	}
}

export function* createLeaseSaga(action) {
	yield put(actions.createLeaseStart());
	try {
		let newAction = clone(action);
		delete newAction.data["formDataObj"];
		let formDataObj;
		if (action.data["formDataObj"]) {
			formDataObj = action.data["formDataObj"];
		}
		const response = yield axios.post("/lease", newAction.data);
		if (response.data && response.data.insertId && formDataObj) {
			yield put(createCrmFileStart());
			let combinedFormData = new FormData();
			combinedFormData.append("SystemNumber", response.data.insertId);
			for (const [key, value] of Object.entries(formDataObj)) {
				for (var pair of value.entries()) {
					combinedFormData.append(pair[0], pair[1]);
				}
			}
			const responseTwo = yield axios.post("/upload", combinedFormData);
			yield put(createCrmFileSuccess(responseTwo.data));
		}
		yield put(actions.createLeaseSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createLeaseFail(e.response.data));
			}
		}
		yield put(actions.createLeaseFail(e));
	}
}

export function* updateLeaseSaga(action) {
	yield put(actions.updateLeaseStart());
	try {
		const response = yield axios.put("/lease", action.data);
		yield put(actions.updateLeaseSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateLeaseFail(e.response.data));
			}
		}
		yield put(actions.updateLeaseFail(e));
	}
}

export function* deleteLeaseSaga(action) {
	yield put(actions.deleteLeaseStart());
	try {
		const response = yield axios.delete("/lease?id=" + action.data);
		yield put(actions.deleteLeaseSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteLeaseFail(e.response.data));
			}
		}
		yield put(actions.deleteLeaseFail(e));
	}
}

export function* getListingsSaga(action) {
	yield put(actions.getListingsStart());
	try {
		const response = yield axios.get("/listings?property_id=" + action.data);
		yield put(actions.getListingsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getListingsFail(e.response.data));
			}
		}
		yield put(actions.getListingsFail(e));
	}
}

export function* getListingSaga(action) {
	yield put(actions.getListingStart());
	try {
		const response = yield axios.get("/listing?id=" + action.data);
		yield put(actions.getListingSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getListingFail(e.response.data));
			}
		}
		yield put(actions.getListingFail(e));
	}
}

export function* createListingSaga(action) {
	yield put(actions.createListingStart());
	try {
		let newAction = clone(action);
		delete newAction.data["formDataObj"];
		let formDataObj;
		if (action.data["formDataObj"]) {
			formDataObj = action.data["formDataObj"];
		}
		const response = yield axios.post("/listing", newAction.data);
		if (response.data && response.data.insertId && formDataObj) {
			yield put(createCrmFileStart());
			let combinedFormData = new FormData();
			combinedFormData.append("SystemNumber", response.data.insertId);
			for (const [key, value] of Object.entries(formDataObj)) {
				for (var pair of value.entries()) {
					combinedFormData.append(pair[0], pair[1]);
				}
			}
			const responseTwo = yield axios.post("/upload", combinedFormData);
			yield put(createCrmFileSuccess(responseTwo.data));
		}
		yield put(actions.createListingSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createListingFail(e.response.data));
			}
		}
		yield put(actions.createListingFail(e));
	}
}

export function* updateListingSaga(action) {
	yield put(actions.updateListingStart());
	try {
		const response = yield axios.put("/listing", action.data);
		yield put(actions.updateListingSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateListingFail(e.response.data));
			}
		}
		yield put(actions.updateListingFail(e));
	}
}

export function* deleteListingSaga(action) {
	yield put(actions.deleteListingStart());
	try {
		const response = yield axios.delete("/listing?id=" + action.data);
		yield put(actions.deleteListingSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteListingFail(e.response.data));
			}
		}
		yield put(actions.deleteListingFail(e));
	}
}

export function* getMeetingsSaga(action) {
	yield put(actions.getMeetingsStart());
	try {
		const response = yield axios.get("/meetings?property_id=" + action.data);
		yield put(actions.getMeetingsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getMeetingsFail(e.response.data));
			}
		}
		yield put(actions.getMeetingsFail(e));
	}
}

export function* getMeetingSaga(action) {
	yield put(actions.getMeetingStart());
	try {
		const response = yield axios.get("/meeting?id=" + action.data);
		yield put(actions.getMeetingSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getMeetingFail(e.response.data));
			}
		}
		yield put(actions.getMeetingFail(e));
	}
}

export function* createMeetingSaga(action) {
	yield put(actions.createMeetingStart());
	try {
		console.log('creating meeting');
		let newAction = clone(action);
		delete newAction.data["formDataObj"];
		let formDataObj;
		if (action.data["formDataObj"]) {
			formDataObj = action.data["formDataObj"];
		}
		const response = yield axios.post("/meeting", newAction.data);
		if (response.data && response.data.insertId && formDataObj) {
			yield put(createCrmFileStart());
			let combinedFormData = new FormData();
			combinedFormData.append("SystemNumber", response.data.insertId);
			for (const [key, value] of Object.entries(formDataObj)) {
				for (var pair of value.entries()) {
					combinedFormData.append(pair[0], pair[1]);
				}
			}
			const responseTwo = yield axios.post("/upload", combinedFormData);
			yield put(createCrmFileSuccess(responseTwo.data));
		}
		yield put(actions.createMeetingSuccess(response.data));
	} catch (e) {
		console.log('e',e)
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createMeetingFail(e.response.data));
			}
		}
		yield put(actions.createMeetingFail(e));
	}
}

export function* updateMeetingSaga(action) {
	yield put(actions.updateMeetingStart());
	try {
		const response = yield axios.put("/meeting", action.data);
		yield put(actions.updateMeetingSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateMeetingFail(e.response.data));
			}
		}
		yield put(actions.updateMeetingFail(e));
	}
}

export function* deleteMeetingSaga(action) {
	yield put(actions.deleteMeetingStart());
	try {
		const response = axios.delete("/meeting?id=" + action.data);
		yield put(actions.deleteMeetingSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteMeetingFail(e.response.data));
			}
		}
		yield put(actions.deleteMeetingFail(e));
	}
}

export function* getOffersSaga(action) {
	yield put(actions.getOffersStart());
	try {
		const response = yield axios.get("/offers?id=" + action.data);
		yield put(actions.getOffersSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getOffersFail(e.response.data));
			}
		}
		yield put(actions.getOffersFail(e));
	}
}

export function* getOfferSaga(action) {
	yield put(actions.getOfferStart());
	try {
		const response = yield axios.get("/offer?id=" + action.data);
		yield put(actions.getOfferSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getOfferFail(e.response.data));
			}
		}
		yield put(actions.getOfferFail(e));
	}
}

export function* createOfferSaga(action) {
	yield put(actions.createOfferStart());
	try {
		let newAction = clone(action);
		delete newAction.data["formDataObj"];
		let formDataObj;
		if (action.data["formDataObj"]) {
			formDataObj = action.data["formDataObj"];
		}
		const response = yield axios.post("/offer", newAction.data);
		if (response.data && response.data.insertId && formDataObj) {
			yield put(createCrmFileStart());
			let combinedFormData = new FormData();
			combinedFormData.append("SystemNumber", response.data.insertId);
			for (const [key, value] of Object.entries(formDataObj)) {
				for (var pair of value.entries()) {
					combinedFormData.append(pair[0], pair[1]);
				}
			}
			const responseTwo = yield axios.post("/upload", combinedFormData);
			yield put(createCrmFileSuccess(responseTwo.data));
		}
		yield put(actions.createOfferSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createOfferFail(e.response.data));
			}
		}
		yield put(actions.createOfferFail(e));
	}
}

export function* updateOfferSaga(action) {
	yield put(actions.updateOfferStart());
	try {
		const response = yield axios.put("/offer", action.data);
		yield put(actions.updateOfferSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateOfferFail(e.response.data));
			}
		}
		yield put(actions.updateOfferFail(e));
	}
}

export function* deleteOfferSaga(action) {
	yield put(actions.deleteOfferStart());
	try {
		const response = yield axios.delete("/offer?id=" + action.data);
		yield put(actions.deleteOfferSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteOfferFail(e.response.data));
			}
		}
		yield put(actions.deleteOfferFail(e));
	}
}

export function* getSubPropertiesSaga(action) {
	yield put(actions.getSubPropertiesStart());
	console.log('action', action);
	try {
		const data = action.data;
		const is_illi = action.is_illi;
		let url = "/sub_properties?type=" + data.plural;
		if (is_illi) {
			url = "/sub_properties?type=" + data.plural + "&illi_db=true";
		}
		if (data.plural === 'meetings') {
			url = '/meetings';
		}
		if (data.plural === 'showings') {
			url = '/showings';
		}
		if (data.plural === 'listings') {
			url = '/listings';
		}
		if (data.plural === 'leases') {
			url = '/leases';
		}
		if (data.plural === 'offers') {
			url = '/offers';
		}
		const response = yield axios.get(url);
		yield put(actions.getSubPropertiesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getSubPropertiesFail(e.response.data));
			}
		}
		yield put(actions.getSubPropertiesFail(e));
	}
}

export function* checkPropertyExistingAddressSaga(action) {
  yield put(actions.checkPropertyExistingAddressStart());
	try {
		const response = yield axios.post("/property_address", action.data);
    yield put(actions.checkPropertyExistingAddressSuccess())
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(
					actions.checkPropertyExistingAddressFail(e.response.data)
				);
			}
		}
		yield put(actions.checkPropertyExistingAddressFail(e));
	}
}

export function* getOptionsSaga(action) {
    yield put(actions.getOptionsStart());
    try {
        const response = yield axios.get('/property_options');
        yield put(actions.getOptionsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getOptionsFail(e.response.data));
            }
          }
        yield put(actions.getOptionsFail(e));
    }
}

export function* getPropertyChangesSaga(action) {
    yield put(actions.getPropertyChangesStart());
    try {
		let changes = [];
		if (!action.data) throw "No data";
		for (let i = 0; i < action.data.length; i++) {
			const property = action.data[i];
			if (property.id) {
				const response = yield axios.get('/property?id='+property.id);
				const db_property = response.data;
				const fields = Object.keys(property);
				let changeDetected = false;
				const exempted_fields = ['id'];
				for (let j = 0; j < fields.length; j++) {
					const field = fields[j];
					if (exempted_fields.indexOf(field) === -1) {
						if (property[field] && db_property.hasOwnProperty(field)) {
							if (db_property[field] != property[field]) {
								changeDetected = true;
							}
						}
					}
				}
				if (changeDetected) {
					const before_obj = {
						id: db_property.id,
						street1: db_property.street1,
						name: db_property.name
					}
					const after_obj = {
						id: db_property.id,
						street1: property.street1,
						name: db_property.name
					}
					const fields = ['email','zip','city','state','illi_number'];
					fields.forEach(field => {
						if (property[field]) {
							before_obj[field] = db_property[field];
							after_obj[field] = property[field];
						}
					});
					changes.push({
						before: before_obj,
						after: after_obj
					});
				}
			}
		}
		yield put(actions.getPropertyChangesSuccess(changes));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getPropertyChangesFail(e.response.data));
            }
          }
        yield put(actions.getPropertyChangesFail(e));
    }
}


export function* savePropertyChangesSaga(action) {
    yield put(actions.savePropertyChangesStart());
    try {
        for (let i = 0; i < action.data.length; i++) {
			const property = action.data[i];
			const update_property = {
			  id: property.id,
			  street1: property.street1,
			  name: property.name
			};
			['zip', 'state', 'street2', 'city', 'email', 'phone_number', 'fax_number', 'illi_number'].forEach(field => {
				if (property[field]) {
					update_property[field] = property[field];
				}
			});
			yield axios.put('/property', update_property);
		}
		yield put(actions.savePropertyChangesSuccess('OK'));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.savePropertyChangesFail(e.response.data));
            }
          }
        yield put(actions.savePropertyChangesFail(e));
    }
}

export function* importPropertiesSaga(action) {
    yield put(actions.importPropertiesStart());
	let imported = [];
	let error_imports = [];
	let checks = [];
	for (let i = 1; i < action.data.length; i++) {
		const ndata = action.data[i];
		const r1_1 = findData(ndata, 'street_range1');
		const r1_2 = findData(ndata, 'street_range2');
		const r2_1 = findData(ndata, 'street2_range1');
		const r2_2 = findData(ndata, 'street2_range2');
		const newProperty = {
			illi_number: findData(ndata, 'illi_number'),
			pm_number: findData(ndata, 'pm_number'),
			name: findData(ndata, 'name'),

			street1: findData(ndata, 'street1'),
			address_is_range: r1_1 && r1_2 ? 'Yes' : '',
			street_range1: r1_1,
			street_range2: r1_2,

			street2: findData(ndata, 'street2'),
			address2_is_range: r2_1 && r2_2 ? 'Yes' : '',
			street2_range1: r2_1,
			street2_range2: r2_2,

			city: findData(ndata, 'city'),
			state: findData(ndata, 'state'),
			zip: findData(ndata, 'zip'),
			use_type: findData(ndata, 'use_type'),
			total_building_sqft: findData(ndata, 'total_building_sqft'),
			property_is_for: findData(ndata, 'property_is_for')
		}
		if (newProperty.street_range1 && newProperty.street_range2) {
			if (newProperty.street_range1 === newProperty.street_range2) {
				newProperty.address_is_range = '';
				newProperty.street1 = newProperty.street_range1 + ' ' + newProperty.street1;
				newProperty.street_range1 = '';
				newProperty.street_range2 = '';
			}
		}
		if (newProperty.street2_range1 && newProperty.street2_range2) {
			if (newProperty.street2_range1 === newProperty.street2_range2) {
				newProperty.address2_is_range = '';
				newProperty.street2 = newProperty.street2_range1 + ' ' + newProperty.street2;
				newProperty.street2_range1 = '';
				newProperty.street2_range2 = '';
			}
		}
		const property_id = findData(ndata, 'property_id');
		let agents = [];
		if (findData(ndata, 'PRIMARY_AGENT')) agents.push(findData(ndata, 'PRIMARY_AGENT'));
		if (findData(ndata, 'SECONDARY_AGENT')) agents.push(findData(ndata, 'SECONDARY_AGENT'));
		if (findData(ndata, 'THIRD_AGENT')) agents.push(findData(ndata, 'THIRD_AGENT'));
		if (findData(ndata, 'FOURTH_AGENT')) agents.push(findData(ndata, 'FOURTH_AGENT'));
		try {
			const postObject = {
				property: newProperty,
				agents
			};
			if (property_id) {
				postObject.property_id = property_id;
			}
			if (action.checkOnly) {
				postObject.checkOnly = true;
			}

			const response = yield axios.post('/import_properties', postObject);
			if (response.data) {
				if (!action.checkOnly) {
					let entered_new = false;
					let entered_update = false;
					if (response.data.msg) {
						if (response.data.msg === 'imported new') {
							entered_new = true;
						} else if (response.data.msg === 'imported update') {
							entered_update = true;
						}
					}
					if (entered_new) {
						imported.push(`${findData(ndata, 'street1')} created`);
					} else if (entered_update) {
						imported.push(`${findData(ndata, 'street1')} updated`);
					} else {
						error_imports.push(i);
					}
				} else {
					checks.push(response.data);
				}
			} else {
				error_imports.push(i);
			}
		} catch (err) {
			error_imports.push(i);
		}
		yield put(actions.importPropertiesSuccess(clone({imported, error_imports, checks})));
	}
}

export function* getPropertyOwnersContactsSaga(action) {
    yield put(actions.getPropertyOwnersContactsStart());
    try {
        const response = yield axios.get('/property_owners_contacts?id='+action.data);
        yield put(actions.getPropertyOwnersContactsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getPropertyOwnersContactsFail(e.response.data));
            }
          }
        yield put(actions.getPropertyOwnersContactsFail(e));
    }
}

export function* importCostarPropertiesSaga(action) {
    yield put(actions.importCostarPropertiesStart());
	let imported = [];
	let error_imports = [];
	let checks = [];
	for (let i = 1; i < action.data.length; i++) {
		const ndata = action.data[i];
		
		const newProperty = {
			street1: findData(ndata, 'street1', COSTAR_IMPORTER_FIELDS),
			name: findData(ndata, 'name', COSTAR_IMPORTER_FIELDS),
			city: findData(ndata, 'city', COSTAR_IMPORTER_FIELDS),
			zip: findData(ndata, 'zip', COSTAR_IMPORTER_FIELDS),
			use_type: sanitizePropertyType(findData(ndata, 'use_type', COSTAR_IMPORTER_FIELDS)),
			total_land_sqft: findData(ndata, 'total_land_sqft', COSTAR_IMPORTER_FIELDS),
			total_building_sqft: findData(ndata, 'total_building_sqft', COSTAR_IMPORTER_FIELDS),
		}

		const rangeAddress = extractAddress(findData(ndata, 'street1', COSTAR_IMPORTER_FIELDS))
		if (rangeAddress) {
			newProperty.address_is_range = 'Yes';
			newProperty.street1 = rangeAddress.street
			newProperty.street_range1 = rangeAddress.range1;
			newProperty.street_range2 = rangeAddress.range2;
		}
	
		const {city, state, zip} = extractCityStateZip(findData(ndata, 'owner_city_state_zip', COSTAR_IMPORTER_FIELDS));
		const company = {
			name: findData(ndata, 'owner_name', COSTAR_IMPORTER_FIELDS),
			main_phone: findData(ndata, 'owner_phone', COSTAR_IMPORTER_FIELDS),
			mailing_address1: findData(ndata, 'owner_address', COSTAR_IMPORTER_FIELDS),
			mailing_city: city,
			mailing_state: state,
			mailing_zip: zip,
		}

		const {firstName, lastName} = getFirstNameLastName(findData(ndata, 'owner_contact', COSTAR_IMPORTER_FIELDS));
		const contact = (firstName && lastName) ? {
			first_name:firstName,
			last_name: lastName,
			main_phone: findData(ndata, 'owner_phone', COSTAR_IMPORTER_FIELDS),
		} : null;

		const contact_address_and_info = {
			street1: findData(ndata, 'owner_address', COSTAR_IMPORTER_FIELDS),
			city,
			zip,
			state,
		}

		const postObject = {
			property: newProperty,
			company,
			contact,
			contact_address_and_info,
		};
		try {
			const response = yield axios.post('/import_properties?costar=true', postObject);

			if (response.data) {
				if (!action.checkOnly) {
					let entered_new = false;
					let entered_update = false;
					if (response.data.msg) {
						if (response.data.msg === 'imported new') {
							entered_new = true;
						} else if (response.data.msg === 'imported update') {
							entered_update = true;
						}
					}
					if (entered_new) {
						imported.push(`${findData(ndata, 'street1')} created`);
					} else if (entered_update) {
						imported.push(`${findData(ndata, 'street1')} updated`);
					} else {
						error_imports.push(i);
					}
				} else {
					checks.push(response.data);
				}
			} else {
				error_imports.push(i);
			}
		} catch (err) {
			error_imports.push(i);
		}
		yield put(actions.importCostarPropertiesSuccess(clone({imported, error_imports, checks})));
	}
}

export function* createSendToMarketingSaga(action) {
    yield put(actions.createSendToMarketingStart());
    try {
        const response = yield axios.post('/send_to_marketing', action.data);
        yield put(actions.createSendToMarketingSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createSendToMarketingFail(e.response.data));
            }
          }
        yield put(actions.createSendToMarketingFail(e));
    }
}

export function* getPropertyDocumentsSaga(action) {
    yield put(actions.getPropertyDocumentsStart());
    try {
        const response = yield axios.get('/property_documents?property_id='+action.data);
        yield put(actions.getPropertyDocumentsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getPropertyDocumentsFail(e.response.data));
            }
          }
        yield put(actions.getPropertyDocumentsFail(e));
    }
}

export function* checkIlliNumberForPropertySaga(action) {
    yield put(actions.checkIlliNumberForPropertyStart());
	console.log('checking for illi number 1', action.data);
	const { illi_number_check, property_id } = action.data;
    try {
		let str = '/properties?illi_number_check='+illi_number_check;
		if (property_id) {
			str += `&property_id=${property_id}`;
		}
		console.log('checking str', str);
        const response = yield axios.get(str);
        yield put(actions.checkIlliNumberForPropertySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.checkIlliNumberForPropertyFail(e.response.data));
            }
          }
        yield put(actions.checkIlliNumberForPropertyFail(e));
    }
}