import React from "react";

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";

const Step1 = (props) => {
    const { setOpenedChooseCRMModal, renderElement, data, userPerms } = props;
    return (
		/* Property Information */
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {props.canEditInvoiceRelatedData && <Button
                    color="primary"
                    onClick={() => {
                        setOpenedChooseCRMModal(true);
                    }}
                >
                    SELECT PROPERTY
                </Button>}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("date")}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("project_name")}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("la_city_verification")}
                <div className="group-wrap">
                    Check if in City of L.A -
                    <a href="http://finance.lacity.org" target="_blank" style={{ textDecoration: "underline" }}>
                        <strong>http://finance.lacity.org</strong>
                    </a>
                </div>
            </GridItem>

            <GridItem xs={12} sm={3} md={3}></GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("illi_number", userPerms.hasOwnProperty("Routing number edit") ? "yes" : "no")}
            </GridItem>

            {data.pm_number && (
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("pm_number")}
                </GridItem>
            )}

            <GridItem xs={12} sm={6} md={6}>
                {renderElement("project_address")}
            </GridItem>

			<GridItem xs={12} sm={3} md={3}>
                {renderElement("lead_source")}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                {renderElement("listings_procured_by")}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                {renderElement("deal_generated_by")}
            </GridItem>

            <GridItem xs={12} sm={3} md={3}>
                {renderElement("can_deal_be_submitted_to_public_relations")}
            </GridItem>
            {data.can_deal_be_submitted_to_public_relations === "Yes" && (
                <GridItem xs={12} sm={12} md={12}>
                    {renderElement("can_deal_be_submitted_to_public_relations_comment")}
                </GridItem>
            )}

            <GridItem xs={12} sm={6} md={6}>
                {renderElement("type_of_lease")}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <div className="group-wrap">
                    <GridContainer>
                        <GridItem xs={12} sm={3} md={3}>
                            {renderElement("illi_represented_lessor")}
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9}>
                            {data.illi_represented_lessor === "Yes" && (
                                <div>{renderElement("illi_represented_lessor_agents")}</div>
                            )}
                        </GridItem>
                    </GridContainer>
                </div>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                <div className="group-wrap">
                    <GridContainer>
                        <GridItem xs={12} sm={3} md={3}>
                            {renderElement("illi_represented_lessee")}
                        </GridItem>
                        <GridItem xs={12} sm={9} md={9}>
                            {data.illi_represented_lessee === "Yes" && (
                                <div>{renderElement("illi_represented_lessee_agents")}</div>
                            )}
                        </GridItem>
                    </GridContainer>
                </div>
            </GridItem>
        </GridContainer>
    );
};

export default Step1;
