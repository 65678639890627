import React, { useState, useEffect } from "react";
import RSheetsLeaseForm from "./Lease/RSheetsLeaseForm";
import { Dialog } from "@material-ui/core";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { initialData as initialLeaseData } from 'containers/RSheets/Lease/RSheetsLeaseModel';
import { initialData as initialConsultingData } from './Consulting/RSheetsConsultingModel';
import { initialData as initialTenantRepData } from './TenantRep/RSheetsTenantRepModel';
import { initialData as initialNoListingData } from './NoListing/RSheetsNoListingModel';
import { initialData as initialSaleData } from './Sale/RSheetsSaleModel';
import { initialData as initialResidentialData } from './Residential/RSheetsResidentialModel';
import { initialData as initialLeaseAssignmentFeeData } from './LeaseAssignmentFee/RSheetsLeaseAssignmentFeeModel';
import { initialData as initialSubleaseData } from './Sublease/RSheetsSubleaseModel';
import { initialData as initialCommissionData } from './Commission/RSheetsCommissionModel';
import { doAction } from "./RSheetsLogic";
import { clone } from "../../shared/utility";
import RSheetsConsultingForm from './Consulting/RSheetsConsultingForm';
import RSheetsTenantRepForm from './TenantRep/RSheetsTenantRepForm';
import RSheetsNoListingForm from './NoListing/RSheetsNoListingForm';
import { debounce, get } from 'lodash';
import RSheetsSaleForm from './Sale/RSheetsSaleForm';
import RSheetsResidentialForm from './Residential/RSheetsResidentialForm';
import RSheetsLeaseAssignmentForm from './LeaseAssignmentFee/RSheetsLeaseAssignmentFeeForm';
import RSheetsSubleaseForm from './Sublease/RSheetsSubleaseForm';
import RSheetsCommissionForm from './Commission/RSheetsCommissionForm';
import Button from "../../components/CustomButtons/Button";
import CustomInput from "../../components/CustomInput/CustomInputValidate";
import { getParameterByName } from "../../shared/utility";
import checkPermissions from "../../shared/checkPermissions";

// add initial lease data / default form structure here - this is used for new entries
const obj = {
    '1': initialResidentialData,
    '2': initialLeaseData,
    '3': initialSubleaseData,
    '4': initialSaleData,
    '5': initialTenantRepData,
    '6': initialConsultingData,
    '7': initialCommissionData,
    '8': initialNoListingData,
    '9': initialLeaseAssignmentFeeData,
}

const RSheetsForm = (props) => {
    console.log('RSheetsForm Main', props);
    const { currentType, showForm, setShowForm, action, tab, invoiceEntries, getInvoiceEntries, 
        setTab, clearMessages, permissions, getRsheets, rsheetData,
        viewOnly, setViewOnly, getAdminData, getNextRsIlliNumber, nextIlliNumber, checkRsIlliNumber, isDuplicatingIlliNumber,
        resetAllChanges, clearRSheetData,
    } = props;

    const [formDataObj, setFormDataObj] = useState(null);
    const [data, setTheData] = useState(currentType ? clone(obj[currentType]) : null);
    const [originalData, setOriginalData] = useState(obj[currentType]);

    const [activityTracker, setActivityTracker] = useState(false);
    const [askBeforeDelete, setAskBeforeDelete] = useState(null);
    const [reload, setReload] = useState(false);
    const [updatingInternalReviewer, setUpdatingInternalReviewer] = useState(false);
    const [notes, setNotes] = useState('');
    const [prefill, setPrefill] = useState(false);
    const [prefillPropertyObject, setPrefillPropertyObject] = useState(null);

    const setData = (newObj) => {
        setTheData(clone(newObj));
    }

    const updateDataObj = (field, value) => {
        const newData = clone(data);
        newData[field] = value;
        setData(newData);
    }

    const updateDataMultiObj = (fieldObj = {}) => {
        const newData = clone(data);
        Object.keys(fieldObj).forEach(key => {
            newData[key] = fieldObj[key];
        });
        setData(newData);
    }

    useEffect(() => {
        // on load 
        if (action === 'Add') {
            setData(clone(obj[currentType]));
        }
        const actioned = getParameterByName('p', window.location.href);
        if (actioned) {
            const permitted = checkPermissions("Routing");
            if (permitted) {
                const property_id = getParameterByName('property_id', window.location.href);
                if (property_id) {
                    setPrefill(true);
                    props.getProperty(property_id);
                }
            }
        }
        return () => {
            resetAllChanges();
            clearRSheetData()
        }
    }, []);

    useEffect(() => {
        if (prefill && props.propertyData) {
            console.log('setting prop', props.propertyData)
            setPrefillPropertyObject(props.propertyData)
            window.history.replaceState(null, null, '/rs');
            setPrefill(false);
            props.reset_prefill_property_id();
        }
    }, [props.propertyData, prefill]);

    useEffect(() => {
        if (currentType) {
            
            setData(clone(obj[currentType]));
        }
    }, [currentType]);

    useEffect(() => {
        if (rsheetData && action === 'Edit') {
            getInvoiceEntries(null, rsheetData.id);
            setOriginalData(clone(rsheetData.json_data));
            setData(rsheetData.json_data);
        }
    }, [rsheetData]);

    const close = () => {
        // clear invoices 
        props.clearInvoices();
        setShowForm(false);
        console.log('closing tab', tab);
        if (tab === 'my_drafts' || tab === 'user_drafts') {
            doAction({
                tab, // my_drafts, completed etc...
                getRsheets, // pass redux action
            });
        } else {
            console.log('getting data for admin');
            getAdminData(tab);
        }
    }

    const rsheetStatus = get(props, 'rsheetData.status', null);
    const isForInternalReview = rsheetStatus === 11 ? 
        props.tab === 'pending_my_drafts' ? 
            true 
        : 
            false 
    : 
        false;
    console.log('isForInternalReview', isForInternalReview, props.tab, rsheetStatus)
    const renderInternalReviewActions = () => {
        return <div className="">
            <div style={{backgroundColor:'white', maxWidth:500, margin:'0 auto', padding:20}}>
                <CustomInput
                    label="Internal Review Notes"
                    value={notes}
                    onChange={(e) => {
                        if (notes.length < 255) {
                            setNotes(e.target.value);
                        }
                    }}
                />
            </div>
            {props.rsheetData && <Button color="primary" className="mr-20" onClick={() => {
                setUpdatingInternalReviewer(true);
                props.updateRsInternalReviewer({
                    status: 2,
                    rs_id: props.rsheetData.id
                })
                props.createRsHistory({
                    status: 12, // approve
                    json_data: data,
                    rs_id: props.rsheetData.id,
                    notes
                })
            }}>APPROVE</Button>}
            {props.rsheetData && <Button color="rose" onClick={() => {
                setUpdatingInternalReviewer(true);
                props.updateRsInternalReviewer({
                    status: 1,
                    rs_id: props.rsheetData.id
                })
                props.createRsHistory({
                    status: 13, // deny
                    json_data: data,
                    rs_id: props.rsheetData.id,
                    notes
                })
            }}>DENY</Button>}
        </div>
    }

    useEffect(() => {
        if (props.success_reviewers) {
            if (updatingInternalReviewer) {
                close();
                setUpdatingInternalReviewer(false);
            }
        }
    }, [props.success_reviewers]);

    return <div>

        {/* Residential */}
        {(parseInt(currentType) === 1 && data) && <Dialog
            open={showForm}
            keepMounted
            maxWidth={false}
            fullWidth={true}
            fullScreen={true}
        >
            <div className="dialog-wrapper pb-200">
                
                <RSheetsResidentialForm
                    tab={props.tab}
                    close={close}
                    showCloseBtn={true}
                    action={action}
                    updateDataObj={updateDataObj}
                    updateDataMultiObj={updateDataMultiObj}
                    setData={setData}
                    data={data}
                    formDataObj={formDataObj}
                    setFormDataObj={setFormDataObj}
                    activityTracker={activityTracker}
                    setActivityTracker={setActivityTracker}
                    createRsheetPdf={props.createRsheetPdf}
                    pdfBuffer={props.pdfBuffer}
                    setOpenedForm={setShowForm}
                    viewOnly={viewOnly}
                    getTheProperty={props.getProperty}
                    originalData={props.originalData}
                    setOriginalData={props.setOriginalData}
                    originalContactData={props.originalContactData}
                    setOriginalContactData={props.setOriginalContactData}
                    getRsheets={props.getRsheets}
                    rsTypes={props.rsTypes}
                    level={props.level}
                    getNextRsIlliNumber={getNextRsIlliNumber}
                    nextIlliNumber={nextIlliNumber}
                    isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                    checkRsIlliNumber={checkRsIlliNumber}
                    invoiceEntries={invoiceEntries}
                    setPrefillPropertyObject={setPrefillPropertyObject}
                    prefillPropertyObject={prefillPropertyObject}
                />

                {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
            </div>
        </Dialog>}

        {/* Lease  */}
        {(parseInt(currentType) === 2 && data) && <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    
                    <RSheetsLeaseForm
                        tab={props.tab}
                        close={close}
                        showCloseBtn={true}
                        action={action}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        data={data}
                        formDataObj={formDataObj}
                        setFormDataObj={setFormDataObj}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        createRsheetPdf={props.createRsheetPdf}
                        pdfBuffer={props.pdfBuffer}
                        setOpenedForm={setShowForm}
                        viewOnly={viewOnly}
                        getTheProperty={props.getProperty}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        getRsheets={props.getRsheets}
                        rsTypes={props.rsTypes}
                        level={props.level}
                        rsheetData={rsheetData}
                        historyPush={props.historyPush}
                        invoiceEntries={invoiceEntries}
                        setPrefillPropertyObject={setPrefillPropertyObject}
                        prefillPropertyObject={prefillPropertyObject}
                    />

                    {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
                </div>
        </Dialog>}

        {/* Sublease */}
        {(parseInt(currentType) === 3 && data) && <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    
                    <RSheetsSubleaseForm
                        tab={props.tab}
                        close={close}
                        showCloseBtn={true}
                        action={action}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        data={data}
                        formDataObj={formDataObj}
                        setFormDataObj={setFormDataObj}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        createRsheetPdf={props.createRsheetPdf}
                        pdfBuffer={props.pdfBuffer}
                        setOpenedForm={setShowForm}
                        viewOnly={viewOnly}
                        getTheProperty={props.getProperty}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        getRsheets={props.getRsheets}
                        rsTypes={props.rsTypes}
                        level={props.level}
                        getNextRsIlliNumber={getNextRsIlliNumber}
                        nextIlliNumber={nextIlliNumber}
                        isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                        checkRsIlliNumber={checkRsIlliNumber}
                        invoiceEntries={invoiceEntries}
                        setPrefillPropertyObject={setPrefillPropertyObject}
                        prefillPropertyObject={prefillPropertyObject}
                    />

                    {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
                </div>
        </Dialog>}

        {/* Consulting  */}
        {(parseInt(currentType) === 6 && data) && <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    
                    <RSheetsConsultingForm
                        tab={props.tab}
                        close={close}
                        showCloseBtn={true}
                        action={action}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        data={data}
                        formDataObj={formDataObj}
                        setFormDataObj={setFormDataObj}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        createRsheetPdf={props.createRsheetPdf}
                        pdfBuffer={props.pdfBuffer}
                        setOpenedForm={setShowForm}
                        viewOnly={viewOnly}
                        getTheProperty={props.getProperty}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        getRsheets={props.getRsheets}
                        rsTypes={props.rsTypes}
                        level={props.level}
                        getNextRsIlliNumber={getNextRsIlliNumber}
                        nextIlliNumber={nextIlliNumber}
                        isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                        checkRsIlliNumber={checkRsIlliNumber}
                        invoiceEntries={invoiceEntries}
                        setPrefillPropertyObject={setPrefillPropertyObject}
                        prefillPropertyObject={prefillPropertyObject}
                    />

                    {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
                </div>
        </Dialog>}

        {/* Sale */}
        {(parseInt(currentType) === 4 && data) && <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    
                    <RSheetsSaleForm
                        tab={props.tab}
                        close={close}
                        showCloseBtn={true}
                        action={action}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        data={data}
                        formDataObj={formDataObj}
                        setFormDataObj={setFormDataObj}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        createRsheetPdf={props.createRsheetPdf}
                        pdfBuffer={props.pdfBuffer}
                        setOpenedForm={setShowForm}
                        viewOnly={viewOnly}
                        getTheProperty={props.getProperty}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        getRsheets={props.getRsheets}
                        rsTypes={props.rsTypes}
                        level={props.level}
                        getNextRsIlliNumber={getNextRsIlliNumber}
                        nextIlliNumber={nextIlliNumber}
                        isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                        checkRsIlliNumber={checkRsIlliNumber}
                        invoiceEntries={invoiceEntries}
                        setPrefillPropertyObject={setPrefillPropertyObject}
                        prefillPropertyObject={prefillPropertyObject}
                    />

                    {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
                </div>
        </Dialog>}

        {/* Tenant Rep  */}
        {(parseInt(currentType) === 5 && data) && <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    
                    <RSheetsTenantRepForm
                        tab={props.tab}
                        close={close}
                        showCloseBtn={true}
                        action={action}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        data={data}
                        formDataObj={formDataObj}
                        setFormDataObj={setFormDataObj}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        createRsheetPdf={props.createRsheetPdf}
                        pdfBuffer={props.pdfBuffer}
                        setOpenedForm={setShowForm}
                        viewOnly={viewOnly}
                        getTheProperty={props.getProperty}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        getRsheets={props.getRsheets}
                        rsTypes={props.rsTypes}
                        level={props.level}
                        getNextRsIlliNumber={getNextRsIlliNumber}
                        nextIlliNumber={nextIlliNumber}
                        isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                        checkRsIlliNumber={checkRsIlliNumber}
                        invoiceEntries={invoiceEntries}
                        setPrefillPropertyObject={setPrefillPropertyObject}
                        prefillPropertyObject={prefillPropertyObject}
                    />

                    {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
                </div>
        </Dialog>}

        {/* Option Commission */}
        {(parseInt(currentType) === 7 && data) && <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    
                    <RSheetsCommissionForm
                        tab={props.tab}
                        close={close}
                        showCloseBtn={true}
                        action={action}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        data={data}
                        formDataObj={formDataObj}
                        setFormDataObj={setFormDataObj}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        createRsheetPdf={props.createRsheetPdf}
                        pdfBuffer={props.pdfBuffer}
                        setOpenedForm={setShowForm}
                        viewOnly={viewOnly}
                        getTheProperty={props.getProperty}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        getRsheets={props.getRsheets}
                        rsTypes={props.rsTypes}
                        level={props.level}
                        getNextRsIlliNumber={getNextRsIlliNumber}
                        nextIlliNumber={nextIlliNumber}
                        isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                        checkRsIlliNumber={checkRsIlliNumber}
                        invoiceEntries={invoiceEntries}
                        setPrefillPropertyObject={setPrefillPropertyObject}
                        prefillPropertyObject={prefillPropertyObject}
                    />

                    {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
                </div>
        </Dialog>}

        {/* No Listing */}
        {(parseInt(currentType) === 8 && data) && <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    
                    <RSheetsNoListingForm
                        tab={props.tab}
                        close={close}
                        showCloseBtn={true}
                        action={action}
                        updateDataObj={updateDataObj}
                        updateDataMultiObj={updateDataMultiObj}
                        setData={setData}
                        data={data}
                        formDataObj={formDataObj}
                        setFormDataObj={setFormDataObj}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        createRsheetPdf={props.createRsheetPdf}
                        pdfBuffer={props.pdfBuffer}
                        setOpenedForm={setShowForm}
                        viewOnly={viewOnly}
                        getTheProperty={props.getProperty}
                        originalData={props.originalData}
                        setOriginalData={props.setOriginalData}
                        originalContactData={props.originalContactData}
                        setOriginalContactData={props.setOriginalContactData}
                        getRsheets={props.getRsheets}
                        rsTypes={props.rsTypes}
                        level={props.level}
                        getNextRsIlliNumber={getNextRsIlliNumber}
                        nextIlliNumber={nextIlliNumber}
                        isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                        checkRsIlliNumber={checkRsIlliNumber}
                        invoiceEntries={invoiceEntries}
                        setPrefillPropertyObject={setPrefillPropertyObject}
                        prefillPropertyObject={prefillPropertyObject}
                    />

                    {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
                </div>
        </Dialog>}

        {/* Lease Assignment Fee */}
        {(parseInt(currentType) === 9 && data) && <Dialog
            open={showForm}
            keepMounted
            maxWidth={false}
            fullWidth={true}
            fullScreen={true}
        >
            <div className="dialog-wrapper pb-200">
                
                <RSheetsLeaseAssignmentForm
                    tab={props.tab}
                    close={close}
                    showCloseBtn={true}
                    action={action}
                    updateDataObj={updateDataObj}
                    updateDataMultiObj={updateDataMultiObj}
                    setData={setData}
                    data={data}
                    formDataObj={formDataObj}
                    setFormDataObj={setFormDataObj}
                    activityTracker={activityTracker}
                    setActivityTracker={setActivityTracker}
                    createRsheetPdf={props.createRsheetPdf}
                    pdfBuffer={props.pdfBuffer}
                    setOpenedForm={setShowForm}
                    viewOnly={viewOnly}
                    getTheProperty={props.getProperty}
                    originalData={props.originalData}
                    setOriginalData={props.setOriginalData}
                    originalContactData={props.originalContactData}
                    setOriginalContactData={props.setOriginalContactData}
                    getRsheets={props.getRsheets}
                    rsTypes={props.rsTypes}
                    level={props.level}
                    getNextRsIlliNumber={getNextRsIlliNumber}
                    nextIlliNumber={nextIlliNumber}
                    isDuplicatingIlliNumber={isDuplicatingIlliNumber}
                    checkRsIlliNumber={checkRsIlliNumber}
                    invoiceEntries={invoiceEntries}
                    setPrefillPropertyObject={setPrefillPropertyObject}
                    prefillPropertyObject={prefillPropertyObject}
                />

                {isForInternalReview && <div className="bottom-dweller">{renderInternalReviewActions()}</div>}
            </div>
        </Dialog>}

    </div>
}

const mapStateToProps = (state) => {
	return {
        loading: state.rs.loading,
        rsheetsData: state.rs.rsheetsData,
        rsheetData: state.rs.rsheetData,
        pdfBuffer: state.rs.pdfBuffer,
        nextIlliNumber: state.rs.nextIlliNumber,
        isDuplicatingIlliNumber: state.rs.isDuplicatingIlliNumber,
        invoiceEntries: state.invoice.entriesData,
        success_reviewers: state.rs.success_reviewers,
        propertyData: state.crm_properties.propertyData
    };
};

const mapDispatchToProps = dispatch => {
    const checkRsIlliNumber = (type, illi_number) => {
        dispatch(actions.checkRsIlliNumber({type, illi_number}))
    }

    return {
        createRsHistory: (data) => {
            dispatch(actions.createRsHistory(data));
        },
        getProperty: (id) => {
            dispatch(actions.getProperty(id));
        },
        getRsheets: (params) => {
            dispatch(actions.getRsheets(params));
        },
        getInvoiceEntries: (data, rs_id) => {
            dispatch(actions.getInvoiceEntries(data, rs_id));
        },
        getNextRsIlliNumber: (type) => {
            dispatch(actions.getNextRsIlliNumber(type))
        },
        checkRsIlliNumber: debounce(checkRsIlliNumber, 500),

        resetAllChanges: () => {
            dispatch(actions.setCompanyChanges(null));
            dispatch(actions.setContactChanges(null));
            dispatch(actions.setPropertyChanges(null));
        },

        clearRSheetData: () => {
            dispatch(actions.clearRSheetData())
        },
        clearInvoices: () => {
            dispatch(actions.clearInvoices());
        },
        updateRsInternalReviewer: (data) => {
            dispatch(actions.updateRsInternalReviewer(data));
        },
        getProperty: (data) => {
            dispatch(actions.getProperty(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsForm);
