import React, { useEffect, useMemo, useState } from "react";
import { clone, get, isEmpty } from "lodash";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInputValidate";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomTextArea from "components/CustomTextArea/CustomTextArea";
import { currencyToNumber, formatDollar } from "shared/utility";

import {
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    calculateLeaseTermTotal,
    isEstimatedLease,
    calculateAbatedRanges,
    calculateTermsTotal,
    isFlatFeeCommissionEnabled,
    newYearlyCalculation,
} from "../RSheetsLogic";

const CommissionAdjustmentForm = (props) => {
    const {
        editMode,
        index,
        initValues,
        onAdd,
        onUpdate,
        readonly,
        lesseeLabel = "Lessee",
        lessorLabel = "Lessor",
    } = props;

    const COMMISSION_ADJUSTMENT_OPTIONS = [lesseeLabel, lessorLabel];

    const [localData, setLocalData] = useState({
        option: "",
        value: "",
        comment: "",
    });

    useEffect(() => {
        if (initValues) {
            setLocalData(initValues);
        }
    }, [initValues]);

    const update = (key, value) => {
        const newLocalData = clone(localData);
        newLocalData[key] = value;
        setLocalData(newLocalData);
    };

    const disabled = !localData.option || !localData.value;

    return (
        <div className="boxed">
            <GridContainer>
                {(index === 0 || index) && (
                    <GridItem xs={12} sm={1} md={1}>
                        <div style={{ marginTop: 33 }}>{`#${index + 1}`}</div>
                    </GridItem>
                )}

                <GridItem xs={12} sm={4} md={4}>
                    <CustomSelect
                        label={"Select Option"}
                        options={COMMISSION_ADJUSTMENT_OPTIONS}
                        default={localData.option}
                        choose={(e, n) => {
                            update("option", e);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                        label="Amount"
                        value={localData.value}
                        onChange={
                            !readonly
                                ? (e) => {
                                      update("value", e.target.value);
                                  }
                                : null
                        }
                        isDollar
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomTextArea
                        label="Comment"
                        value={localData.comment}
                        onChange={
                            !readonly
                                ? (e) => {
                                      update("comment", e.target.value);
                                  }
                                : null
                        }
                        readonly={readonly}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {editMode ? (
                        <Button color="primary" disabled={disabled} onClick={() => onUpdate(localData)}>
                            UPDATE
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            disabled={disabled}
                            onClick={() => {
                                onAdd(localData);
                                setLocalData({
                                    option: "",
                                    value: "",
                                    comment: "",
                                });
                            }}
                        >
                            ADD
                        </Button>
                    )}
                </GridItem>
            </GridContainer>
        </div>
    );
};

const CommissionAdjustment = (props) => {
    const {
        commissionValue,
        data,
        renderElement,
        updateDataObj,
        lesseeLabel = "Lessee",
        lessorLabel = "Lessor",
    } = props;

    const [editIndex, setEditIndex] = useState(null);

    const isEditMode = (index) => editIndex === index;
    const setEditMode = (index) => () => setEditIndex(index);
    const closeEditMode = () => setEditIndex(null);

    const commission_adjustment = get(data, "commission_adjustment", null);
    const commission_adjustment_details = get(data, "commission_adjustment_details", []);

    const lease_term = get(data, "lease_term", []);
    const abated = get(data, "abated_rent_ranges", []);

	const isActualDays = get(data, "commission_based_off", "") === "Actual Days";

    // FIXME - DEPRECATED
    const isFlatFee = get(data, "flat_fee_commission", null) === "Yes";

    const isEstimated = isEstimatedLease(data);

    const flatFeeEnabled = isFlatFeeCommissionEnabled(data);

    const totalNetCommission = useMemo(() => {
        if (commissionValue) {
            return currencyToNumber(commissionValue);
        }
        if (isEstimated) {
            let grossCommission = 0;
            let abatedCommission = 0;

            const abated_ranges = calculateEstimatedAbatedRanges({
                lease_term: lease_term,
                abated_rent_ranges: abated,
            });
            const abatedTotal = calculateEstimatedTermsTotal(abated_ranges);

            if (abatedTotal) {
                abatedCommission = abatedTotal.totalCommission;
            }

            lease_term.forEach((term, index) => {
                const totalTermRent = calculateEstimatedTermsTotal(term.ranges, isFlatFee);
                grossCommission += totalTermRent.totalCommission;
            });
            const netCommission = grossCommission + abatedCommission;

            return netCommission;
        } else {
            const abatedRanges = calculateAbatedRanges({
                lease_term,
                abated,
            });

            let grossCommission = 0;
            let abatedCommission = newYearlyCalculation(abatedRanges, isEstimated, isActualDays).commission

            lease_term.forEach(term => {
                const totalTermRent = newYearlyCalculation(term.ranges, isEstimated, isActualDays);
                grossCommission += totalTermRent.commission
            })
            
            const net_commission = grossCommission - abatedCommission;

            return net_commission;
        }
    }, [lease_term, abated, isFlatFee]);

    const handleAddNewAdjustment = (adjustment) => {
        const newDetails = clone(commission_adjustment_details);
        newDetails.push(adjustment);
        updateDataObj("commission_adjustment_details", newDetails);
    };

    const handleDeleteAdjustment = (index) => {
        const newDetails = clone(commission_adjustment_details);
        newDetails.splice(index, 1);
        updateDataObj("commission_adjustment_details", newDetails);
    };

    const handleUpdateAdjustment = (index) => (newAdjustment) => {
        const newDetails = clone(commission_adjustment_details);
        newDetails[index] = newAdjustment;
        updateDataObj("commission_adjustment_details", newDetails);
        closeEditMode();
    };

    const renderAdjustments = () =>
        commission_adjustment_details.map((item, index) => {
            if (isEditMode(index)) {
                return (
                    <CommissionAdjustmentForm
                        initValues={item}
                        index={index}
                        onUpdate={handleUpdateAdjustment(index)}
                        lesseeLabel={lesseeLabel}
                        lessorLabel={lessorLabel}
                        editMode
                    />
                );
            } else {
                return (
                    <div className="boxed" key={`${index}-adjustment`}>
                        <GridContainer>
                            <GridItem xs={12} sm={1} md={1}>
                                <div style={{ marginTop: 33 }}>{`#${index + 1}`}</div>
                            </GridItem>
                            <GridItem xs={12} sm={5} md={5}>
                                <CustomInput label="Option" value={item.option} readonly />
                            </GridItem>
                            <GridItem xs={12} sm={5} md={5}>
                                <CustomInput label="Value" value={item.value} readonly isDollar />
                            </GridItem>
                            <GridItem xs={12} sm={1} md={1}>
                                <div className="hoverable mt-20" onClick={() => handleDeleteAdjustment(index)}>
                                    <i className="fas fa-trash-alt red-text"></i>
                                </div>
                                <div className="hoverable mt-20" onClick={setEditMode(index)}>
                                    <i className="fa fa-edit"></i>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={5} md={5}>
                                <CustomInput label="Comment" value={item.comment} readonly />
                            </GridItem>
                        </GridContainer>
                    </div>
                );
            }
        });

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {renderElement("commission_adjustment")}
            </GridItem>

            {commission_adjustment === "Yes" && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <p style={{ fontWeight: "bold" }}>TOTAL NET COMMISSION: {formatDollar(totalNetCommission)}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CommissionAdjustmentForm
                            onAdd={handleAddNewAdjustment}
                            lesseeLabel={lesseeLabel}
                            lessorLabel={lessorLabel}
                        />
                    </GridItem>

                    {!isEmpty(commission_adjustment_details) && (
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="boxed">{renderAdjustments()}</div>
                        </GridItem>
                    )}

                    <hr />
                    <GridItem xs={12} sm={12} md={12}>
                        <h4>
                            NEW Total NET Commission:{" "}
                            <strong>{formatDollar(totalNetCommission + calculateAdjustedCommission(data))}</strong>
                        </h4>
                    </GridItem>
                </>
            )}
        </GridContainer>
    );
};

export default CommissionAdjustment;
