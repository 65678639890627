import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CommissionSplitsForm from "../../containers/CommissionSplits/CommissionSplitsForm";
import * as actions from '../../store/actions';
import CommissionSplitsList from "../../containers/CommissionSplits/CommissionSplitsList";
import Loader from "../../components/Loader/Loader";
import Button from "../../components/CustomButtons/Button";
import { Cached } from "@material-ui/icons";
import Notification from "../../components/Notification/Notification";
import { getParameterByName } from "shared/utility";
import { clone } from "../../shared/utility";

class CommissionSplitsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'my_drafts',
      data: [],
      openedForm: false,
      formData: null,
      formAction: null,
      entryData: null,
      notification: '',
      finalApproval: null,
      prefill_property_id: null,
      openCsplit: false
    }
  }

  reload = () => {
    if (this.state.tab === 'my_drafts') {
        this.props.getCommissionSplits({
            status: [1,8],
            own: true
        });
    } else if (this.state.tab === 'pending_my_approval') {
      const permissions = checkPermissionsActions('CommissionSplits');
      let status = [8];
      if (permissions) {
        Object.keys(permissions).forEach(p => {
          if (p === 'approve_level_2') {
            status.push(2);
          }
        })
      }
        this.props.getCommissionSplits({
            status,
            pending_agent_approval: true
        });
    } else if (this.state.tab === 'all_completed') {
      this.props.getCommissionSplits({
        status: [13]
      });
    } else if (this.state.tab === 'my_completed') {
      this.props.getCommissionSplits({
        status: [13],
        own: true
      });
    } else if (this.state.tab === 'pending_signature') {
      this.props.getCommissionSplits({
        status: [11,15]
      });
    } else if (this.state.tab === 'final_signature') {
      this.props.getCommissionSplits({
        status: [12]
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tab !== this.state.tab) {
        // load tab's data
        this.reload();
    }
    if (prevProps.data !== this.props.data) {
        console.log('data', this.props.data, prevProps.data);
        this.setState({ data: this.props.data });
    }
    if (prevProps.entryData !== this.props.entryData) {
      const obj = { entryData: clone(this.props.entryData) };
        if (this.state.openCsplit) {
          obj.formData = this.props.entryData.json_data;
          obj.openedForm = true;
          obj.formAction = 'edit';
          obj.openCsplit = false;
          window.history.replaceState(null, null, '/commission_splits');
        }
        this.setState(obj);
    }
  }

  componentDidMount() {
    // My Drafts
    this.reload();
    const finalApproval = getParameterByName('final_approval', window.location.href);
    const obj = {}
    if (finalApproval) {
      const authorizations = checkPermissionsActions('CommissionSplits');
      if (authorizations.hasOwnProperty('final_signature')) {
        obj.tab = 'final_signature',
        obj.finalApproval = finalApproval;
      }
    }
    const action = getParameterByName('p', window.location.href);
    if (action === 'new') {
        const permitted = checkPermissions("CommissionSplits");
        if (permitted) {
            const property_id = getParameterByName('property_id', window.location.href);
            if (property_id) {
              obj.prefill_property_id = parseInt(property_id);
              obj.openedForm = true;
              obj.formAction = 'add';
              obj.entryData = null;
              obj.formData = null;
            }
        }
    } else if (action === 'open') {
      const permitted = checkPermissions("CommissionSplits");
        if (permitted) {
            const cs_id = getParameterByName('cs_id', window.location.href);
            if (cs_id) {
              this.setState({
                openCsplit: true
              })
              this.props.getCommissionSplit(cs_id);
              this.props.getCommissionSplitsHistory(cs_id);
            }
        }
    }
    if (Object.keys(obj).length > 0) this.setState(obj);
  }

  render() {
    const permitted = checkPermissions('CommissionSplits');
    console.log('CommissionSplitsPage', this.props, permitted);
    if (!permitted) {
      return <Redirect to="/dashboard" />;
    }
    const authorizations = checkPermissionsActions('CommissionSplits');

    return (
      <div style={{ marginTop: 20 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>


                <CardIcon
                  color={this.state.tab === 'my_drafts' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'my_drafts'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>My Drafts</strong>
                </CardIcon>


                <CardIcon
                  color={this.state.tab === 'pending_my_approval' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'pending_my_approval'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>Pending My Approval</strong>
                </CardIcon>


                {authorizations.hasOwnProperty('approve_level_2') && <CardIcon
                  color={this.state.tab === 'pending_signature' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'pending_signature'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>Pending Initial/Signature</strong>
                </CardIcon>}

                {authorizations.hasOwnProperty('final_signature') && <CardIcon
                  color={this.state.tab === 'final_signature' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'final_signature'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>Pending Final Signature</strong>
                </CardIcon>}


                <CardIcon
                  color={this.state.tab === 'my_completed' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'my_completed'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>My Completed</strong>
                </CardIcon>

                {(authorizations.hasOwnProperty('final_signature') || authorizations.hasOwnProperty('approve_level_2')) && <CardIcon
                  color={this.state.tab === 'all_completed' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'all_completed'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>All Completed</strong>
                </CardIcon>}

              </CardHeader>
              <CardBody>  
                  {this.state.tab === 'my_drafts' && <div>
                    <Button color="rose" className="mr-20" onClick={() => {
                    this.setState({openedForm: true, formAction:'add', entryData: null, formData: null});
                  }}>ADD NEW</Button>
                  </div>}

                  <Button color="primary" size="sm" className="mr-20" onClick={() => {
                    this.reload();
                  }}><Cached /> Reload</Button>

                  {this.props.loading_data && <Loader center={true} />}
                  <CommissionSplitsList 
                    data={this.state.data}
                    tab={this.state.tab}
                    authorizations={authorizations}
                    deleteCommissionSplit={this.props.deleteCommissionSplit}
                    openEntry={(entryData) => {
                        this.props.getCommissionSplitsHistory(entryData.id);
                        this.setState({
                            entryData,
                            formData: entryData.json_data,
                            openedForm: true,
                            formAction: 'edit'
                        });
                    }}
                    reload={this.reload}
                    success_delete={this.props.success_delete}
                    error_delete={this.props.error_delete}
                    getCsSigningUrl={this.props.getCsSigningUrl}
                    signing_url={this.props.signing_url}
                  />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.openedForm && <CommissionSplitsForm 
            tab={this.state.tab}
            formData={this.state.formData}
            entryData={this.state.entryData}
            action={this.state.formAction}
            authorizations={authorizations}
            close={() => {
                this.reload();
                this.setState({openedForm:false})
            }}
            formAction={this.state.formAction}
            notification={this.state.notification}
            setNotification={(notification) => {
              this.setState({
                notification
              });
            }}
            prefill_property_id={this.state.prefill_property_id}
            reset_prefill_property_id={() => {
              this.setState({
                prefill_property_id: null
              })
            }}
        />}

        {this.state.notification && <Notification 
            message={this.state.notification}
            open={this.state.notification ? true : false}
            close={() => {
                this.setState({
                  notification: ''
                })
            }}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
    const cs = state.commission_splits;
    return {
        data: cs.data,
        loading_data: cs.loading_data,
        success_delete: cs.success_delete,
        error_delete: cs.error_delete,
        entryData: cs.entryData,
        signing_url: cs.signing_url
    }
}

const mapDispatchToProps = dispatch => {
  return {
    getCommissionSplits: (data) => {
        dispatch(actions.getCommissionSplits(data));
    },
    getCommissionSplit: (data) => {
      dispatch(actions.getCommissionSplit(data));
    },
    deleteCommissionSplit: (data) => {
        dispatch(actions.deleteCommissionSplit(data));
    },
    getCommissionSplitsHistory: (data) => {
        dispatch(actions.getCommissionSplitsHistory(data));
    },
    getCsSigningUrl: (data) => {
      dispatch(actions.getCsSigningUrl(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionSplitsPage);
