import React, { useState, useEffect, useRef } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Table from "components/Table/Table.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate";
import Button from "../../components/CustomButtons/Button";
import { getUser } from "../../shared/authValidation";
import { 
    statusDescriptionsDict, statusDescriptions,
    getHistory,
    checkIfAlreadySubmitted,
    checkIfAllContactsHaveUserIds
} from "./CommissionSplitsLogic";
import moment from "moment";

const Step4 = (props) => {
    const [startedSavingHistory, setStartedSavingHistory] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [shouldCloseForm, setShouldCloseForm] = useState(false);
    const [notes, setNotes] = useState('');
    const { currentUserIsSubmitter, currentUserIsAnAgentApprover } = props;

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSuccess = usePrevious(props.success_history);

    useEffect(() => {
        console.log('props->', props.success_history, startedSavingHistory)
        if (props.success_history && startedSavingHistory) {
            console.log('props-> getting history data', props, startedSavingHistory, shouldCloseForm)
            if (props.entryData) props.getCommissionSplitsHistory(props.entryData.id)
            if (shouldCloseForm && props.cancel) {
                props.cancel();
            }
            setStartedSavingHistory(false);
        }
    }, [props.success_history, startedSavingHistory]);

    useEffect(() => {
        if (props.entryData) props.getCommissionSplitsHistory(props.entryData.id)
        setCurrentUser(getUser());
    }, []);
    // Load all Agents from Step2 
    // Compare with all current users, 
    const { dataObj, all_potential_reviewers, history } = props;

    const allContactsHaveUserIds = checkIfAllContactsHaveUserIds(all_potential_reviewers, dataObj.contacts);
    const hasAlreadyBeenSubmitted = checkIfAlreadySubmitted(props.history);
    console.log('Step4', 
        currentUserIsSubmitter,
        currentUserIsAnAgentApprover, 
        currentUser, 
        props, 
        all_potential_reviewers, dataObj.contacts, allContactsHaveUserIds);

    return <div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Table 
                    tableHeaderColor="primary"
                    tableHead={['Is Current User?','Name','Email','Status']}
                    tableData={dataObj.contacts.map((a,i) => {
                        let isCurrentUser = false;
                        let currentUser = null;
                        if (all_potential_reviewers) {
                            for (let j = 0; j < all_potential_reviewers.length; j++) {
                                const apr = all_potential_reviewers[j];
                                if (apr.mail === a.email) {
                                    isCurrentUser = true;
                                    currentUser = apr;
                                }
                            }
                        }
                        const uid = currentUser ? currentUser.id : null;
                        const hs = getHistory(uid, history);
                        const status = hs ? statusDescriptions[hs.action] : 'Pending Submission to Associate';
                        return [
                            isCurrentUser ? <div style={{color:'green'}}>Yes</div> : <div style={{color:'red'}}>No</div>,
                            `${a.first_name} ${a.last_name} ${(a.dba_name && a.dba_active) ? `DBA ${a.dba_name}` : ``}`,
                            a.email,
                            status === 'Approved by Associate' ? <div className="green-text">{status}</div> : '',
                        ]
                    })}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <hr />
                <h4>History</h4>
                <Table 
                    tableHeaderColor="primary"
                    tableHead={['Date','Name','Action','Notes']}
                    tableData={
                        props.history ? props.history.map(h => {
                            return [
                                moment(h.timestamp.replace("T", " ")).format("MM/DD/YYYY h:mm a"),
                                h.name,
                                statusDescriptions[h.action],
                                h.message
                            ]
                        }) : []
                    }
                />
            </GridItem>
            <GridItem xs={4} sm={4} md={4}>
                {(props.entryData?.id && currentUserIsSubmitter && !hasAlreadyBeenSubmitted) && <div className="mt-20">
                    <CustomInput 
                        label="Notes"
                        value={notes}
                        onChange={(e) => {
                            setNotes(e.target.value);
                        }}
                    />
                    <Button color="primary" size="sm"
                        disabled={allContactsHaveUserIds ? false : true}
                        onClick={() => {
                            setStartedSavingHistory(true);
                            let newHistories = [{
                                cs_id: props.entryData?.id,
                                action: 8, // SENT TO PENDING APPROVAL BY AGENT,
                                json_data: JSON.stringify(dataObj),
                                message: notes
                            }];
                            let notifications = [];
                            dataObj.contacts.forEach(c => {
                                let isCurrentUser = false;
                                let currentUser = null;
                                if (all_potential_reviewers) {
                                    for (let j = 0; j < all_potential_reviewers.length; j++) {
                                        const apr = all_potential_reviewers[j];
                                        if (apr.mail === c.email) {
                                            isCurrentUser = true;
                                            currentUser = apr;
                                        }
                                    }
                                }
                                const uid = currentUser ? currentUser.id : null;
                                newHistories.push({
                                    cs_id: props.entryData?.id,
                                    action: statusDescriptionsDict['Sent to Pending Approval by Associate'], 
                                    json_data: JSON.stringify(dataObj),
                                    message: '',
                                    user_id: uid
                                })
                                notifications.push({
                                    name: `${c.first_name} ${c.last_name}`,
                                    email: c.email,
                                    id: props.entryData?.id,
                                    type: 'pending approval'
                                })
                            })

                            
                            props.createCommissionSplitsHistory(newHistories, notifications);
                            props.save({
                                status: 8
                            });
                        }}
                    >SUBMIT FOR REVIEW</Button>
                </div>}

                {(props.entryData?.id && currentUserIsAnAgentApprover) && <div className="mt-20">
                    <CustomInput 
                        label="Notes"
                        value={notes}
                        onChange={(e) => {
                            setNotes(e.target.value);
                        }}
                    />
                    <Button color="primary" size="sm"
                        className="mr-20"
                        disabled={allContactsHaveUserIds ? false : true}
                        onClick={() => {
                            setStartedSavingHistory(true);
                            setShouldCloseForm(true);
                            let newHistories = [{
                                cs_id: props.entryData?.id,
                                action: statusDescriptionsDict['Approved by Associate'],
                                json_data: JSON.stringify(dataObj),
                                message: notes
                            }];
                            props.createCommissionSplitsHistory(newHistories);
                        }}
                    >APPROVE</Button>

                    <Button color="white" size="sm"
                        disabled={allContactsHaveUserIds ? false : true}
                        onClick={() => {
                            setStartedSavingHistory(true);
                            setShouldCloseForm(true);
                            let newHistories = [{
                                cs_id: props.entryData?.id,
                                action: statusDescriptionsDict['Rejected by Associate'],
                                json_data: JSON.stringify(dataObj),
                                message: notes
                            }];
                            props.createCommissionSplitsHistory(newHistories);
                        }}
                    >DENY</Button>
                </div>}
            </GridItem>
        </GridContainer>
    </div>
}

export default Step4;