import { put } from "redux-saga/effects";
import axios from "../axios/axios-invoice";
import * as actions from "../actions/invoice";
import { generateInvoiceHTML } from "../../containers/Invoices/InvoiceObj";

export function* getInvoiceEntriesSaga(action) {
    yield put(actions.getInvoiceEntriesStart());
    try {
        if (action.data) {
            const response = yield axios.get(`/entries?status=${action.data}`);
            yield put(actions.getInvoiceEntriesSuccess(response.data));
        } else if (action.rs_id) {
            const response = yield axios.get(`/entries?rs_id=${action.rs_id}`);
            yield put(actions.getInvoiceEntriesSuccess(response.data));
        }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.getInvoiceEntriesFail(e.response.data));
            }
        }
        yield put(actions.getInvoiceEntriesFail(e));
    }
}

export function* createInvoiceEntrySaga(action) {
    yield put(actions.createInvoiceEntryStart());
    try {
        const response = yield axios.post("/entry", action.data);
        yield put(actions.createInvoiceEntrySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.createInvoiceEntryFail(e.response.data));
            }
        }
        yield put(actions.createInvoiceEntryFail(e));
    }
}

export function* updateInvoiceEntrySaga(action) {
    yield put(actions.updateInvoiceEntryStart());
    try {
        const response = yield axios.put("/entry", action.data);
        yield put(actions.updateInvoiceEntrySuccess(response.data));
        // yield put(actions.getInvoiceEntries());
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.updateInvoiceEntryFail(e.response.data));
            }
        }
        yield put(actions.updateInvoiceEntryFail(e));
    }
}

export function* deleteInvoiceEntrySaga(action) {
    yield put(actions.deleteInvoiceEntryStart());
    try {
        const response = yield axios.delete("/entry?id=" + action.data);
        yield put(actions.deleteInvoiceEntrySuccess(response.data));
        // yield put(actions.getInvoiceEntries());
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.deleteInvoiceEntryFail(e.response.data));
            }
        }
        yield put(actions.deleteInvoiceEntryFail(e));
    }
}

export function* getInvoicePdfSaga(action) {
    yield put(actions.getInvoicePdfStart());
    try {
        const response = yield axios.post("/entry/pdf", action.data);
        yield put(actions.getInvoicePdfSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.getInvoicePdfFail(e.response.data));
            }
        }
        yield put(actions.getInvoicePdfFail(e));
    }
}

export function* resendInvoiceEmailSaga(action) {
    yield put(actions.resendInvoiceEmailStart());
    try {
        const response = yield axios.put('/entry/resend_invoice_email', action.data);
        yield put(actions.resendInvoiceEmailSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.resendInvoiceEmailFail(e.response.data));
            }
          }
        yield put(actions.resendInvoiceEmailFail(e));
    }
}

export function* voidInvoicesSaga(action) {
    yield put(actions.voidInvoicesStart());
    try {
        for (let i = 0; i < action.data.length; i++) {
            const data = action.data[i];
            const response = yield axios.put('/entry', data);
            console.log('response voiding invoices', response.data);
        }
        if (action.sendNotificationTo) {
            const notification_response = yield axios.post('/notify', {
                email: action.sendNotificationTo.email,
                type: 'void',
                name: action.sendNotificationTo.name
            });
            console.log('response notification for voiding invoices', notification_response.data);
        }
        yield put(actions.voidInvoicesSuccess('OK'));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.voidInvoicesFail(e.response.data));
            }
          }
        yield put(actions.voidInvoicesFail(e));
    }
}


export function* getInvoicePaymentsSaga(action) {
    yield put(actions.getInvoicePaymentsStart());
    try {
        if (action.data.invoice_id) {
            const response = yield axios.get('/payments?invoice_id='+action.data.invoice_id);
            yield put(actions.getInvoicePaymentsSuccess(response.data));
        } else if (action.data.rs_id) {
            const response = yield axios.get('/payments?rs_id='+action.data.rs_id);
            yield put(actions.getInvoicePaymentsSuccess(response.data));
        }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getInvoicePaymentsFail(e.response.data));
            }
          }
        yield put(actions.getInvoicePaymentsFail(e));
    }
}

export function* createInvoicePaymentSaga(action) {
    yield put(actions.createInvoicePaymentStart());
    try {
        const response = yield axios.post("/payment", action.data);
        yield put(actions.createInvoicePaymentSuccess(response.data));
        const response_payments = yield axios.get('/payments?invoice_id='+action.data.invoice_id);
        yield put(actions.getInvoicePaymentsSuccess(response_payments.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.createInvoicePaymentFail(e.response.data));
            }
        }
        yield put(actions.createInvoicePaymentFail(e));
    }
}

export function* updateInvoicePaymentSaga(action) {
    yield put(actions.updateInvoicePaymentStart());
    try {
        const response = yield axios.put("/payment", action.data);
        yield put(actions.updateInvoicePaymentSuccess(response.data));
        const response_payments = yield axios.get('/payments?invoice_id='+action.data.invoice_id);
        yield put(actions.getInvoicePaymentsSuccess(response_payments.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
                return yield put(actions.updateInvoicePaymentFail(e.response.data));
            }
        }
        yield put(actions.updateInvoicePaymentFail(e));
    }
}