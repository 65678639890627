import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Close, Delete, Edit, Save, ArrowUpward } from "@material-ui/icons";
import { concat, get, isEmpty, orderBy, pullAt, set, sortBy } from "lodash";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import * as actions from "store/actions";
import termLogic from "../RSheets/CommonCustomElements/Term/TermLogicMain";
import { clone, formatDollar } from "shared/utility";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate";
import NotificationAsk from "../../components/Notification/NotificationAsk";

import {
    calculateAbatedRanges,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
	calculateEstimatedTermsTotal,
    getFlatFeeCommission,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    shouldGetPaymentAmount,
    getPaymentAmount
} from "../RSheets/RSheetsLogic";

import { calculateTermsTotal } from "./InvoiceLogic";
import InvoiceApplyPaymentDialog from "./InvoiceApplyPaymentDialog";

const formatDate = (date) => {
    let newDate = '';
    if (date) {
        const date_arr = date.split('T');
        if (date_arr[0]) {
            const arr = date_arr[0].split('-');
            newDate = `${arr[1]}/${arr[2]}/${arr[0]}`;
        }
    }
    return newDate;
}

const reformatDate = (date) => {
    let newDate = '';
    if (date) {
        const arr = date.split('/');
        newDate = `${arr[2]}-${arr[0]}-${arr[1]}`;
    }
    return newDate;
}

const InvoicePaymentHistory = (props) => {
    console.log('InvoicePaymentHistory', props)
    const { open, close, invoice, updateInvoicePayment } = props;

    const [editMode, setEditMode] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [localPayments, setLocalPayments] = useState([]);
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(null);
    const [openApplyPayment, setOpenApplyPayment] = useState(false);

    const handlePaymentFormOpen = () => setOpenApplyPayment(true);
    const handlePaymentFormClose = () => setOpenApplyPayment(false);

    const invoice_number = get(invoice, 'data.invoice', '');

    const getTotalPaid = (payments) => {
        console.log('payments', payments)
        let paid = 0;
        if (payments) {
            payments.forEach(p => {
                if (invoice.id) {
                    if (p.invoice_id === invoice.id) {
                        if (p.payment_amount) paid = paid + parseFloat(p.payment_amount.replace(/[$,]/g, ""));
                    }
                }
            })
        }
        return paid;
    }

    useEffect(() => {
        if (!isEmpty(props.invoicePayments)) {
            setLocalPayments(props.invoicePayments.map(ip => {
                const newIP = clone(ip);
                newIP.payment_date = formatDate(ip.payment_date);
                return newIP;
            }));
        }
    }, [props.invoicePayments]);

    console.log('localPayments', localPayments);

    const handleChange = (field, value, id) => {
        const newPayments = clone(localPayments).map(p => {
            const np = clone(p);
            if (id === np.id) {
                np[field] = value;
            }
            return np;
        });
        setIsChanged(true);
        setLocalPayments(newPayments);
    };

    const rsData = get(invoice, "json_data", {});
    const isEstimated = isEstimatedLease(rsData);

    const calcs = termLogic.calculateLeaseTerm(rsData);
    const invoice_calcs = termLogic.calculateInvoiceRows(calcs, rsData);
    console.log('invoice_calcs InvoiceFullForm', invoice_calcs);

    const payment_data = get(invoice, 'data.payment_schedule_data.data', []).map(pd => {
        console.log('pdd', pd)
        const newPD = clone(pd);
        newPD.shouldGetPaymentAmount = shouldGetPaymentAmount(newPD.option);
        newPD.amount = newPD.shouldGetPaymentAmount ? formatDollar(getPaymentAmount(rsData, newPD.option)) : newPD.value;
        newPD.amountNum = parseFloat(newPD.amount.replace(/[$,]/g, ""));
        return newPD;
    });
    console.log('payment_data', payment_data);

    let ranges = [];
    get(rsData, "lease_term", []).forEach((lt) => {
        ranges = ranges.concat(lt.ranges);
    });
    const abatedRanges = isEstimated
        ? calculateEstimatedAbatedRanges({
              lease_term: get(rsData, "lease_term", []),
              abated_rent_ranges: get(rsData, "abated_rent_ranges", []),
          })
        : calculateAbatedRanges({
              lease_term: get(rsData, "lease_term", []),
              abated: get(rsData, "abated_rent_ranges", []),
          });
    const totalRanges = isEstimated
        ? orderBy(concat(ranges, abatedRanges), ["month1", "day1"], "asc")
        : sortBy(concat(ranges, abatedRanges), (r) => new Date(r.date1));

    const total = isEstimated ? calculateEstimatedTermsTotal(totalRanges) : calculateTermsTotal(totalRanges);

    let totalCommissionBeforeAdjustment = 0;

    const flatFeeEnabled = isFlatFeeCommissionEnabled(rsData);
    if (flatFeeEnabled) {
        totalCommissionBeforeAdjustment = getFlatFeeCommission(rsData);
    } else {
        let total_commission_for_this_invoice = 0;
        payment_data.forEach(pd => {
            total_commission_for_this_invoice = total_commission_for_this_invoice + pd.amountNum;
        })
        totalCommissionBeforeAdjustment = total_commission_for_this_invoice;
    }
    const calculatedAdjustmentCommission = calculateAdjustedCommission(rsData);
    const totalCommission = totalCommissionBeforeAdjustment + calculatedAdjustmentCommission;
    console.log('totalCommission', totalCommissionBeforeAdjustment, calculatedAdjustmentCommission);
    const totalPaid = getTotalPaid(localPayments);

    const paidTextColor = parseFloat(totalPaid) < parseFloat(totalCommission) ? "red" : "green";

    const renderPayments = () => { 
        const renderedPayments = localPayments.filter(payment => {
            if (payment.invoice_id !== invoice.id) {
                return false;
            }
            return true;
        }).map((payment, index) => {
            const { payment_date, payment_amount, check_number, payee_name } = payment;
            return (
                <GridItem xs={12} sm={12} md={6} key={index}>
                    <div className="boxed">
                        <GridContainer>
                            <GridItem xs={6} sm={12} md={12}>
                                <h4>Payment #{index+1}</h4>
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                {editMode === index ? (
                                    <Datetime
                                        inputProps={{
                                            placeholder: "Payment Date",
                                        }}
                                        closeOnSelect={true}
                                        onChange={(e) => {
                                            let stringDate = e;
                                            if (typeof e === "object") {
                                                stringDate = e.format("MM/DD/YYYY");
                                            }
                                            handleChange("payment_date", stringDate, payment.id);
                                        }}
                                        timeFormat={false}
                                        value={payment_date}
                                    />
                                ) : (
                                    <CustomInput label={"Payment Date"} value={payment_date} readonly readonly_show_gray />
                                )}
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    label={"Payment Amount"}
                                    value={payment_amount}
                                    onChange={(e) => {
                                        handleChange("payment_amount", e.target.value, payment.id);
                                    }}
                                    isDollar
                                    readonly={editMode !== index}
                                    readonly_show_gray
                                    autoFocus={editMode === index ? true : false}
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    label={"Check Number"}
                                    onChange={(e) => {
                                        handleChange("check_number", e.target.value, payment.id);
                                    }}
                                    value={check_number}
                                    readonly={editMode !== index}
                                    readonly_show_gray
                                />
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6}>
                                <CustomInput
                                    label={"Payee Name"}
                                    onChange={(e) => {
                                        handleChange("payee_name", e.target.value, payment.id);
                                    }}
                                    value={payee_name}
                                    readonly={editMode !== index}
                                    readonly_show_gray
                                />
                            </GridItem>
                            {/* <GridItem xs={6} sm={6} md={6}>
                                <div className={editMode !== index ? 'gray-bg-input avoid-clicks' : ''}>
                                    <CustomSelect
                                        label="Applied to current Invoice"
                                        options={['Yes','No']}
                                        choose={(e) => {
                                            handleChange("invoice_id", e === 'Yes' ? invoice.id : null, index);
                                        }}
                                        default={payment.invoice_id === invoice.id ? 'Yes' : 'No'}
                                    />
                                </div>
                            </GridItem> */}
                        </GridContainer>
                        {editMode !== index && (
                            <div style={{ textAlign: "right" }}>
                                <Tooltip title="Edit">
                                    <IconButton onClick={() => {
                                        setEditMode(index);
                                    }}>
                                        <Edit />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        {editMode === index && (
                            <div style={{ textAlign: "right" }}>
                                <Tooltip title="Update">
                                    <IconButton className="mr-20" onClick={() => {
                                        updateInvoicePayment({
                                            id: payment.id,
                                            invoice_id: payment.invoice_id,
                                            payment_date: reformatDate(payment_date),
                                            payment_amount,
                                            check_number,
                                            payee_name
                                        })
                                        setEditMode(null);
                                    }}>
                                        <Save />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete">
                                    <IconButton onClick={() => {
                                        setOpenedAskBeforeDelete(payment);
                                    }}>
                                        <Delete />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                    </div>
                </GridItem>
            );
        });
        if (renderedPayments.length === 0) {
            return <GridItem xs={12} md={12}><em>* No Payments *</em></GridItem>
        }
        return renderedPayments;
    };

    const renderPayments2 = () => { 
        const renderedPayments = localPayments.filter(payment => {
            if (payment.invoice_id === invoice.id) {
                return false;
            }
            return true;
        }).map((payment, index) => {
            const { payment_date, payment_amount, check_number, payee_name } = payment;
            return (
                <GridItem xs={12} sm={12} md={4} key={index}>
                    <div className="boxed">
                        <GridContainer>
                            <GridItem xs={6} sm={12} md={12}>
                                <h4>Payment #{index+1}</h4>
                                <div>Payment Date: {payment_date}</div>
                                <div>Payment Amount: {payment_amount}</div>
                                <div>Check Number: {check_number}</div>
                                <div>Payee Name: {payee_name}</div>
                                <div>
                                    <Tooltip title="Apply to this invoice">
                                        <IconButton
                                            onClick={() => {
                                                updateInvoicePayment({
                                                    id: payment.id,
                                                    invoice_id: invoice.id
                                                })
                                            }}
                                        >
                                            <ArrowUpward />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            );
        });
        if (renderedPayments.length === 0) {
            return <GridItem xs={12} md={12}><em>* No Payments *</em></GridItem>
        }
        return renderedPayments;
    };

    return (
        <Dialog open={open} onClose={close} keepMounted maxWidth={false} fullWidth={false}>
            <div
                className="close-btn"
                onClick={() => {
                    close();
                }}
            >
                <Close />
            </div>
            <div style={{ margin: 20, padding: 50 }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <h3>PAYMENT HISTORY FOR INVOICE #{invoice_number}</h3>
                </div>
                <div>
                    <p>Total commission due: {formatDollar(totalCommission)}</p>
                    <p style={{ color: paidTextColor }}>Total paid to date: {formatDollar(totalPaid)}</p>
                </div>
                <div>
                    <Button color="danger" style={{ marginBottom: '15px' }} onClick={handlePaymentFormOpen}>
                        APPLY NEW PAYMENT
                    </Button>
                </div>

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h4>Payments applied to this invoice</h4>
                    </GridItem>
                    {renderPayments(true)}
                    <GridItem xs={12} sm={12} md={12}>
                        <hr />
                        <h4>Payments not applied to this invoice</h4>
                    </GridItem>
                    {renderPayments2(false)}

                    <GridItem xs={12} sm={12} md={12}>
                        <Button
                            style={{ marginTop: 30, marginRight: 20 }}
                            onClick={() => {
                                close();
                            }}
                        >
                            CLOSE
                        </Button>
                    </GridItem>
                </GridContainer>
            </div>

            {openApplyPayment && (
                <InvoiceApplyPaymentDialog invoice={invoice} open={openApplyPayment} close={handlePaymentFormClose} />
            )}

            {openedAskBeforeDelete && <NotificationAsk 
                open={openedAskBeforeDelete ? true : false}
                close={() => {
                    setOpenedAskBeforeDelete(null);
                }}
                title="Are You Sure?"
                message="Do you want to delete this payment?"
                success={() => {
                    const payment = openedAskBeforeDelete;
                    updateInvoicePayment({
                        id: payment.id,
                        invoice_id: payment.invoice_id,
                        active: 0
                    })
                    setOpenedAskBeforeDelete(null);
                }}
            />}
        </Dialog>
    );
};

const mapStateToProps = (state) => {
    return {
        invoicePayments: state.invoice.invoicePayments,
        success_payments: state.invoice.success_payments,
        error_payments: state.invoice.error_payments
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoicePayments: (data) => {
            dispatch(actions.getInvoicePayments(data));
        },
        createInvoicePayment: (data) => {
            dispatch(actions.createInvoicePayment(data));
        },
        updateInvoicePayment: (data) => {
            dispatch(actions.updateInvoicePayment(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePaymentHistory);
