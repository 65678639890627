import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { clone } from "../../shared/utility";

const Step5 = (props) => {
    const { final_approvers, dataObj, setDataObj, currentUserIsAnAgentApprover } = props;
    return <div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <div className={currentUserIsAnAgentApprover ? 'no-click' : ''}>
                    <CustomSelect 
                        options={final_approvers ? final_approvers : []}
                        label="Select an approver"
                        indx="name"
                        desc="name"
                        choose={(e, n) => {
                            console.log('chosen', e);
                            if (final_approvers) {
                                const newData = clone(dataObj);
                                newData.final_approver_name = e;
                                final_approvers.forEach(f => {
                                    if (f.name === e) {
                                        newData.final_approver_email = f.email;
                                        newData.final_approver_position = f.position;
                                    }
                                })
                                setDataObj(newData);
                            }
                        }}
                        default={dataObj.final_approver_name}
                    />
                </div>
            </GridItem>
        </GridContainer>
    </div>
}

export default Step5;