import React from 'react';
import { useEffect, useState } from 'react';
import { get } from 'lodash';

import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import CustomSelect from 'components/CustomSelect/CustomSelect.jsx';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { calculateDays, clone, round2dec } from '../../../../../shared/utility';

import { required } from 'shared/validation';

const TermEstimatedMainForm = (props) => {
	console.log('TermForm', props);
    const [localData, setLocalData] = useState({
        month1: '',
        month2: '',
        day1: '',
        day2: '',
        value: '',
        flat_fee: '',
        commissionRate: ''
    });

    const update = (field, value) => {
        const clonedLocalData = clone(localData);
        clonedLocalData[field] = value;
        setLocalData(clonedLocalData);
    }

    const updateMultiFields = (obj) => {
        if (!obj) return;
        const clonedLocalData = clone(localData);
        Object.keys(obj).forEach(field => {
            clonedLocalData[field] = obj[field];
        })
        setLocalData(clonedLocalData);
    }

	const {
        localDataLoad,
        current_year,
        current_range,
        save,
        suggestion,
		data,
		isRequired,
	} = props;

    const ranges = get(data, `lease_term[${current_year}].ranges`, []);

    const createMonthOptions = () => {
        // year 1 = 1 to 12  (0 * 12) + 1 = 1
        // year 2 = 13 to 24 (1 * 12) + 1 = 13
        // year 3 = 25 to 36 (2 * 12) + 1 = 25
        const first_month = (current_year * 12) + 1;
        const lease_length = get(data, 'lease_length', null);
        let options = [];
        for (let i = first_month; i < (first_month + 12); i++) {
            if (lease_length) {
                const lease_length_num = parseInt(lease_length);
                if (i > lease_length_num) continue;
            }
            options.push('Month '+i);
        }
        return options;
    }

    const createDayOptions = () => {
        let options = [];
        for (let i = 1; i < 31; i++) {
            options.push('Day '+i);
        }
        return options;
    }

    const monthOptions = createMonthOptions();
    const dayOptions = createDayOptions();

    useEffect(() => {
        if (localDataLoad) {
            setLocalData(localDataLoad);
        }
        if (suggestion) {
            const rate_type_cpi_percentage = data.rate_type_cpi_percentage ? parseInt(data.rate_type_cpi_percentage) : 0;
            const rate_type = data.rate_type ? data.rate_type : null;
            let amount = '';
            if (rate_type === 'Fixed Percentage') {
                if (rate_type_cpi_percentage) {
                    // check if year is after Year 1
                    console.log('cyear', current_year);
                    if (current_year > 0) {
                        const last_ranges = get(data, `lease_term[${current_year - 1}].ranges`, []);
                        // get last amount 
                        last_ranges.forEach(range => {
                            const val = range.value ? parseFloat(range.value) : 0;
                            if (val) { 
                                amount = round2dec(val + (val * (rate_type_cpi_percentage * 0.01)));
                            }
                        });
                    }
                }
            }

            let month = '';
            let day = '';
            const ranges = get(data, `lease_term[${current_year}].ranges`, []);
            ranges.forEach(range => {
                if (range.month2) {
                    const day_int = range.day2 ? parseInt(range.day2.split(' ')[1]) : 0;
                    const month_int = parseInt(range.month2.split(' ')[1]);
                    if (range.day2) {
                        if (range.day2 === 'Day 30') {
                            day = 'Day 1';
                            month = 'Month ' + (month_int + 1);
                        } else {
                            day = 'Day ' + (day_int + 1);
                            month = 'Month ' + month_int;
                        }
                    }
                }
            })
            if (month && day) {
                updateMultiFields({
                    month1: month,
                    day1: day,
                    value: amount
                });
            } else {
                update('value', amount);
            }
        }
    }, []);


    const checkValidity = () => {
        let errors = [];
        if (!localData.month1 || 
            !localData.month2 || 
            !localData.day1 ||
            !localData.day2 ||
            !localData.value ||
            !localData.commissionRate
            ) {
                errors.push('Missing fields');
        }
        const days1 = calculateDays(localData.month1, localData.day1);
        const days2 = calculateDays(localData.month2, localData.day2);
        if (days2 <= days1) errors.push('Days are not in correct order');
        // check if these days overlap with other ranges
        const ranges = get(data, `lease_term[${current_year}].ranges`, []);
        let overlap = false;
        ranges.forEach(range => {
            if (range.month1 && range.day1 && range.month2 && range.day2) {
                if (
                    (range.month1 !== localData.month1) && 
                    (range.day1 !== localData.day1) && 
                    (range.month2 !== localData.month2) &&
                    (range.day2 !== localData.day2)
                ) {
                    const d1 = calculateDays(range.month1, range.day1);
                    const d2 = calculateDays(range.month2, range.day2);
                    if (days1 >= d1 && days1 <= d2) overlap = true;
                    if (days2 >= d1 && days2 <= d2) overlap = true;
                }
            }
        })
        if (overlap) errors.push('Range cannot overlap with other ranges');
        return errors.join(', ');
    }

    const error_message = checkValidity();

	return (
		<>
			<GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 style={{display:'inline-block',marginRight:20}}>Range # {current_range ? current_range : ranges.length + 1}</h4>
                    <Button
                        size="sm"
                        disabled={error_message ? true : false}
                        color="primary"
                        style={{marginRight:10}}
                        onClick={() => {
                            save(localData);
                            props.close();
                        }}
                    >SAVE</Button>
                    <Button
                        size="sm"
                        color="white"
                        style={{marginRight:20}}
                        onClick={() => {
                            props.close();
                        }}
                    >CANCEL</Button>
                </GridItem>
				<GridItem xs={12} sm={2} md={3}>
					<div
						className={`${props.inputOnClick ? 'hoverable' : ''}`}
						onClick={() => {
							if (props.inputOnClick) {
								props.inputOnClick();
							}
						}}
					>
						<div className={`${props.inputOnClick ? 'avoid-clicks' : ''}`}>
							<CustomInput
                                label='Monthly $ Value'
                                value={localData.value}
                                onChange={(e) => {
                                    const obj = {
                                        value: e.target.value,
                                        flat_fee: e.target.value,
                                    };
                                    updateMultiFields(obj);
                                }}
                                validate={[required]}
                                type='number'
                                onClick={props.inputOnClick ? props.inputOnClick : () => {}}
                            />
						</div>
					</div>
				</GridItem>
				<GridItem xs={12} sm={2} md={3}>
                    <div
                        className={`${props.inputOnClick ? 'hoverable' : ''}`}
                        onClick={() => {
                            if (props.inputOnClick) {
                                props.inputOnClick();
                            }
                        }}
                    >
                        <div className={`${props.inputOnClick ? 'avoid-clicks' : ''}`}>
                                <CustomInput
                                    label='Commission Rate'
                                    value={localData.commissionRate}
                                    onChange={(e) => {
                                        update('commissionRate', e.target.value);
                                    }}
                                    validate={[required]}
                                    type='number'
                                    endAdornment={localData.commissionRate ? '%' : ''}
                                    onClick={props.inputOnClick ? props.inputOnClick : () => {}}
                                />
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={2} md={3}>
                    {localData.month1 && <div className='tiny-label'>Month Start</div>}
                    <div>
                        <CustomSelect
                            label={'Month Start'}
                            options={monthOptions ? monthOptions : []}
                            choose={(e, n) => {
                                update('month1', e);
                            }}
                            default={localData.month1}
                        />
                    </div>
                    {isRequired && !localData.month1 && (
                        <div className='red-text'>
                            <small>Required</small>
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={2} md={3}>
                    {localData.day1 && <div className='tiny-label'>Day Start</div>}
                    <div>
                        <CustomSelect
                            label={'Day Start'}
                            options={dayOptions}
                            choose={(e, n) => {
                                update('day1', e);
                            }}
                            default={localData.day1}
                        />
                    </div>
                    {isRequired && !localData.day1 && (
                        <div className='red-text'>
                            <small>Required</small>
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={2} md={3}></GridItem>
                <GridItem xs={12} sm={2} md={3}></GridItem>
                <GridItem xs={12} sm={2} md={3}>
                    {localData.month2 && <div className='tiny-label'>Month End</div>}
                    <div>
                        <CustomSelect
                            label={'Month End'}
                            options={monthOptions.filter(m => {
                                if (localData.month1) {
                                    const m_int = parseInt(m.split(' ')[1]);
                                    const month1_int = parseInt(localData.month1.split(' ')[1]);
                                    if (m_int < month1_int) {
                                        return false;
                                    }
                                }
                                return true;
                            })}
                            choose={(e, n) => {
                                update('month2', e);
                            }}
                            default={localData.month2}
                        />
                    </div>
                    {isRequired && !localData.month2 && (
                        <div className='red-text'>
                            <small>Required</small>
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={2} md={3}>
                    {localData.day2 && <div className='tiny-label'>Day End</div>}
                    <div>
                        <CustomSelect
                            label={'Day End'}
                            options={dayOptions.filter(d => {
                                if (localData.month1 === localData.month2 && localData.day1) {
                                    const d_int = parseInt(d.split(' ')[1]);
                                    const day1_int = parseInt(localData.day1.split(' ')[1]);
                                    if (d_int < day1_int) {
                                        return false;
                                    }
                                }
                                return true;
                            })}
                            choose={(e, n) => {
                                update('day2', e);
                            }}
                            default={localData.day2}
                        />
                    </div>
                    {isRequired && !localData.day2 && (
                        <div className='red-text'>
                            <small>Required</small>
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div className="red-text">{error_message}</div>
                </GridItem>
			</GridContainer>
		</>
	);
};

export default TermEstimatedMainForm;
