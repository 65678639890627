import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FadeLoader } from "react-spinners";
import { getErrors } from "./PropertiesLogic";
import propertyFields from "./PropertiesObj";
import { Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Button from "../../../components/CustomButtons/Button";
import { clone } from '../../../shared/utility';

const PropertiesFormReview = (props) => {
	const {
		dataObj,
		setDataObj,
		error,
		success,
		leasesForAdd,
		listingsForAdd,
		meetingsForAdd,
		offersForAdd,
		showingsForAdd,
		theContactData,
		sharesForAdd,
		contactsForAdd,
		companyForAdd,
		formDataObj,
		setAction,
		propertySuccessData,
		getProperty,
		skipSuccessMsg,
	} = props;
	const [errorData, setErrorData] = useState(null);

	useEffect(() => {
		if (propertySuccessData && propertySuccessData.id) {
			const newData = clone(dataObj);
			newData.id = propertySuccessData.id
			setDataObj(newData)
		}
	}, [propertySuccessData])

	useEffect(() => {
		const others = {
			leasesForAdd,
			listingsForAdd,
			meetingsForAdd,
			offersForAdd,
			showingsForAdd,
			contactsForAdd,
			companyForAdd
		};
		
		let errors = getErrors(dataObj, others, theContactData);
		if (props.existingAddress) {
			if (!errors) {
				errors = {
					street1: props.existingAddress,
				};
			} else {
				errors["street1"] = props.existingAddress;
			}
		}
		if (dataObj) {
			if (!errors) {
				if (dataObj.address_is_range === 'Yes') {
					if (!dataObj.street_range1 || !dataObj.street_range2) {
						errors = { 
							street1: 'Range Start and End are both required'
						}
					}
				}
			} else {
				if (dataObj.address_is_range === 'Yes') {
					if (!dataObj.street_range1 || !dataObj.street_range2) errors.street1 = 'Range Start and End are both required';
				}
			}
		}
		
		setErrorData(errors);
		if (!errors) {
			const newObj = { ...dataObj };
			if (newObj.illi_represented === "1") {
				newObj.illi_represented = 1;
			} else if (newObj.illi_represented === "0") {
				newObj.illi_represented = 0;
			} else {
				delete newObj.illi_represented;
			}
			newObj.goal = JSON.stringify(newObj.goal);
			newObj.spaces = JSON.stringify(newObj.spaces);

			// SALE rule 
			// Properties CAN'T be Prospective Listing and also No listing or Property Management.
			// (if property_is has Prospective Listing then it should only be Prospective Listing)
			if (newObj.property_is) {
				if (newObj.property_is.indexOf('Prospective Listing') !== -1) {
					newObj.property_is = 'Prospective Listing';
				}
				// If No Listing, it cannot be Sale
				if (newObj.property_is.indexOf('No Listing') !== -1) {
					const property_is_for_arr = newObj.property_is_for ? newObj.property_is_for.split(',') : [];
					newObj.property_is_for = property_is_for_arr.filter(pifa => {
						if (pifa === 'Sale') return false;
						return true;
					}).join(',');
				}
			}
			// If a property ONLY has the Sale checked, then the No Listing checkbox goes away.
			if (newObj.property_is_for === 'Sale') {
				if (newObj.property_is) {
					if (newObj.property_is.indexOf('No Listing') !== -1) {
						// remove No Listing 
						const pif_arr = newObj.property_is.split(',');
						newObj.property_is = pif_arr.filter(f => {
							if (f !== 'No Listing') return true
						}).join(',');
					}
				}
			}
			console.log('saving this proeprty object', newObj);
			if (props.action === "Add") {
				const otherObj = {
					leasesForAdd,
					listingsForAdd,
					meetingsForAdd,
					offersForAdd,
					showingsForAdd,
					sharesForAdd,
					contactsForAdd,
					companyForAdd,
					formDataObj,
				};
				props.createProperty(newObj, otherObj);
				if (props.closeDialog) props.closeDialog();
				// if (props.closeForm) props.closeForm();
			}
			if (props.action === "Edit") {
				
				props.updateProperty(newObj);
				if (skipSuccessMsg) props.setOpenedReview(false);
			}
		}
	}, []);

	return (
	<Dialog
        open={true}
        keepMounted  
        maxWidth="sm" 
        fullWidth={true}
        onClose={() => {
            props.setOpenedReview(false);
        }}
    >
        <div className="dialog-wrapper-small text-center">
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<div className="close-btn" onClick={() => { props.setOpenedReview(false); }}><Close /></div>
					<h2>Property</h2>
					{errorData && (
						<div>
							<h4 style={{ color: "red" }}>
								Please correct these errors before submitting.
							</h4>
							<hr />
							{Object.keys(errorData).map((field) => {
								if (!errorData[field]) return null;
								if (field === "Contact not found") {
									return (
										<div key={field} style={{ marginBottom: 20 }}>
											<span style={{ marginRight: 20 }}>
												<strong>Contact not found</strong>
											</span>
											<span style={{ color: "red" }}>{errorData[field]}</span>
										</div>
									);
								}
								return (
									<div key={field} style={{ marginBottom: 20 }}>
										<span style={{ marginRight: 20 }}>
											<strong>{propertyFields[field].label}</strong>
										</span>
										<span style={{ color: "red" }}>{errorData[field]}</span>
									</div>
								);
							})}
						</div>
					)}

					{(props.action === "Edit" && !errorData && !error && !props.success) &&  (
						<div>
							<h4>Saving... </h4>
							<div className="fade-loader-center">
								<FadeLoader />
							</div>
						</div>
					)}

					<div style={{ marginTop: 20 }}>
						{!errorData && (
							<div>
								<h4>{props.success}</h4>
							</div>
						)}
						{!props.success && (
							<div style={{ color: "red" }}>
								<h4>{props.error}</h4>
							</div>
						)}
						<div style={{marginTop:20}} className="text-center">
							<Button color="primary" onClick={() => {
								props.setOpenedReview(false);
							}}>OK</Button>
						</div>
					</div>
				</GridItem>
			</GridContainer>
		</div>
	</Dialog>
	);
};

export default PropertiesFormReview;
