import React from 'react';
import { useEffect, useCallback } from 'react';
import { get } from 'lodash';
import { clone } from '../../../shared/utility';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInputValidate';

import {
	checkOutsideBrokerOrReferralFirmFields,
	setReferralFirmFeeAmountChoice,
} from '../RSheetsLogic';

const LESSOR_REFERRAL_FIRM_FIELDS = [
	'referral_firm_company_name',
	'referral_firm_name',
	'referral_firm_lastname',
	'referral_firm_contact_dba_name',
	'referral_firm_address1',
	'referral_firm_city',
	'referral_firm_state',
	'referral_firm_zip',
	'referral_firm_contact',
	'referral_firm_fax',
	'referral_firm_main_phone',
	'referral_firm_mobile_phone',
	'referral_firm_work_phone',
	'referral_firm_email',
	'referral_firm_taxid',
	'referral_firm_w9',
	'referral_firm_w9_upload',
	'referral_firm_fee_amount_choice',
	'referral_firm_fee_amount',
	'referral_firm_fee_amount_percentage',
	'referral_firm_fee_amount_type',
];

const LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS = [
	'referral_firm_fax',
	'referral_firm_fee_amount_choice',
];

const Step5 = (props) => {
	const {
		setQueryFor,
		getContacts,
		setOpenedContactModal,
		renderElement,
		data,
		setData,
	} = props;

	useEffect(() => {
		setReferralFirmFeeAmountChoice(setData, data)('lessor');
	}, []);

	const areReferralFirmFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
		data,
		LESSOR_REFERRAL_FIRM_FIELDS,
		LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS
	);

	const LESSOR_REFERRAL_FIRM_FIELDS_FILTERED = LESSOR_REFERRAL_FIRM_FIELDS.filter(lff => {
        const lf = lff.split('referral_firm_')[1];
        console.log('the-lf', lf, data.referral_firm_phone_type);
        if (data.referral_firm_phone_type) {
            if (data.referral_firm_phone_type === 'Work') {
                if (lf === 'mobile_phone' || lf === 'main_phone') return false;
            } else if (data.referral_firm_phone_type === 'Mobile') {
                if (lf === 'work_phone' || lf === 'main_phone') return false;
            } else if (data.referral_firm_phone_type === 'Main') {
                if (lf === 'work_phone' || lf === 'mobile_phone') return false;
            }
        } else {
            if (lf === 'work_phone' || lf === 'mobile_phone') return false;
        }
        return true;
    });

	const renderReferralFirmFields = useCallback(() => {
		console.log('LESSOR_REFERRAL_FIRM_FIELDS_FILTERED', LESSOR_REFERRAL_FIRM_FIELDS_FILTERED)
		return LESSOR_REFERRAL_FIRM_FIELDS_FILTERED.map((lfield) => {
			const extraValidations =
				!LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS.includes(lfield) &&
				areReferralFirmFieldsRequired
					? ['required']
					: [];

			if (data.referral_firm_id) {
				// is a company
				if (
					data.referral_firm_id.toString().indexOf('c') !== -1 &&
					get(data, 'referral_firm_company_only', false)
				) {
					if (lfield === 'referral_firm_name') return null;
					if (lfield === 'referral_firm_lastname') return null;
				}
			}

			if (!lfield) {
				return <GridItem xs={12} sm={3} md={3}></GridItem>;
			}

			if (lfield === 'referral_firm_contact_dba_name') {
				return <GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
				<CustomInput
						label='Payee DBA'
						value={data.referral_firm_contact_dba_name}
						readonly
						readonly_show_gray
						endAdornment={data.referral_firm_contact_dba_active === 0 && 
								<span style={{color: 'red', fontWeight: 'bold', fontSize: '.7em'}}>(removed)</span>
						}
				/>
				</GridItem>
		}

			if (data.referral_firm_w9 !== 'Yes') {
				if (lfield === 'referral_firm_w9_upload') return null;
			}
			if (
				data.referral_firm_fee_amount_choice === 'Percentage' &&
				lfield === 'referral_firm_fee_amount'
			)
				return null;
			if (
				data.referral_firm_fee_amount_choice === 'Value' &&
				lfield === 'referral_firm_fee_amount_percentage'
			)
				return null;
			return (
				<GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
					{renderElement(lfield, null, null, null, null, extraValidations)}
				</GridItem>
			);
		});
	}, [data]);

	return (
		<GridContainer>
			{/* Lessor */}
			<GridItem xs={12} sm={12} md={12}>
				<Button
					color='primary'
					onClick={() => {
						setQueryFor('lessors');
						getContacts({
							names_only: 'All',
						});
						setOpenedContactModal(true);
					}}
				>
					<i className="fa fa-plus"></i> ADD LESSOR
				</Button>
			</GridItem>
			{data.lessors.length === 0 && <div className="red-text" style={{margin:20}}>Lessor Required</div>}
			{renderElement('lessors')}

			<GridItem xs={12} sm={12} md={12}>
				<hr />
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				{renderElement('lessor_contacts', undefined, undefined, undefined, undefined, undefined, undefined, true)}
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				<hr />
				<h4>
					<strong>LESSOR REFERRAL</strong>
				</h4>
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				{!data.referral_firm_id && <Button
					color='primary'
					onClick={() => {
						setQueryFor('referral_firm');
						props.getContacts({
							names_only: 'All',
						});
						setOpenedContactModal(true);
					}}
				>
					SELECT REFERRAL
				</Button>}
				{data.referral_firm_id && (!data?.lessor_referrals || !data?.lessor_referrals.length) && <Button
					color='success'
					onClick={() => {
						const newData = clone(data);
						['address1','city','company_name','contact','email','fax','fee_amount',
						'fee_amount_choice','fee_amount_percentage','fee_amount_type','lastname',
						'main_phone','work_phone','mobile_phone',
						'name','state','taxid','w9','w9_upload','zip','id',
						'contact_dba_name',
						'contact_dba_id',
						'contact_dba_type',
						'contact_dba_active',
						'company_dba_name',
						'company_dba_id',
						'company_dba_type',
						'company_dba_active',
					].forEach(fieldy => {
							const field = `referral_firm_${fieldy}`;
							newData[field] = '';
						})
						setData(newData);
					}}
				>
					REMOVE REFERRAL
				</Button>}
			</GridItem>

			{data.referral_firm_id ? renderReferralFirmFields() : null}
		</GridContainer>
	);
};

export default Step5;
