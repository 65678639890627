import React, { useEffect, useState, useCallback } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/Person';
import Star from '@material-ui/icons/Star';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import ContactsNewProperty from "containers/CRM/Contacts/ContactsNewProperty";
import { connect } from 'react-redux';
import { clone } from 'shared/utility';
import * as actions from 'store/actions';
import _ from 'lodash';
import { getUser } from '../../shared/authValidation';
import { Tab, Tabs } from '@material-ui/core';
import CustomSelect from '../CustomSelect/CustomSelect';
import crmObj from 'containers/CRM/crmObj';
import Notification from '../Notification/Notification';

const CustomDialogWithProperty = (props) => {
    const [openedChooseType, setOpenedChooseType] = useState(null);
    const [openedNewProperty, setOpenedNewProperty] = useState(false);
    const [currentTab, setCurrentTab] = useState('illi');
    const [userType, setUserType] = useState('E');
    const [theType, setTheType] = useState('');
    const [choice, setChoice] = useState('');
    const [options, setOptions] = useState([]);
    const [subType, setSubtype] = useState('');
    const [acronymType, setAcronymtype] = useState('');
    const [notification, setNotification] = useState(false);
    const [checkProperty, setCheckProperty] = useState(null);

    const { indx, desc, open, close, title, options: opts, label, current,
        illi_properties, own_properties, own_shared_properties, is_lease, has_spaces,
        is_sale,
        filtered_spaces,
        filter_message,
        rs_type
    } = props;

    useEffect(() => {
        const userPerms = getUser();
        if (userPerms) {
            setUserType(userPerms.type);
            if (userPerms.type === 'A') {
                setCurrentTab('Agent');
            }
        }
        if (props.prefill_property_name) {
            setChoice(props.prefill_property_name);
        }
    }, []);

    useEffect(() => {
        console.log('cjeck -', props.checking_property, checkProperty)
        if (props.checking_property && checkProperty) {
            console.log('checking property', props.checking_property);
            if (props.checking_property) {
                if (props.checking_property.length > 0) {
                    setNotification('Property chosen is a duplicate');
                } else {
                    props.choose(checkProperty);
                }
            }
            setCheckProperty(null);
        }
    }, [props.checking_property, checkProperty]);

    const searchAPI = (the_choice) => {
        if (props.propertyOwner) {
            const params = {
                names_only: 'all',
                search_term: the_choice,
                property_owner: props.propertyOwner,
                check_for_duplicates: props.check_for_duplicates
            };
            if (props.full_property_access_only) {
                params.full_property_access_only = true
            }
            props.getProperties(params)
        } else {
            const params = {
                names_only: 'all',
                search_term: the_choice,
                check_for_duplicates: props.check_for_duplicates
            };
            if (props.full_property_access_only) {
                params.full_property_access_only = true
            }
            props.getProperties(params);
        }
    }

    const searchIt = useCallback(_.debounce(searchAPI, 600), []);

    useEffect(() => {
        if (choice) {
            if (choice.length > 1) {
                searchIt(choice);
            }
        }
    }, [choice]);

    useEffect(() => {
        if (illi_properties && own_properties && own_shared_properties) {
            renderOptions();
        }
    }, [illi_properties, own_properties, own_shared_properties]);

    useEffect(() => {
        renderOptions();
    }, [currentTab]);

    let hide_illi_tab = false;
	if (props.loggedInUser) {
		// shown if in CRM association editing mode (e.g. company properties, contact properties)
		if (props.loggedInUser.type === 'A') {
			hide_illi_tab = true;
		}
	}

	const filterProperties = (properties, {
		property_is_for=[],
		has_spaces = false,
		filtered_spaces = [],
		exclude_use_types = ["Residential", "Multi-Family"],
        property_is_for_only = false
	  }) => {
		return properties.filter(property => {
			let includeProperty = true;

            if (rs_type === 'Lease' || rs_type === 'Sub-Lease' || rs_type === 'Tenant Rep') {
                // Any property for lease as long as there is at least one space that isn't multi-family or residential 
                // since those can't be used here.
                if (!property.property_is_for) { 
                    includeProperty = false;
                } else {
                    if (property.property_is_for.indexOf('Lease') === -1) {
                        includeProperty = false;
                    } else {
                        let passing_space = [];
                        const spaces = property.spaces || [];
                        if (spaces.length === 0) {
                            includeProperty = false;
                        } else {
                            spaces.forEach(sp => {
                                if (sp.use_type !== 'Multi-Family' && sp.use_type !== 'Residential') {
                                    passing_space.push(sp.use_type);
                                }
                            })
                            if (passing_space.length === 0) includeProperty = false;
                        }
                    }
                }
                
            } else if (rs_type === 'Residential') {
                // Lease only, Multi-Family & Residential Spaces only.
                if (!property.property_is_for) { 
                    includeProperty = false;
                } else {
                    // Lease only
                    if (property.property_is_for.indexOf('Lease') === -1) {
                        includeProperty = false;
                    } else {
                        let passing_space = [];
                        const spaces = property.spaces || [];
                        if (spaces.length === 0) {
                            includeProperty = false;
                        } else {
                            // Multi-Family & Residential Spaces only.
                            spaces.forEach(sp => {
                                if (sp.use_type === 'Multi-Family' || sp.use_type === 'Residential') {
                                    passing_space.push(sp.use_type);
                                }
                            })
                            if (passing_space.length === 0) includeProperty = false;
                        }
                    }
                }
            } else if (rs_type === 'No Listing') { 
                if (property.property_is !== 'No Listing') includeProperty = false;
            } else {
                if (property_is_for.length > 0) {
                    const propertyIsFor = property.property_is_for ? property.property_is_for.split(',') : [];
      
                    if (property_is_for_only && property_is_for.length > 0) {
                      includeProperty = propertyIsFor.length > 0 && propertyIsFor.every(r => property_is_for.includes(r));
                    } else {
                      includeProperty = propertyIsFor.some(r => property_is_for.includes(r));
                    }
      
                    if (includeProperty && has_spaces) {
                      const spaces = property.spaces || [];
                      const filteredSpaces = filtered_spaces.length > 0 ? 
                          spaces.filter(space => filtered_spaces.includes(space.use_type)) : spaces;
                      const includedSpaces = exclude_use_types.length > 0 ? 
                          filteredSpaces.filter(space => !exclude_use_types.includes(space.use_type)) : filteredSpaces;
                      includeProperty = includedSpaces.length > 0
                    }
                }
            }

			return includeProperty;
		  });
	  };

	const renderOptions = () => {
        let opts;
        if (currentTab === 'illi') {
            opts = filterProperties(illi_properties, { 
                has_spaces, filtered_spaces,
                property_is_for: props.property_is_for ? props.property_is_for : [], // filter for e.g. Lease, Sale 
                exclude_use_types: props.exclude_use_types,
                property_is_for_only: props.property_is_for_only
            });
        } else {
            const own_and_shared_properties = own_properties.concat(own_shared_properties);
            opts = filterProperties(own_and_shared_properties, { 
                has_spaces, filtered_spaces,
                property_is_for: props.property_is_for ? props.property_is_for : [], // filter for e.g. Lease, Sale only
                exclude_use_types: props.exclude_use_types,
                property_is_for_only: props.property_is_for_only
            });
            if (props.full_property_access_only) {
                opts = opts.filter(o => {
                    if (o.hasOwnProperty('access')) {
                        if (o.access === 'F') {
                            return true;
                        }
                    } else {
                        return true;
                    }
                })
            }
        }
        setOptions(opts);
    }

    const ct = currentTab === 'illi' ? 0 : 1;

    const oo = options.filter((o, i) => {
        if (currentTab === 'Agent' && o.user_id === 1) {
            return false;
        }
        if (currentTab === 'illi' && o.user_id !== 1) {
            return false;
        }
        return true;
    });

    return (
        <div>
            <Dialog open={props.open} keepMounted onClose={props.close} maxWidth='sm' fullWidth={true}>
                <DialogTitle>
                    <div className="close-btn" onClick={() => { props.close() }}><Close /></div>
                    <div style={{ textAlign: 'center' }}>{props.title}</div>
                    {!props.no_new_property && <Button className="outlined-btn"
                        size="sm"
                        onClick={() => {
                            setOpenedNewProperty(true);
                        }}
                    ><i className="fa fa-plus"></i> CREATE NEW {props.propertyType} PROPERTY</Button>}
                </DialogTitle>

                {<div style={{ margin: '0 20px' }}>
                    {userType !== 'E' && <Tabs
                        TabIndicatorProps={{ style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' } }}
                        style={{ marginBottom: 10 }}
                        value={currentTab === 'illi' ? 0 : 1}>
                        {!props.full_property_access_only && <Tab
                            onClick={() => {
                                setCurrentTab('illi');
                            }}
                            style={{ 
                                color: ct === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
                                display: hide_illi_tab ? 'none' : 'block'
                            }}
                            tabIndex={0}
                            label='illi Properties'
                            icon={<i className="fas fa-building"></i>}
                        />}
                        {<Tab
                            onClick={() => {
                                setCurrentTab('Agent');
                            }}
                            style={{ color: ct === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                            tabIndex={1}
                            label='Your Properties'
                            icon={<i className="fas fa-user"></i>}
                        />}
                    </Tabs>}
                </div>}

                <div style={{ textAlign: 'center', padding: 20 }}>
                    {filter_message && <div className="red-text">
                            <div>FILTERS:</div>
                            {filter_message}
                    </div>}
                    {/* {(is_lease || has_spaces) && <div className="red-text">
                        FILTERS:<br />
                        {is_lease && <span className="red-text">Lease property only</span>}
                        {(is_lease && has_spaces) && <span>,</span>}
                        {has_spaces && <span className="red-text">Property with spaces only</span>}
                        {notification && <div className="red-text mt-20">,{notification}</div>}
                    </div>} */}
                    <CustomInput
                        label={'Search by Street Name , Project Name or illi Number'}
                        value={choice}
                        onChange={(e) => {
                            setNotification('');
                            setChoice(e.target.value);
                        }}
                        autoFocus={true}
                    />

                    {props.loading && <div>
                        <div style={{ margin: '0 auto', display: 'inline-block' }}><ClipLoader /></div>
                    </div>}
                    {!props.loading && <div>
                        {options && <div style={{ height: !choice ? 100 : 300, overflowY: 'scroll', padding: 10, backgroundColor: '#f1f1f1' }}>
                            {!choice && <div style={{ marginTop: 20 }}><em>Enter letters or numbers from the property's street or project name<br />you are searching for in the line above</em></div>}
                            {(choice && options) && <div>
                                {options.length === 0 && <div><em>0 Results</em></div>}
                            </div>}
                            {(!props.loading && choice) && <div>
                                {options.length > 0 && <div><em>{options.length.toLocaleString()} Result(s)</em></div>}
                            </div>}
                            {(options.length !== 0 && choice) && <List component="nav">
                                {options.map((o, i) => {

                                    const range1 = o.street_range1 && (o.street_range2 && o.address_is_range === 'Yes') ? `${o.street_range1}-${o.street_range2}` : '';
                                    const range2 = o.street2_range1 && (o.street2_range2 && o.address2_is_range === 'Yes') ? `${o.street2_range1}-${o.street2_range2}` : '';
                                    const street1 = `${range1} ${o.street1 ? o.street1 : ''}`;
                                    const street2 = `${range2} ${o.street2 ? o.street2 : ''}`;
                                    const added = (street1 && o.city) ? `${street1} ${street2}, ${o.city}` : '';
                                    const desc = props.desc ?
                                        <div><strong>{o[props.desc]}</strong><span><br />{added}</span></div>
                                        :
                                        <div><strong>{o.name}</strong><span><br />{added}</span></div>;
                                    return (
                                        <ListItem
                                            button
                                            key={`propertyoptions-${i}`}
                                            onClick={() => {
                                                let missing = [];
                                                if (props.required_fields) {
                                                    if (props.required_fields.length > 0) {
                                                        props.required_fields.forEach(pr => {
                                                            if (!o[pr]) {
                                                                missing.push(pr.split('_').join(' ').toUpperCase());
                                                            }
                                                        })
                                                    }
                                                }
                                                if (missing.length > 0) {
                                                    return setNotification(`Missing Required fields ${missing}`);
                                                }
                                                if (props.askForType) {
                                                    setOpenedChooseType(o);
                                                } else {
                                                    console.log('cjeck', props.check_for_duplicates);
                                                    if (props.check_for_duplicates) {
                                                        if (props.check_for_duplicates === 'commission_splits') {
                                                            setCheckProperty(o);
                                                            props.getCommissionSplitsPropertyExistence(o.id);
                                                        }
                                                    } else {
                                                        props.choose(o);
                                                    }
                                                }
                                            }}
                                        >
                                            {props.icon === 'person' && <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>}
                                            {(props.icon !== 'person' && props.icon) && <ListItemIcon>
                                                {props.icon}
                                            </ListItemIcon>}
                                            <ListItemText inset primary={desc} />
                                            <ListItemSecondaryAction>
                                                {current === desc && <Star />}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>}
                        </div>}
                    </div>}

                    {openedNewProperty && <ContactsNewProperty
                        open={openedNewProperty}
                        close={() => {
                            setOpenedNewProperty(false);
                            if (props.askForType) {
                                return;
                            }
                            if (props.do_not_close_on_choose) return;
                            props.close();
                        }}
                        propertyType={props.propertyType}
                        create_and_choose={(chosen) => {
                            if (props.askForType) {
                                return setOpenedChooseType(chosen);
                            }
                            console.log('chosen new property', chosen);
                            props.create_and_choose(chosen);
                        }}
                        theContactData={props.theContactData}
                        is_lease={true}
                        property_is_for_override={props.property_is_for_override}
                        success_data={props.success_data}
                    />}

                    <Button
                        color='white'
                        style={{ marginRight: 10, marginTop: 20 }}
                        onClick={() => {
                            props.close();
                        }}
                    >
                        CLOSE
                    </Button>
                </div>

                {openedChooseType && <Dialog
                    open={true} keepMounted onClose={() => { setOpenedChooseType(null); }} 
                    maxWidth='sm' fullWidth={true}
                >
                    <DialogTitle>
                        <div className="close-btn" onClick={() => { setOpenedChooseType(null); }}><Close /></div>
                        <div style={{ textAlign: 'center' }}>
                            Choose Contact Type for this Property<br />
                            {openedChooseType && <div style={{opacity:0.5}}>{openedChooseType.name}</div>}
                        </div>
                    </DialogTitle>
                    <div className="dialog-body" style={{ margin: '0 20px', minHeight:400 }}>
                        <CustomSelect 
                            default={theType}
                            choose={(typeChosen) => {
                                setTheType(typeChosen);
                                if (typeChosen === 'Other') {
                                    setSubtype('');
                                } else {
                                    setSubtype(crmObj.contactOrCompanyPropertySubTypes[typeChosen].subtype);
                                }
                                setAcronymtype(crmObj.contactOrCompanyPropertySubTypes[typeChosen].type);
                            }}
                            options={crmObj.contactOrCompanyPropertyTypes}
                            label="Choose one"
                        />
                        {theType === 'Other' && <CustomInput
                            label="Enter Other type"
                            value={subType}
                            onChange={(e) => {
                                setSubtype(e.target.value);
                            }}
                        />}
                        {theType && <Button color="white"
                            onClick={() => {
                                if (theType === 'Other') {
                                    if (!subType) {
                                        return setNotification('Please enter an "Other" type.');
                                    }
                                }
                                const typeObj = {
                                    theType, 
                                    acronymType, 
                                    subType
                                };
                                console.log('typeObj', typeObj);
                                props.choose(openedChooseType, typeObj)
                                props.close();
                            }}
                        >SUBMIT</Button>}
                    </div>
                </Dialog>}

                {notification && <Notification 
                    message={notification}
                    open={notification ? true : false}
                    close={() => { 
                        props.setCommissionSplitsPropertyExistence();
                        setNotification('') 
                    }}
                />}
            </Dialog>
        </div>
    );
};

CustomDialogWithProperty.propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string,
    close: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    choose: PropTypes.func.isRequired,
    indx: PropTypes.string,
    desc: PropTypes.string,
    current: PropTypes.string,
    icon: PropTypes.object
};

const mapStateToProps = state => {
    const p = state.crm_properties;
    return {
        loading: p.loading,
        illi_properties: p.illi_properties,
        own_properties: p.own_properties,
        own_shared_properties: p.own_shared_properties,
        success_data: p.success_data,
        checking_property: state.commission_splits.checking_property
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getProperties: (params) => {
            dispatch(actions.getProperties(params));
        },
        getCommissionSplitsPropertyExistence: (data) => {
            dispatch(actions.getCommissionSplitsPropertyExistence(data));
        },
        setCommissionSplitsPropertyExistence: () => {
            dispatch(actions.getCommissionSplitsPropertyExistence());
        },
        setCommissionSplitsPropertyExistence: () => {
            dispatch(actions.setCommissionSplitsPropertyExistence());
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogWithProperty);
