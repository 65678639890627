import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Button2 from '@material-ui/core/Button';
import NavPills from "components/NavPills/NavPills.jsx";

import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";

import UserSubPermissions from "./UserSubPermissions";

const UserPermissions = props => {
  return (
    <div>
      <div>
        <div style={{textAlign:'center',position:'relative'}}>
          <h4><strong>QUANTITY</strong></h4>
          <div style={{marginTop:'20px',position:'absolute',top:'-74px',right:'0'}}>
            <Button color="success" onClick={() => props.save() } className="outlined-btn">SAVE USER PERMISSIONS</Button>
          </div>
        </div>
        <div className="outlined-blue">
          {!props.currentUser && <span><strong>SELECT A USER</strong></span>}
          {props.currentUser && <div>
            <div style={{marginBottom:'20px'}}>
              <strong>NOW DISPLAYING PERMISSIONS FOR : <span className="yellow-text">{props.currentUser ? props.currentUser.name : ''}</span>
              </strong>
            </div>
            <NavPills
              color="rose"
              tabs={[
                {
                  tabButton: "ADMIN",
                  tabContent: <UserSubPermissions
                                main="Admin"
                                choosePerm={props.choosePerm}
                                permObj={props.permObj}
                              />
                },
                {
                  tabButton: "CRM",
                  tabContent: <UserSubPermissions
                                main="CRM"
                                choosePerm={props.choosePerm}
                                permObj={props.permObj}
                              />
                },
                {
                  tabButton: "Documents",
                  tabContent: <UserSubPermissions
                                main="Documents"
                                choosePerm={props.choosePerm}
                                permObj={props.permObj}
                              />
                },
                {
                  tabButton: "Onboarding",
                  tabContent: <UserSubPermissions
                                main="Onboarding"
                                choosePerm={props.choosePerm}
                                permObj={props.permObj}
                              />
                }
              ]}
            />
          </div>}
        </div>
      </div>
    </div>
  );
}

export default UserPermissions;
