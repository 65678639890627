import { put } from 'redux-saga/effects';
import axios from '../axios/axios-user';
import * as actions from '../actions/user';

export function* getWorkspaceUsersSaga(action) {
    yield put(actions.getWorkspaceUsersStart());
    try {
      
      const response = yield axios.get('/all');
      
      yield put(actions.getWorkspaceUsersSuccess(response.data));
    } catch (err) {
      yield put(actions.getWorkspaceUsersFail(err));
    }
}

export function* syncUsersSaga(action) {
    yield put(actions.syncUsersStart());
    try {
        
        const response = yield axios.post('/bulksync', { users: action.userList });
        
        yield put(actions.syncUsersSuccess(response.data));
    } catch (err) {
        yield put(actions.syncUsersFail(err));
    }
}

export function* createWorkspaceUserSaga(action) {
  // POST api/users/new_workspace_user
  yield put(actions.createWorkspaceUserStart());
  try {
    
    const response = yield axios.post('/new_workspace_user', action.userData);
    
    yield put(actions.createWorkspaceUserSuccess(response.data));
  } catch (e) {
    if (e.response) {
      if (e.response.data) {
        return yield put(actions.createWorkspaceUserFail(e.response.data));
      }
    }
    yield put(actions.createWorkspaceUserFail(e));
  }
}

export function* createUserFromWorkspaceSaga(action) {
  yield put(actions.createUserFromWorkspaceStart());
  try {
      const response = yield axios.post('/user_from_workspace', action.data);
      yield put(actions.createUserFromWorkspaceSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createUserFromWorkspaceFail(e.response.data));
          }
        }
      yield put(actions.createUserFromWorkspaceFail(e));
  }
}

export function* deleteWorkspaceUserSaga(action) {
  // DELETE api/users/delete_workspace_user
  yield put(actions.deleteWorkspaceUserStart());
  try {
    
    const response = yield axios.delete('/delete_workspace_user?id='+action.id);
    
    yield put(actions.deleteWorkspaceUserSuccess(response.data));
  } catch (err) {
      yield put(actions.deleteWorkspaceUserFail(err));
  }
}

export function* updateWorkspaceUserSaga(action) {
  // PUT api/users/update_workspace_user
  yield put(actions.updateWorkspaceUserStart());
  try {
    
    const response = yield axios.put('/update_workspace_user', action.userDetails);
    
    yield put(actions.updateWorkspaceUserSuccess(response.data));
  } catch (err) {
      yield put(actions.updateWorkspaceUserFail(err));
  }
}

export function* checkUsernameSaga(action) {
  
  yield put(actions.checkUsernameStart());
  yield put(actions.loginUserStart());
  try {
    
    const response = yield axios.post('/check_user_exists', {
      username: action.username
    });
    
    yield put(actions.checkUsernameSuccess(response.data));
    yield put(actions.loginUserChecked());
  } catch (err) {
    yield put(actions.checkUsernameFail(err));
    yield put(actions.loginUserFail(err));
  }
}

export function* getWorkspaceGroupsSaga(action) {
  yield put(actions.getWorkspaceGroupsStart());
  try {
    const response = yield axios.get('/workspace_groups');
    
    yield put(actions.getWorkspaceGroupsSuccess(response.data));
  } catch (err) {
    yield put(actions.getWorkspaceGroupsFail(err));
  }
}

export function* createWorkspaceGroupSaga(action) {
  yield put(actions.createWorkspaceGroupStart());
  try {
    const response = yield axios.post('/workspace_group', {
      groupData: action.groupData
    });
    
    yield put(actions.createWorkspaceGroupSuccess(response.data));
  } catch (err) {
    yield put(actions.createWorkspaceGroupFail(err));
  }
}

export function* deleteWorkspaceGroupSaga(action) {
  yield put(actions.deleteWorkspaceGroupStart());
  try {
    const response = yield axios.delete('/workspace_group?id='+action.groupKey);
    
    yield put(actions.deleteWorkspaceGroupSuccess(action.groupKey));
  } catch (err) {
    yield put(actions.deleteWorkspaceGroupFail(err));
  }
}

export function* updateWorkspaceGroupSaga(action) {
  yield put(actions.updateWorkspaceGroupStart());
  try {
    const response = yield axios.put('/workspace_group', action.groupData);
    
    yield put(actions.updateWorkspaceGroupSuccess(response.data));
  } catch (err) {
    yield put(actions.updateWorkspaceGroupFail(err));
  }
}

export function* getWorkspaceGroupMembersSaga(action) {
  yield put(actions.getWorkspaceGroupMembersStart());
  try {
    const response = yield axios.get(`/workspace_group_members?groupKey=${action.groupKey}`);
    
    if (response.data) {
      if (response.data.members) {
        yield put(actions.getWorkspaceGroupMembersSuccess(response.data.members));
        return;
      }
    }
    yield put(actions.getWorkspaceGroupMembersFail('Error getting members.'));
  } catch (err) {
    yield put(actions.getWorkspaceGroupMembersFail(err));
  }
}

export function* createWorkspaceGroupMemberSaga(action) {
  yield put(actions.createWorkspaceGroupMemberStart());
  try {
    const response = yield axios.post('/workspace_group_member', {
      groupData: action.groupData,
      memberData: action.memberData
    });
    
    yield put(actions.createWorkspaceGroupMemberSuccess(response.data, action.groupData ? action.groupData.id : ''));
  } catch (err) {
    yield put(actions.createWorkspaceGroupMemberFail(err));
  }
}

export function* deleteWorkspaceGroupMemberSaga(action) {
  yield put(actions.deleteWorkspaceGroupMemberStart());
  try {
    const response = yield axios.delete(`/workspace_group_member?group_id=${action.groupKey}&member_id=${action.memberKey}`);
    
    yield put(actions.deleteWorkspaceGroupMemberSuccess(action.memberEmail, action.groupKey));
  } catch (err) {
    yield put(actions.deleteWorkspaceGroupMemberFail(err));
  }
}