import { sectionNames, sectionRequirements } from "./RSheetsSaleModel";
import { saleFields, subFields } from "./RSheetsSaleModel";
import { commatize, get2DecimalRound, currencyToNumber, formatDollar, isValidDate2 } from "shared/utility";
import {
    calculateAbatedRanges as calculateAbatedRanges3,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    calculateEstimatedTotal,
    calculateLeaseTermTotal,
    calculateTermsTotal,
    isEstimatedLease,
    shouldGetPaymentAmount,
    getPaymentAmount,
    calculateAbatedRanges_RS as calculateAbatedRanges,
    getDateDifference,
    makeAllRanges,
    makeTotalCalcs,
    calculateLeaseLogic_RS as calculateLeaseLogic,
    getAbatedTotal,
    calculateStartingRent,
    calculateAbatedValues,
    checkOutsideBrokerOrReferralFirmFields,
    checkInternalReview,
} from "../RSheetsLogic";
import { concat, flatMap, get, isEmpty, sum } from "lodash";
import {
    email,
    max10,
    max100,
    max15,
    max20,
    max250,
    max4,
    max50,
    max500,
    min1,
    min10,
    min4,
    min6,
    no_letters,
    no_numbers,
    no_special_chars,
    required,
} from "shared/validation";

import { LESSEE_OUTSIDE_BROKER_FIELDS, LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS, LESSEE_REFERRAL_FIRM_FIELDS, LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS } from "./Step4";
import { LESSOR_OUTSIDE_BROKER_FIELDS, LESSOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS, LESSOR_REFERRAL_FIRM_FIELDS, LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS } from "./Step5";

const findLabel = (field) => {
    let label = '';
    const fields = Object.keys(saleFields);
    for (let i = 0; i < fields.length; i++) {
        const sfield = fields[i];
        if (sfield === field) {
            if (saleFields[field]) {
                if (saleFields[field].label) {
                    label = saleFields[field].label;
                    break;
                } 
            }
        }
    }
    if (!label) {
        return field.toUpperCase();
    }
    return label;
}

const validate = {
    min1,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max50,
    max100,
    max250,
    max500,
};

export const statusDescriptions = {
    1: "Draft",
    2: "Submitted to level 1",
    3: "Submitted to level 2",
    4: "Submitted to level 3",
    5: "Level 1 Denied to Submitter",
    6: "Level 2 Denied to Level 1",
    7: "Level 3 Denied to Level 2",
    8: "Final Approver denied to Level 3",
    9: "Final Approver approved",
    10: "Submitted to final approver for approval",
};

export const doAction = (tab, getRsheets, status) => {
    if (tab === "user_drafts") {
        getRsheets({
            type: 4,
            status: [1, 5],
        });
    } else if (tab === "my_drafts") {
        getRsheets({
            type: 4,
            own: "true",
            status: [1, 5],
        });
    } else if (tab === "pending_my_drafts") {
        getRsheets({
            type: 4,
            status,
        });
    } else if (tab === "pending_further_approval") {
        getRsheets({
            type: 4,
            status,
        });
    } else if (tab === "my_completed") {
        getRsheets({
            type: 4,
            status: 9,
            own: true,
        });
    } else if (tab === "all_completed") {
        getRsheets({
            type: 4,
            status: 9,
        });
    }
};

export const generateSections = (data, internalReviewers) => {
    // checking if data is complete

    let sections = [];
    Object.values(sectionNames).forEach((v, index) => {
        if (data) {
            let complete = true;
            let errors = [];
            
            if (v === 'Property Information') {
                // Required to have a value
                ['date','project_name','la_city_verification','illi_number',
                    'project_address','lead_source','property_type','deal_generated_by',
                    'can_deal_be_submitted_to_public_relations',
                    'illi_represented_lessor', // TODO: if yes -> are associates required ?
                    'illi_represented_lessee'].forEach(field => {
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });
                // values that are array of objects, must have at least one
                ['listings_procured_by'].forEach(field => {
                    if (data[field]) {
                        if (data[field].length === 0) {
                            complete = false;
                            const fieldLabel = findLabel(field);
                            errors.push(`${fieldLabel} is required`);
                        }
                    }
                })
            }

            if (v === 'Marketing Information') {
                // Required to have a value
                ['window_signs_returned','sign_removal_requested','loopnet_marketing_updated'].forEach(field => {
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });
                if (data.sign_removal_requested === 'Yes') {
                    ['order_type','maintenance_description',
                    'sign_size','location','sign_request_reason'].forEach(field => {
                        if (!data[field]) {
                            complete = false;
                            const fieldLabel = findLabel(field);
                            errors.push(`${fieldLabel} is required`);
                        }
                    });
                }
            }

            if (v === 'Escrow Information') {
                ['address','city','company','email',
                'officer_name','phone','state','zip'].forEach(efield => {
                    const field = `escrow_${efield}`;
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });
            }

            if (v === 'Sale Information') {
                ['purchase_price','commission_rate',
                'escrow_opening_date','escrow_closing_date'].forEach(sfield => {
                    const field = `sale_${sfield}`;
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                    if (data[field]) {
                        if (field === 'sale_escrow_closing_date' || 
                            field === 'sale_escrow_opening_date') {
                                if (!isValidDate2(data[field])) {
                                    complete = false;
                                    const fieldLabel = findLabel(field);
                                    errors.push(`${fieldLabel} is invalid`);
                                }
                        }
                    }
                })
            }

            if (v === "Buyer") {
                if (data.lessees) {
					if (data.lessees.length === 0) {
						complete = false;
                        errors.push('Please enter a Buyer');
					} else {
                        let companies_count = 0;
						data.lessees.forEach((lessee, index) => {
                            if (typeof lessee.id === 'string') {
                                if (lessee.id[0] === 'c') {
                                    companies_count++;
                                }
                            }
							Object.keys(lessee).forEach((key) => {
								let validations = get(
									subFields.lessee[key],
									'validations',
									[]
								);

								// If key is guarantor_name and guarantor's value is N/A, remove validations
								if (key === 'guarantor_name') {
									if (get(data, `lessees[${index}].guarantor`, '') === 'N/A') validations = [];
								}
                                // if lessee has company no need for name and last_name
                                if (key === 'name' || key === 'last_name') {
                                    if (get(data, `lessee[${index}].company_name`, '')) validations = [];
                                }

								validations.forEach((v) => {
									if (!!validate[v](lessee[key])) {
										complete = false;
										if (errors.indexOf('Please check for Buyer validation errors') === -1) {
                                            console.log('BUYER ERROR 1', key);
											errors.push('Please check for Buyer validation errors');
										}
									}
								});
							});
						});

                        const areOutsideBrokerFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
                            data,
                            LESSEE_OUTSIDE_BROKER_FIELDS,
                            LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS
                        );
                        const areReferralFirmFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
                            data,
                            LESSEE_REFERRAL_FIRM_FIELDS,
                            LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS
                        );
                        if (areOutsideBrokerFieldsRequired) {
                            LESSEE_OUTSIDE_BROKER_FIELDS.forEach(key => {
                                if (!LESSEE_OUTSIDE_BROKE_NON_REQUIRED_FIELDS.includes(key)) {
                                    if (get(data, 'outside_broker_id', '').toString().indexOf("c") !== -1) {
                                        if (key === "outside_broker_lastname") return;
                                        if (key === "outside_broker_name") return;
                                    } else {
                                        if (key === 'outside_broker_company_name') return;
                                    }
                                    if (typeof get(data, key, '') === 'string' && get(data, key, '').trim() === '') {
                                        if (key === 'outside_broker_w9_upload') {
                                            if (get(data, 'outside_broker_w9', '') === 'Yes') {
                                                if (errors.indexOf('Please check Buyer entries requirements') === -1) {
                                                    console.log('BUYER ERROR 2');
                                                    errors.push('Please check Buyer entries requirements');
                                                }
                                                complete = false;
                                            }
                                        } else {
                                            console.log('keyy1', key);
                                            if (errors.indexOf('Please check Buyer entries requirements') === -1) {
                                                console.log('BUYER ERROR 3');
                                                errors.push('Please check Buyer entries requirements');
                                            }
                                            complete = false;
                                        }
                                    }
                                }
                            })
                        }
                        if (areReferralFirmFieldsRequired) {
                            LESSEE_REFERRAL_FIRM_FIELDS.forEach(key => {
                                if (!LESSEE_REFERRAL_FIRM_NON_REQUIRED_FIELDS.includes(key)) {
                                    if (get(data, 'lessee_referral_firm_id', '').toString().indexOf("c") !== -1) {
                                        if (key === 'lessee_referral_firm_name') return;
                                        if (key === 'lessee_referral_firm_lastname') return;
                                    };

                                    if (typeof get(data, key, '') === 'string' && get(data, key, '').trim() === '') {
                                        if (key === 'lessee_referral_firm_w9_upload') {
                                            if (get(data, 'lessee_referral_firm_w9', '') === 'Yes') {
                                                if (errors.indexOf('Please check Buyer entries') === -1) {
                                                    console.log('BUYER ERROR 4');
                                                    errors.push('Please check Buyer entries');
                                                }
                                                complete = false;
                                            }
                                        } else if (key === 'lessee_referral_firm_fee_amount') {
                                            if (get(data, 'lessee_referral_firm_fee_amount_choice', '') === 'Value') {
                                                if (errors.indexOf('Please check Buyer entries') === -1) {
                                                    console.log('BUYER ERROR 5');
                                                    errors.push('Please check Buyer entries');
                                                }
                                                complete = false;
                                            }
                                        } else if (key === 'lessee_referral_firm_fee_amount_percentage') {
                                            if (get(data, 'lessee_referral_firm_fee_amount_choice', '') === 'Percentage') {
                                                if (errors.indexOf('Please check Buyer entries') === -1) {
                                                    console.log('BUYER ERROR 6');
                                                    errors.push('Please check Buyer entries');
                                                }
                                                complete = false;
                                            }
                                        }
                                        else {
                                            console.log('keyy2', key);
                                            if (key !== 'lessee_referral_firm_company_name') {
                                                if (errors.indexOf('Please check Buyer entries') === -1) {
                                                    console.log('BUYER ERROR 7');
                                                    errors.push('Please check Buyer entries');
                                                }
                                                complete = false;
                                            }
                                        }
                                    }
                                }
                            })
                        }
					}
				}
            }

            if (v === "Seller") {
                if (data.lessors) {
					if (data.lessors.length === 0) {
						complete = false;
                        errors.push('Please enter a Seller');
					} else {
                        let companies_count = 0;
						data.lessors.forEach((lessor, indx) => {
                            if (typeof lessor.id === 'string') {
                                if (lessor.id[0] === 'c') {
                                    companies_count++;
                                }
                            }
							Object.keys(lessor).forEach((key) => {
								let validations = get(
									subFields.lessor[key],
									'validations',
									[]
								);

								// If key is guarantor_name and guarantor's value is N/A, remove validations
								if (key === 'guarantor_name') {
									if (get(data, `lessors[${indx}].guarantor`, '') === 'N/A') validations = [];
								}
                                // if lessor has company no need for name and last_name
                                if (key === 'name' || key === 'last_name') {
                                    console.log('get(data, `lessors[${index}].company_name`)', key, get(data, `lessors[${indx}].company_name`));
                                    if (get(data, `lessors[${indx}].company_name`)) validations = [];
                                }
                                
								validations.forEach((v) => {
									if (!!validate[v](lessor[key])) {
										complete = false;
                                        console.log('SELLER ERROR 1', key)
                                        if (errors.indexOf('Please check for Seller validation errors') === -1) {
                                            errors.push('Please check for Seller validation errors');
                                        }
									}
								});
							});
						});

                        const areOutsideBrokerFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
                            data,
                            LESSOR_OUTSIDE_BROKER_FIELDS,
                            LESSOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS
                        );
                        const areReferralFirmFieldsRequired = checkOutsideBrokerOrReferralFirmFields(
                            data,
                            LESSOR_REFERRAL_FIRM_FIELDS,
                            LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS
                        );
                        if (areOutsideBrokerFieldsRequired) {
                            LESSOR_OUTSIDE_BROKER_FIELDS.forEach(key => {
                                if (!LESSOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS.includes(key)) {
                                    if (get(data, 'lessor_outside_broker_id', '').toString().indexOf("c") !== -1) {
                                        if (key === "lessor_outside_broker_lastname") return;
                                        if (key === "lessor_outside_broker_name") return;
                                    } else {
                                        if (key === 'lessor_outside_broker_company_name') return;
                                    }
                                    
                                    if (typeof get(data, key, '') === 'string' && get(data, key, '').trim() === '') {
                                        if (key === 'lessor_outside_broker_w9_upload') {
                                            if (get(data, 'lessor_outside_broker_w9', '') === 'Yes') {
                                                console.log('SELLER ERROR 2')
                                                if (errors.indexOf('Please check for Seller validation errors') === -1) {
                                                    errors.push('Please check for Seller validation errors');
                                                }
                                                complete = false;
                                            }
                                        } else {
                                            console.log('SELLER ERROR 3', key)
                                            if (errors.indexOf('Please check for Seller validation errors') === -1) {
                                                errors.push('Please check for Seller validation errors');
                                            }
                                            complete = false;
                                        }   
                                    }
                                }
                            })
                        }
                        if (areReferralFirmFieldsRequired) {
                            LESSOR_REFERRAL_FIRM_FIELDS.forEach(key => {
                                if (!LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS.includes(key)) {
                                    if (get(data, 'referral_firm_id', '').toString().indexOf("c") !== -1) {
                                        if (key === 'referral_firm_name') return;
                                        if (key === 'referral_firm_lastname') return;
                                    }

                                    if (typeof get(data, key, '') === 'string' && get(data, key, '').trim() === '') {
                                        if (key === 'referral_firm_w9_upload') {
                                            if (get(data, 'referral_firm_w9', '') === 'Yes') {
                                                console.log('SELLER ERROR 4')
                                                if (errors.indexOf('Please check for Seller validation errors') === -1) {
                                                    errors.push('Please check for Seller validation errors');
                                                }
                                                complete = false;
                                            }
                                        } else if (key === 'referral_firm_fee_amount') {
                                            if (get(data, 'referral_firm_fee_amount_choice', '') === 'Value') {
                                                console.log('SELLER ERROR 5')
                                                if (errors.indexOf('Please check for Seller validation errors') === -1) {
                                                    errors.push('Please check for Seller validation errors');
                                                }
                                                complete = false;
                                            }
                                        } else if (key === 'referral_firm_fee_amount_percentage') {
                                            if (get(data, 'referral_firm_fee_amount_choice', '') === 'Percentage') {
                                                console.log('SELLER ERROR 6')
                                                if (errors.indexOf('Please check for Seller validation errors') === -1) {
                                                    errors.push('Please check for Seller validation errors');
                                                }
                                                complete = false;
                                            }
                                        }
                                        else {
                                            if (key !== 'referral_firm_company_name') {
                                                console.log('SELLER ERROR 7', key)
                                                if (errors.indexOf('Please check for Seller validation errors') === -1) {
                                                    errors.push('Please check for Seller validation errors');
                                                }
                                                complete = false;
                                            }
                                        }
                                    }
                                }
                            })
                        }
					}
				}
            }

            if (v === 'Commission Splits') {
                let pass = true;
                const fields = ['illi_represented_lessee','illi_represented_lessor'];
                fields.forEach(f => {
                    if (!data[f]) pass = false;
                })
                if (!pass) {
                    complete = false;
                    errors.push('Choose if illi Represented lessee or illi Represented lessor');
                }
            }

            if (v === "Payment Schedule") {
                const isEstimated = isEstimatedLease(data, false);
                const lease_term = get(data, "lease_term", []);
                const abated_rent_ranges = get(data, "abated_rent_ranges", []);

                const abated_ranges = isEstimated
                    ? calculateEstimatedAbatedRanges({ lease_term, abated_rent_ranges })
                    : calculateAbatedRanges({ lease_term, abated: abated_rent_ranges });
                const ranges = lease_term.flatMap((item) => item.ranges);
                const combinedRanges = concat(abated_ranges, ranges);
                const totalCalc = isEstimated
                    ? calculateEstimatedTermsTotal(combinedRanges)
                    : calculateTermsTotal(combinedRanges);

                const payments = data["payment_schedule"] ? data["payment_schedule"] : [];
                const totalPayment = sum(
                    flatMap(payments, (item) =>
                        item.map((i) => {
                            const shouldGetPayment = shouldGetPaymentAmount(i.option);
                            if (shouldGetPayment) {
                                return getPaymentAmount(data, i.option);
                            } else {
                                return currencyToNumber(i.value);
                            }
                        })
                    )
                );

                if (get2DecimalRound(totalPayment) > 0 && get2DecimalRound(totalCalc.totalCommission) > 0) {
                    if (get2DecimalRound(totalPayment) === get2DecimalRound(totalCalc.totalCommission)) {
                        complete = true;
                    }
                } else {
                    errors.push('Check payment schedule for errors');
                    complete = false;
                }
            }
            if (v === "Commission Adjustments") {
                const commission_adjustment = get(data, "commission_adjustment", null);
                const commission_adjustment_details = get(data, "commission_adjustment_details", []);

                if (commission_adjustment === "No" || !isEmpty(commission_adjustment_details)) {
                    complete = true;
                } else {
                    errors.push('Commission Adjustments errors');
                    complete = false;
                }
            }
            if (v === "Comments and Special Instructions") {
                if (!data.comments_and_special_instructions) {
                    errors.push('Comments and Special Instructions required');
                    complete = false;
                }
            }
            
            if (v === 'Documents') {
                if (data.documents) {
                    if (data.documents.length === 0) complete = false;
                } else {
                    complete = false;
                }
            }

            if (v === 'Internal Review') {
                const check = checkInternalReview({
                    data,
                    complete: JSON.parse(JSON.stringify(complete)),
                    errors: JSON.parse(JSON.stringify(errors)),
                    internalReviewers
                });
                complete = check.complete;
                errors = check.errors;
            }

            sections.push({
                name: v,
                complete,
                errors
            });
        }
    });
    console.log('sections', sections, data);
    return sections;
};

export const getRsTypeName = (id, rsTypes) => {
    if (!id || !rsTypes || !Array.isArray(rsTypes)) return "";
    const type = rsTypes.find((item) => item.id === id);
    if (!type) return "";
    return type.name;
};

export const renderHTML = (data, rsData = null) => {
    const diffObj = getDateDifference(data);

    const isEstimated = isEstimatedLease(data);

    const isFlatFee = data.flat_fee_commission === "Yes";

    const hasLesseeAssociate = get(data, "illi_represented_lessee", "") !== "No";
    const hasLessorAssociate = get(data, "illi_represented_lessor", "") !== "No";

    const make = (field) => {
        let value = get(data, field, "");

        const date_is_estimated = !!data[`${field}_estimated`];
        if (date_is_estimated) {
            const dataArr = data[`${field}_estimated_tbd`] ? data[`${field}_estimated_tbd`].split(",") : [];
            value =
                data[`${field}_estimated`] && dataArr.indexOf("TBD") !== -1 ? "TBD" : data[field] ? data[field] : "";
            return `<span><strong>${saleFields[field].label} :</strong> ${value} ${
                date_is_estimated && value !== "TBD" ? "(Estimated)" : ""
            }</span>`;
        }
        if (field.indexOf('_sqft') !== -1) {
            value = commatize(value);
        } 
        console.log('saleFields', saleFields[field], field, saleFields);
        return `<span><strong>${saleFields[field].label} :</strong> ${value}</span>`;
    };

    const makeCheckboxes = (field) => {
        const htmlSnippet = `<span>${saleFields[field].options
            .map((o) => {
                if (!o) return "";
                if (data[field]) {
                    if (data[field].indexOf(o) !== -1) {
                        return `<span class="ml-10 grayed"><input type="checkbox" checked />${o}</span>`;
                    }
                }
                return `<span class="ml-10 grayed"><input type="checkbox" />${o}</span>`;
            })
            .join("")}</span>`;

        return htmlSnippet;
    };

    const renderRentTotals = (data) => {
        const allRanges = makeAllRanges(data);
        const calculatedAbatedRanges = calculateAbatedRanges({
            lease_term: data.lease_term,
            abated: data.abated_rent_ranges,
        });
        const {
            rentCollected,
            the_abated_total,
            netCollected,
            the_commissions_added,
            the_calculatedAR,
            netCommission,
        } = makeTotalCalcs(allRanges, calculatedAbatedRanges, data);

        const lease_term = get(data, "lease_term", []);
        const abated = get(data, "abated_rent_ranges", []);

        const total = calculateLeaseTermTotal(lease_term, isFlatFee);

        const abatedRanges = calculateAbatedRanges3({
            lease_term,
            abated,
        });
        const totalAbated = calculateTermsTotal(abatedRanges);
        const net_commission = total.totalCommission + totalAbated.totalCommission;
        const net_rent = total.totalTermRent + totalAbated.totalTermRent;

        const commission_adjustment = get(data, "commission_adjustment", null);
        const commission_adjustment_details = get(data, "commission_adjustment_details", []);

        const hasCommissionAdjustments = commission_adjustment === "Yes" && !isEmpty(commission_adjustment_details);

        const renderCommissionAdjustments = () => {
            let result = [];
            if (hasCommissionAdjustments) {
                commission_adjustment_details.forEach((item, index) => {
                    result.push(`<h4>
                        #${index + 1} - ${item.option} - Adjusted Commission: <strong>${item.value}</strong>
                    </h4>`);
                });
            }
            return result.join(" ");
        };

        return `<div>
          <h4>Gross Rent Collected : <span className="bold">${formatDollar(total.totalTermRent)}</span></h4>
          <h4>Rent Abated : <span className="bold">${formatDollar(totalAbated.totalTermRent, true)}</span></h4>
          <h4>Net Rent Collected : <span className="bold">${formatDollar(net_rent)}}</span></h4>
          <h4>Gross Commission : <span className="bold">${formatDollar(total.totalCommission)}</span></h4>
          <h4>Abated Commission : <span className="bold">${formatDollar(totalAbated.totalCommission, true)}</span></h4>
          <h4>Net Commission : <span className="bold">${formatDollar(net_commission)}</span></h4>

          ${
              hasCommissionAdjustments
                  ? `
            <hr />
            ${renderCommissionAdjustments()}
            <h4>
                Total Adjusted Commission (Estimated):
                <span className="bold">${formatDollar(net_commission + calculateAdjustedCommission(data))}</span>
            </h4>
            `
                  : ""
          }
      </div>`;
    };

    const makeArray = (field, key, is_total) => {
        if (key === "multi-dates") {
            if (field === "lease_term") {
                return `
                    <ul class="list-unstyled">
                        ${data.lease_term
                            .map((m, i) => {
                                let all_total = 0;
                                let total_abated = 0;
                                if (field === "lease_term") {
                                    if (data.abated_rent_ranges) {
                                        if (data.abated_rent_ranges[i]) {
                                            const tot = getAbatedTotal(data.abated_rent_ranges[i], data);
                                            total_abated += tot;
                                        }
                                    }
                                }
                                return `<li>
                                <h4>Year ${m.year_num}</h4>
                                ${m.ranges.length === 0 ? `<div><em>None</em></div>` : ""}
                                ${m.ranges
                                    .map((mr, j) => {
                                        const { value, month1, month2, day1, day2, date1, date2, commissionRate } = mr;

                                        const term = isEstimated
                                            ? `<span>
                                            ${month1} ${day1} - ${month2} - ${day2}
                                        </span>`
                                            : `<span>
                                                ${mr.date1} to ${mr.date2}
                                            </span>`;

                                        const totalTerm = isEstimated ? calculateEstimatedTotal(mr) : null;

                                        const calcs = calculateLeaseLogic({
                                            date1,
                                            date2,
                                            value,
                                            data,
                                        });
                                        let allTotal = 0;
                                        calcs.forEach((cc) => {
                                            allTotal += cc.calcRounded;
                                        });
                                        all_total += allTotal;
                                        return `<div>
                                        ${term} - $${commatize(mr.value, true)}
                                        ${
                                            field === "lease_term"
                                                ? `<div>${
                                                      commissionRate ? commatize(commissionRate, true) : 0
                                                  }% commission rate</div>`
                                                : ""
                                        }
                                        <hr />
                                    </div>`;
                                    })
                                    .join("")}
                            </li>`;
                            })
                            .join("")}
                    </ul>
                `;
            } else if (field === "abated_rent_ranges") {
                return `<ul class="list-unstyled">
                ${data[field]
                    .map((m, i) => {
                        return `<li>
                        <h4>Year ${m.year_num}</h4>
                        ${m.ranges
                            .map((mr, j) => {
                                const lease_term = get(data, "lease_term", []);
        
                                const abatedRanges = calculateAbatedValues({
                                    lease_term,
                                    abated_range: mr,
                                });

                                const totalAbated = calculateTermsTotal(abatedRanges);

                                const term = isEstimated
                                    ? `${mr.month1} ${mr.day1} - ${mr.month2} ${mr.day2}`
                                    : `${mr.date1} to ${mr.date2}`;
                                return `<div>
                                <span class="mr-20">${term}</span> - ${formatDollar(totalAbated.totalTermRent, true)}
                            </div>`;
                            })
                            .join("")}
                    </li>`;
                    })
                    .join("")}
            </ul>`;
            }
            return `<ul class="list-unstyled">
                ${data[field]
                    .map((m, i) => {
                        return `<li>
                        <h4>Year ${m.year_num}</h4>
                        ${m.ranges
                            .map((mr, j) => {
                                return `<div>
                                <span class="mr-20">${mr.date1} to ${mr.date2}</span> - $${commatize(mr.value)}
                            </div>`;
                            })
                            .join("")}
                    </li>`;
                    })
                    .join("")}
            </ul>`;
        } else if (key === "multi-contact") {
            return `<ul class="list-unstyled">
                ${data[field]
                    .map((m, i) => {
                        let contactPhone = `Main Phone: ${m.main_phone || ""}<br />`;
                        if (a.phone_type === 'Mobile') {
                            contactPhone = `Mobile Phone: ${m.mobile_phone || ""}<br />`;
                        } else if (a.phone_type === 'Work') {
                            contactPhone = `Work Phone: ${m.work_phone || ""}<br />`;
                        }
                        
                        return `<li>
                    <div className="bordered-thin">
                        First Name: ${m.name || ""}<br />
                        Last Name: ${m.last_name || ""}<br />
                        Address 1: ${m.address1 || ""}<br />
                        Address 2: ${m.address2 || ""}<br />
                        City: ${m.city || ""}<br />
                        State: ${m.state || ""}<br />
                        Zip: ${m.zip || ""}<br />
                        Email: ${m.email || ""}<br />
                        ${contactPhone}
                    </div>
                    </li>`;
                    })
                    .join("<br />")}
            </ul>`;
        } else if (key === "name") {
            let html = ``;
            if (data[field]) {
                html = data[field]
                    .map((m) => {
                        return m.name + " " + m.last_name;
                    })
                    .join(", ");
            }
            return html;
        } else {
            let html = ``;
            if (data[field]) {
                html = data[field].map((m) => m[key]).join(", ");
            }
            return html;
        }
    };

    const renderLessors = () => {
        const model = subFields.lessor;
        const orderedKeys = [
            "id",
            "company_name",
            "name",
            "last_name",
            "main_phone",
            "work_phone",
            "mobile_phone",
            "email",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "fax",
            "guarantor",
            "upload_sos",
            "guarantor_name",
            "spousal_consent",
            "agency_disclosure",
            "llc_corp_verified",
            "upload_spousal_id",
            "upload_guarantor_id",
            "guarantor_spouse_name",
            "can_we_reach_out_for_testimonial",
            "can_we_reach_out_for_testimonial_comment",
        ];
        return `<div>
            ${data.lessors
                .map((dl, i) => {
                    return `<div>
                    <h4>Seller ${i + 1}</h4>
                    ${orderedKeys
                        .map((field) => {
                            // HIDE UNSELECTED
                            if (dl.phone_type) {
                                if (dl.phone_type === 'Work') {
                                    if (field === 'mobile_phone' || field === 'main_phone') return '';
                                } else if (dl.phone_type === 'Mobile') {
                                    if (field === 'work_phone' || field === 'main_phone') return '';
                                } else if (dl.phone_type === 'Main') {
                                    if (field === 'work_phone' || field === 'mobile_phone') return '';
                                }
                            } else {
                                if (field === 'work_phone' || field === 'mobile_phone') return '';
                            }

                            if (field === "id" || !model[field] || !Object.keys(dl).includes(field)) return null;
                            if (dl.company_name) {
                                if (field === "name" || field === "last_name") {
                                    return null;
                                }
                            } else {
                                if (field === "company_name") {
                                    return null;
                                }
                            }
                            return `<div>
                            <strong>${model[field].label}</strong> : <span>${dl[field] ? dl[field] : "n/a"}</span>
                        </div>`;
                        })
                        .join("")}
                </div>`;
                })
                .join("<br />")}
        </div>`;
    };

    const renderLessees = () => {
        const model = subFields.lessee;
        const orderedKeys = [
            "id",
            "company_name",
            "name",
            "last_name",
            "main_phone",
            "work_phone",
            "mobile_phone",
            "email",
            "address1",
            "address2",
            "city",
            "state",
            "zip",
            "fax",
            "guarantor",
            "upload_sos",
            "guarantor_name",
            "spousal_consent",
            "agency_disclosure",
            "llc_corp_verified",
            "upload_spousal_id",
            "upload_guarantor_id",
            "guarantor_spouse_name",
            "can_we_reach_out_for_testimonial",
            "can_we_reach_out_for_testimonial_comment",
        ];
        return `<div>
            ${data.lessees
                .map((dl, i) => {
                    return `<div>
                    <h4>Buyer ${i + 1}</h4>
                    ${orderedKeys
                        .map((field) => {
                            // HIDE UNSELECTED
                            if (dl.phone_type) {
                                if (dl.phone_type === 'Work') {
                                    if (field === 'mobile_phone' || field === 'main_phone') return '';
                                } else if (dl.phone_type === 'Mobile') {
                                    if (field === 'work_phone' || field === 'main_phone') return '';
                                } else if (dl.phone_type === 'Main') {
                                    if (field === 'work_phone' || field === 'mobile_phone') return '';
                                }
                            } else {
                                if (field === 'work_phone' || field === 'mobile_phone') return '';
                            }

                            if (field === "id" || !model[field] || !Object.keys(dl).includes(field)) return null;
                            if (dl.company_name) {
                                if (field === "name" || field === "last_name") {
                                    return null;
                                }
                            } else {
                                if (field === "company_name") {
                                    return null;
                                }
                            }
                            return `<div>
                            <strong>${model[field].label}</strong> : <span>${dl[field] ? dl[field] : "n/a"}</span>
                             </div>`;
                        })
                        .join("")}
                        </div>`;
                })
                .join("<br />")}
        </div>`;
    };

    const renderCommissionsLessor = () => {
        const total =
            data.commission_splits_agent
                .map((acc) => {
                    if (acc.Percentage === "" || isNaN(acc.Percentage)) {
                        acc.Percentage = "0";
                        return acc;
                    } else {
                        return acc;
                    }
                })
                .reduce((acc, curr) => {
                    return acc + parseFloat(curr.Percentage);
                }, 0) + "%";

        return `<tbody>
                <tr>
                    <td className="col-50">
                        <strong>Name</strong>
                    </td>
                    <td className="col-50">
                        <strong>Percentage</strong>
                    </td>
                </tr>
            ${data.commission_splits_agent
                .map((c, i) => {
                    return `<tr>
                            <td className="col-50">
                                ${c.Name}
                            </td>
                            <td className="col-50">
                                ${c.Percentage}
                            </td>
                        </tr>`;
                })
                .join("")}
            <tr>
                <td className="col-50">
                    <strong>Total</strong>
                </td>
                <td className="col-50">
                    ${total}
                </td>
            </tr>
        </tbody>`;
    };

    const renderCommissionsLessee = () => {
        const total =
            data.commission_splits_agent2
                .map((acc) => {
                    if (acc.Percentage === "" || isNaN(acc.Percentage)) {
                        acc.Percentage = "0";
                        return acc;
                    } else {
                        return acc;
                    }
                })
                .reduce((acc, curr) => {
                    return acc + parseFloat(curr.Percentage);
                }, 0) + "%";

        return `<tbody>
                <tr>
                    <td className="col-50">
                        <strong>Name</strong>
                    </td>
                    <td className="col-50">
                        <strong>Percentage</strong>
                    </td>
                </tr>
            ${data.commission_splits_agent2
                .map((c, i) => {
                    return `<tr>
                            <td className="col-50">
                                ${c.Name}
                            </td>
                            <td className="col-50">
                                ${c.Percentage}
                            </td>
                        </tr>`;
                })
                .join("")}
            <tr>
                <td className="col-50">
                    <strong>Total</strong>
                </td>
                <td className="col-50">
                    ${total}
                </td>
            </tr>
        </tbody>`;
    };

    const renderCommissionsOtherLessor = () => {
        return `<tbody>
            <tr>
                <td className="col-50">
                    <strong>Name</strong>
                </td>
                <td className="col-50">
                    <strong>Value</strong>
                </td>
            </tr>
            <tr>
                <td className="col-50">
                    Seller Referral
                </td>
                <td className="col-50">
                    ${
                        formatDollar(data.referral_firm_fee_amount)
                        // data.referral_firm_fee_amount_choice === "Value"
                        //     ? data.referral_firm_fee_amount
                        //     : data.referral_firm_fee_amount_percentage + " %"
                    }
                </td>
            </tr>
            <tr>
                <td className="col-50">
                    Seller - Outside Broker
                </td>
                <td className="col-50">
                    ${formatDollar(data.lessor_outside_broker_commission)}
                </td>
            </tr>
        </tbody>
        `;
    };

    const renderCommissionsOtherLessee = () => {
        return `<tbody>
            <tr>
                <td className="col-50">
                    <strong>Name</strong>
                </td>
                <td className="col-50">
                    <strong>Value</strong>
                </td>
            </tr>
            <tr>
                <td className="col-50">
                    Buyer Referral
                </td>
                <td className="col-50">
                    ${
                        formatDollar(data.lessee_referral_firm_fee_amount)
                        // data.lessee_referral_firm_fee_amount_choice === "Value"
                        //     ? formatDollar(data.lessee_referral_firm_fee_amount)
                        //     : data.lessee_referral_firm_fee_amount_percentage + " %"
                    }
                </td>
            </tr>
            <tr>
                <td className="col-50">
                    Buyer - Outside Broker
                </td>
                <td className="col-50">
                    ${formatDollar(data.outside_broker_commission)}
                </td>
            </tr>
        </tbody>
        `;
    };

    const renderReferrals = (name) => {
        const prefix = name === 'lessor' ? '' : 'lessee_';
        const html = [];

        if (!data[`${name}_referrals`]) return '';

        data[`${name}_referrals`].forEach((ref, i) => {
            const fields = [];

            ['name', 'lastname', 'contact_dba_name', 'address1', 'city', 'state', 'zip', 'contact', 'main_phone', 'fax', 'email', 'taxid', 'w9'].forEach((fld) => {
                const field = `${prefix}referral_firm_${fld}`;
                fields.push(`<div><strong>${saleFields[field].label}</strong> : ${ref[fld] || ''}</div>`);
            });

            const a = `<div>
                <h4>Referral #${i + 2}</h4>
                ${fields.join('')}
            </div>`;

            html.push(a);
        });

        return html.join('');
    }

    const renderField = (field) => {
        if (data[field]) return data[field];
        return "";
    };

    const renderCommissionAdjustments = () => {
        const commission_adjustment = get(data, "commission_adjustment", null);
        const commission_adjustment_details = get(data, "commission_adjustment_details", []);
        const hasCommissionAdjustments = commission_adjustment === "Yes" && !isEmpty(commission_adjustment_details);

        let result = [];
        if (hasCommissionAdjustments) {
            commission_adjustment_details.forEach((item, index) => {
                result.push(
                    `<p>
                        #${index + 1} - ${item.option} - Adjusted Commission: <strong>${item.value}</strong>
                    </p>`
                );
            });

            result.push(`<p>
                Total Adjusted Commission:
                <span class="bold">${formatDollar(
                    currencyToNumber(get(data, "sale_sale_commission", 0)) + calculateAdjustedCommission(data)
                )}</span>
            </p>`);

            return `
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-100 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>COMMISSION ADJUSTMENTS</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-100">
                                ${result.join(" ")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />
            <br />
            `;
        } else {
            return "";
        }
    };

    return `
        <html>
            <head>
                <style>
                body {
                    font-size: 10px;
                    font-family: 'Arial', sans-serif;
                }
                td {
                    vertical-align: top;
                }
                .list-unstyled {
                    list-style: none;
                  }
                .grayed {
                    -webkit-filter: grayscale(1);
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .mr-10 {
                    margin-right: 10px;
                }
                .ml-10 {
                    margin-left: 10px;
                }
                .col-5 {
                    width: 5%;
                }
                .col-10 {
                    width: 10%;
                }
                .col-15 {
                    width: 15%;
                }
                .col-20 {
                width: 20%;
                }
                
                .col-30 {
                width: 30%;
                }
                
                .col-40 {
                width: 40%;
                }
                
                .col-50 {
                width: 50%;
                }
                .col-55 {
                width: 55%;
                }
                
                .col-60 {
                width: 60%;
                }
                
                .col-70 {
                width: 70%;
                }
                
                .col-80 {
                width: 80%;
                }
                
                .col-90 {
                width: 90%;
                }
                
                .col-100 {
                width: 100%;
                }
                
                .b-t {
                border-top: 1px solid black;
                }
                
                .b-b {
                border-bottom: 1px solid black;
                }
                
                .b-r {
                border-right: 1px solid black;
                }
                
                .b-l {
                border-right: 1px solid black;
                }
                
                .the-table table {
                width: 100%;
                }
                
                .the-table table, .the-table th, .the-table td {
                border: 1px solid #878787;
                border-collapse: collapse;
                font-size: 10px;
                }
                
                .the-table td {
                padding: 5px;
                }
                
                .no-bt {
                border-top: 1px solid rgba(0,0,0,0) !important;
                }
                .no-bb {
                border-bottom: 1px solid rgba(0,0,0,0) !important;
                }
                .the-table .no-br {
                border-right: 1px solid rgba(0,0,0,0) !important;
                }
                .the-table table {
                    table-layout: fixed;
                }
                .the-table .no-bl {
                border-left: 1px solid rgba(0,0,0,0) !important;
                }
                .bold {
                    font-weight: bold;
                }
                .text-center {
                    text-align: center;
                }
                .underlined {
                border-bottom: 1px solid #ccc;
                display: inline-block;
                }
                .bordered-thin {
                border: 1px solid #ccc;
                padding: 10px;
                margin: 10px;
                border-radius: 5px;
                }
                .pagebreak {
                page-break-after: always;
                }
                .small-table-text td,
                .small-table-text th {
                    font-size: 9px;
                }
                .mt-10 {
                    margin-top: 10px;
                }
                </style>
            </head>
        <body>
            <div>
                <table style="width:100%">
                    <tbody>
                        <tr>
                            <td class="col-10"><img src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png" style="width:60px" /></td>
                            <td class="col-90">
                                <div class="text-center"><h3>ROUTING SHEET - SALE</h3></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-20">${make("date")}</td>
                            <td class="col-60">${make("project_name")}</td>
                            <td class="col-20">${make("illi_number")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-80 no-bt">${make("project_address")}</td>
                            <td class="col-20 no-bt">PM # </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-80 no-bt bold">Is Property Located in the City of Los Angeles ?</td>
                            <td class="col-20 no-bt">${makeCheckboxes("la_city_verification")}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-30 no-bt">
                                <strong>Listings Procured By : </strong>
                                ${makeArray("listings_procured_by", "name")}
                            </td>
                            <td class="col-30 no-bt">${make("lead_source")}</td>
                            <td class="col-40 no-bt">
                                <strong>Deal Generated By : </strong>
                                ${makeCheckboxes("deal_generated_by")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-50 no-bt">
                                <strong>Can deal be submitted to Public Relations ? : </strong>
                                ${makeCheckboxes("can_deal_be_submitted_to_public_relations")}
                            </td>
                            <td class="col-50 no-bt">
                                ${make("can_deal_be_submitted_to_public_relations_comment")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-40 no-bt">
                                <strong>illi Represented Seller :</strong>
                                ${makeCheckboxes("illi_represented_lessor")}
                            </td>
                            <td class="col-60 no-bt"><strong>Agent(s)</strong> : ${makeArray(
                                "illi_represented_lessor_agents",
                                "name"
                            )}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-40 no-bt">
                                <strong>illi Represented Buyer :</strong>
                                ${makeCheckboxes("illi_represented_lessee")}
                            </td>
                            <td class="col-60 no-bt"><strong>Agent(s)</strong> : ${makeArray(
                                "illi_represented_lessee_agents",
                                "name"
                            )}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br/>
            <br/>

            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-50">
                                ${make("premises_address")}
                            </td>
                            <td class="col-50">
                                <strong>Window Sign Returned ? : </strong>
                                ${makeCheckboxes("window_signs_returned")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-60 no-bt">
                                ${make("unit_number")}
                            </td>
                            <td class="col-40 no-bt">
                                ${make("window_sign_number")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-20 no-bt">
                                ${make("city")}
                            </td>
                            <td class="col-20 no-bt">
                                ${make("zip")}
                            </td>
                            <td class="col-60 no-bt">
                                <strong>Sign Removal Requested? : </strong>
                                ${makeCheckboxes("sign_removal_requested")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-60 no-bt">
                                ${make("county")}
                            </td>
                            <td class="col-40 no-bt">
                                <strong>Loopnet/Marketing Updated? : </strong>
                                ${makeCheckboxes("loopnet_marketing_updated")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

			<div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-100 no-bt">
                                <strong>Use Type : </strong>
                                ${makeCheckboxes("property_type")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br/>
            <br/>

            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 class="underlined">ESCROW INFORMATION</h4>
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 class="underlined">SALE INFORMATION</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_officer_name")}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${make("sale_purchase_price")}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_company")}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${make("sale_commission_rate")}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_address")}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${make("sale_sale_commission")}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_address_2")}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${make("sale_escrow_opening_date")}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_city")}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${make("sale_escrow_closing_date")}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_state")}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${make("sale_building_sqft")}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_zip")}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${make("sale_land_sqft")}
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_phone")}
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-bb no-br no-bl">
                            </td>
                        </tr>
                        <tr>
                            <td class="col-45">
                                ${make("escrow_email")}
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-bb no-br no-bl">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br/>
            <br />
        
            ${renderCommissionAdjustments()}

            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 class="underlined">SELLER</h4>
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 class="underlined">BUYER</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45">
                                ${renderLessors()}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${renderLessees()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        <br />
        <br />
        <div class="the-table">
            <table>
                <tbody>
                    <tr>
                        <td class="col-45 no-bt no-br no-bl no-bb">
                            <h4 class="underlined">SELLER CONTACTS</h4>
                        </td>
                        <td class="col-5 no-bt no-bb no-br"></td>
                        <td class="col-45 no-bt no-br no-bl no-bb">
                            <h4 class="underlined">BUYER CONTACTS</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="the-table">
            <table>
                <tbody>
                    <tr>
                        ${
                            data.lessor_contacts
                                ? `<td class="col-45"> 
                            ${makeArray("lessor_contacts", "multi-contact")}
                            ${data.lessor_contacts.length === 0 ? `<div class="text-center"><em>N/A</em></div>` : ""}
                        </td>`
                                : ""
                        }
                        <td class="col-5 no-bt no-bb"></td>
                        ${
                            data.lessee_contacts
                                ? `<td class="col-45">
                            ${makeArray("lessee_contacts", "multi-contact")}
                            ${data.lessee_contacts.length === 0 ? `<div class="text-center"><em>N/A</em></div>` : ""}
                        </td>`
                                : ""
                        }
                    </tr>
                </tbody>
            </table>
        </div>
            <br />
            <h4 style="text-decoration:underline">COMMISSION DISTRIBUTION SELLER</h4>
            ${
                hasLessorAssociate
                    ? `<div class="the-table small-table-text" style="width:100%">
                <table>
                    ${renderCommissionsLessor()}
                </table>
            </div>`
                    : ""
            }
            <div class="the-table small-table-text mt-10" style="width:100%">
                <table>
                    ${renderCommissionsOtherLessor()}
                </table>
            </div>
            <br />
            <br />
            <h4 style="text-decoration:underline"> COMMISSION DISTRIBUTION BUYER</h4>
            ${
                hasLesseeAssociate
                    ? `<div class="the-table small-table-text" style="width:100%">
                <table>
                    ${renderCommissionsLessee()}
                </table>
            </div>`
                    : ""
            }
            <div class="the-table small-table-text mt-10" style="width:100%">
                <table>
                    ${renderCommissionsOtherLessee()}
                </table>
            </div>
            <br />
            <br />
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">SELLER - OUTSIDE BROKER</h4>
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">BUYER - OUTSIDE BROKER</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            ${[
                "name",
                "lastname",
                "address1",
                "city",
                "state",
                "zip",
                "contact",
                "main_phone",
                "fax",
                "email",
                "taxid",
                "w9",
            ]
                .map((ff, i) => {
                    const r = `lessor_outside_broker_${ff}`;
                    const o = `outside_broker_${ff}`;
                    const add = i === 0 ? `` : `no-bt`;
                    if (ff === "w9") {
                        return (
                            `<div class="the-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="col-45 ` +
                            add +
                            `">
                                        <strong>W9 : </strong>
                                        ${makeCheckboxes(r)}
                                    </td>
                                    <td class="col-5 no-bt no-bb"></td>
                                    <td class="col-45 ${add}">
                                        <strong>W9 : </strong>
                                        ${makeCheckboxes(o)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>`
                        );
                    }
                    return `<div class="the-table">
                    <table>
                        <tbody>
                            <tr>
                                <td class="col-45 ${add}">
                                    ${make(r)}
                                </td>
                                <td class="col-5 no-bt no-bb"></td>
                                <td class="col-45 ${add}">
                                    ${make(o)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>`;
                })
                .join("")}
            <br />
            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">SELLER REFERRAL</h4>
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">BUYER REFERRAL</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            ${[
                "name",
                "lastname",
                "address1",
                "city",
                "state",
                "zip",
                "contact",
                "main_phone",
                "fax",
                "email",
                "taxid",
                "w9",
            ]
                .map((ff, i) => {
                    const o = `referral_firm_${ff}`;
                    const r = `lessee_referral_firm_${ff}`;
                    const add = i === 0 ? `` : `no-bt`;
                    if (ff === "w9") {
                        return `<div class="the-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="col-45 no-bt no-bb no-br no-bl">
                                        <strong>W9 : </strong>
                                        ${makeCheckboxes(o)}
                                    </td>
                                    <td class="col-5 no-bt no-bb"></td>
                                    <td class="col-45 ${add}">
                                        <strong>W9 : </strong>
                                        ${makeCheckboxes(r)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>`;
                    }
                    return `<div class="the-table">
                    <table>
                        <tbody>
                            <tr>
                                <td class="col-45 ${add}">
                                    ${make(o)}
                                </td>
                                <td class="col-5 no-bt no-bb"></td>
                                <td class="col-45 ${add}">
                                    ${make(r)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>`;
                })
                .join("")}

                <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">OTHER SELLER REFERRAL(S)</h4>
                            </td>
                            <td class="col-5 no-bt no-bb no-br"></td>
                            <td class="col-45 no-bt no-br no-bl no-bb">
                                <h4 style="text-decoration:underline">OTHER BUYER REFERRAL(S)</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td class="col-45"> 
                                ${renderReferrals('lessor')}
                                ${(!data?.lessor_referrals || data?.lessor_referrals.length === 0) ? `<div class="text-center"><em>N/A</em></div>` : ""}
                            </td>
                            <td class="col-5 no-bt no-bb"></td>
                            <td class="col-45">
                                ${renderReferrals('lessee')}
                                ${(!data?.lessee_referrals || data?.lessee_referrals.length === 0) ? `<div class="text-center"><em>N/A</em></div>` : ""}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />
            <br />

            <div>
                <h4 style="text-decoration:underline">COMMENTS AND SPECIAL INSTRUCTIONS</h4>
            </div>
            <div class="the-table">
                <table>
                    <tbody>
                        ${
                            data.comments_and_special_instructions
                                ? `<tr>
                                <td class="col-100">
                                    ${data.comments_and_special_instructions}
                                </td>
                            </tr>`
                                : ""
                        }
                    </tbody>
                </table>
            </div>
        </body>
    </html>
    `;
};

export const renderSignRequestForm = (data) => {
    if (data.sign_removal_requested !== "Yes") return null;

    const makeCheckboxes = (field) => {
        const htmlSnippet = `<span>${saleFields[field].options
            .map((o) => {
                if (!o) return "";
                if (data[field]) {
                    if (data[field].indexOf(o) !== -1) {
                        return `<span class="ml-10 grayed"><input type="checkbox" checked />${o}</span>`;
                    }
                }
                return `<span class="ml-10 grayed"><input type="checkbox" />${o}</span>`;
            })
            .join("")}</span>`;
        return htmlSnippet;
    };

    const renderField = (field) => {
        if (data[field]) return data[field];
        return "";
    };

    return `
        <html>
            <head>
                <style>
                body {
                    font-size: 10px;
                    font-family: 'Arial', sans-serif;
                }
                td {
                    vertical-align: top;
                }
                .list-unstyled {
                    list-style: none;
                  }
                .grayed {
                    -webkit-filter: grayscale(1);
                }
                .mr-20 {
                    margin-right: 20px;
                }
                .mr-10 {
                    margin-right: 10px;
                }
                .ml-10 {
                    margin-left: 10px;
                }
                .col-5 {
                    width: 5%;
                }
                .col-10 {
                    width: 10%;
                  }
                  .col-15 {
                      width: 15%;
                  }
                  .col-20 {
                    width: 20%;
                  }
                  
                  .col-30 {
                    width: 30%;
                  }
                  
                  .col-40 {
                    width: 40%;
                  }
                  
                  .col-50 {
                    width: 50%;
                  }
                  
                  .col-60 {
                    width: 60%;
                  }
                  
                  .col-70 {
                    width: 70%;
                  }
                  
                  .col-80 {
                    width: 80%;
                  }
                  
                  .col-90 {
                    width: 90%;
                  }
                  
                  .col-100 {
                    width: 100%;
                  }
                  
                  .b-t {
                    border-top: 1px solid black;
                  }
                  
                  .b-b {
                    border-bottom: 1px solid black;
                  }
                  
                  .b-r {
                    border-right: 1px solid black;
                  }
                  
                  .b-l {
                    border-right: 1px solid black;
                  }
                  
                  .the-table table {
                    width: 100%;
                  }
                  
                  .the-table table, .the-table th, .the-table td {
                    border: 1px solid #878787;
                    border-collapse: collapse;
                    font-size: 10px;
                  }
                  
                  .the-table td {
                    padding: 5px;
                  }
                  
                  .no-bt {
                    border-top: 1px solid rgba(0,0,0,0) !important;
                  }
                  .no-bb {
                    border-bottom: 1px solid rgba(0,0,0,0) !important;
                  }
                  .the-table .no-br {
                    border-right: 1px solid rgba(0,0,0,0) !important;
                  }
                  .the-table table {
                      table-layout: fixed;
                  }
                  .the-table .no-bl {
                    border-left: 1px solid rgba(0,0,0,0) !important;
                  }
                  .bold {
                      font-weight: bold;
                  }
                  .text-center {
                      text-align: center;
                  }
                  .underlined {
                    border-bottom: 1px solid #ccc;
                    display: inline-block;
                  }
                  .bordered-thin {
                    border: 1px solid #ccc;
                    padding: 10px;
                    margin: 10px;
                    border-radius: 5px;
                  }
                  .pagebreak {
                    page-break-after: always;
                  }
                  .small-table-text td,
                  .small-table-text th {
                      font-size: 9px;
                  }
                  .mt-10 {
                      margin-top: 10px;
                  }
                </style>
            </head>
        <body>
        
        <div>
        <table class="the-table">
            <tbody>
                <tr>
                    <td class="col-10">
                        <img
                            src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png"
                            style="width: 60px"
                        />
                    </td>
                    <td class="col-90">
                        <div class="text-center">
                            <h3>SIGN REQUEST</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <br />

    <div style="display: flex; justify-content: center">
        <div style="flex-basis: 30%">
            <span>
                <strong>illi#:</strong>
            </span>
            <span
                class="col-20"
            >
                ${renderField("illi_number")}
            </span>
        </div>
        <div style="flex-basis: 30%">
            <span>
                <strong>Date:</strong>
            </span>
            <span
            >
                ${renderField("date")}
            </span>
        </div>
        <div style="flex-basis: 30%">
            <span>
                <strong>Submitted By:</strong>
            </span>
        </div>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">Street Address:</th>
                <td
                    colspan="5"
                >
                    ${renderField("project_address")}
                </td>
            </tr>
            <tr>
                <th class="col-20">City:</th>
                <td
                >
                    ${renderField("city")}
                </td>
                <th class="col-20">State:</th>
                <td
                >
                    ${renderField("state")}
                </td>
                <th class="col-20">Zip:</th>
                <td
                >
                    ${renderField("zip")}
                </td>
            </tr>
            <tr>
                <th class="col-20">Cross Street:</th>
                <td colspan="5"></td>
            </tr>
        </table>
    </div>

    <br />

    <div>
        <div class="col-50 inline-block">
            <span>Access Necessary?</span>
            <span
                class="col-80"
            >
                ${makeCheckboxes("access_necessary")}
            </span>
        </div>
        <div class="col-50 inline-block">
            <span>Contact:</span>
            <span
                class="col-80"
            >
                ${renderField("contact")}
            </span>
        </div>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">Order Type:</th>
                <td
                    class="col-80"
                >
                    ${makeCheckboxes("order_type")}
                </td>
            </tr>
        </table>
    </div>

    <br />

    <div>
        <h4>MAINTENANCE DESCRIPTION / SPECIAL INSTRUCTIONS</h4>
        <div class="the-table">
            <span
            >
                ${renderField("maintenance_description")}
            </span>
        </div>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">Sign Size:</th>
                <td
                >
                    ${renderField("sign_size")}
                </td>
                <th class="col-20">Location:</th>
                <td
                >
                    ${renderField("location")}
                </td>
            </tr>
        </table>
    </div>

    <br />

    <div class="the-table">
        <table>
            <tr>
                <th class="col-20">
                    Reason for sign request or change:
                </th>
                <td
                    class="col-80"
                >
                    ${renderField("sign_request_reason")}
                </td>
            </tr>
        </table>
    </div>

    <br />

    <div style="display: flex; justify-content: center">
        <div style="flex-basis: 30%">
            <span>Date: </span>
            <span
                class="col-20"
            >
                ${renderField("date")}
            </span>
        </div>
        <div style="flex-basis: 30%">
            <span>Todd Nathanson Signature:</span>
        </div>
    </div>

        </body>
    </html>
    `;
};
