export const USE_TYPES = ['Retail', 'Industrial', 'Office', 'Self-Storage', 'Multi-Family', 'Medical Office', 'Residential', 'Association', 'Auto', 'Land', 'Retail/Office', 'Retail/Residential', 'School', 'Restaurant', 'Retail/Restaurant', 'Flex', 'Student', 'Hospitality', 'Health Care', 'Specialty', 'Sports and Entertainment'];

const propertyFields = {
	property_is_for: {
		type: "checkbox",
		label: "Property is for ?",
		options: ["Sale", "Lease", "No Listing"],
		validations: [],
	},
	// has_sent_marketing_information - "yes or blank"
	property_is: {
		type: "checkbox",
		label: "Property is ?",
		options: ["No Listing","Property Management"],
		validations: [],
	},
	nl_number: {
		type: 'input',
		label: 'No Listing #',
		validations: []
	},
	property_price_per_sqft: {
		type: "input",
		label: "Price per Square Feet",
		validations: ["min1", "max500"],
		isDollar: true,
	},
	name: {
		type: "input",
		label: "Project Name",
		validations: ["min1", "max500"],
	},
	lease_type: {
		type: "select",
		label: "Lease Type",
		options: [
			"",
			"NNN",
			"MG",
			"FSG"
		],
	},
	use_type: {
		type: "select",
		label: "Property Type",
		options: USE_TYPES,
	},
	loopnet_url: {
		type: 'input',
		label: 'Loopnet URL'
	},
	street1: {
		type: "input",
		label: "Address",
		validations: ["min5", "max500", "required"],
	},
	address_is_range: {
		type: "radio",
		label: "Is Address a Range?,Yes,No",
		options: ["Yes", "No"],
	},
	street_range1: {
		type: "input",
		label: "Starting Range",
		validations: ["min1", "max500","no_letters"],
	},
	street_range2: {
		type: "input",
		label: "Ending Range",
		validations: ["min1", "max500","no_letters"],
	},
	address2_is_range: {
		type: "radio",
		label: "Is Address 2 a Range?,Yes,No",
		options: ["Yes", "No"],
	},
	street2_range1: {
		type: "input",
		label: "Starting Range",
		validations: ["min1", "max500","no_letters"],
	},
	street2_range2: {
		type: "input",
		label: "Ending Range",
		validations: ["min1", "max500","no_letters"],
	},
	street2: {
		type: "input",
		label: "Address 2",
		validations: ["min1", "max500"],
	},
	county: {
		type: "input",
		label: "County",
		validations: ['required'],
	},
	city: {
		type: "input",
		label: "City",
		validations: ["min2", "max500","required"],
	},
	state: {
		type: "select",
		label: "State",
		options: [
			{ name: "Alabama", abbreviation: "AL" },
			{ name: "Alaska", abbreviation: "AK" },
			{ name: "Arizona", abbreviation: "AZ" },
			{ name: "Arkansas", abbreviation: "AR" },
			{ name: "California", abbreviation: "CA" },
			{ name: "Colorado", abbreviation: "CO" },
			{ name: "Connecticut", abbreviation: "CT" },
			{ name: "Delaware", abbreviation: "DE" },
			{ name: "Florida", abbreviation: "FL" },
			{ name: "Georgia", abbreviation: "GA" },
			{ name: "Hawaii", abbreviation: "HI" },
			{ name: "Idaho", abbreviation: "ID" },
			{ name: "Illinois", abbreviation: "IL" },
			{ name: "Indiana", abbreviation: "IN" },
			{ name: "Iowa", abbreviation: "IA" },
			{ name: "Kansas", abbreviation: "KS" },
			{ name: "Kentucky", abbreviation: "KY" },
			{ name: "Louisiana", abbreviation: "LA" },
			{ name: "Maine", abbreviation: "ME" },
			{ name: "Maryland", abbreviation: "MD" },
			{ name: "Massachusetts", abbreviation: "MA" },
			{ name: "Michigan", abbreviation: "MI" },
			{ name: "Minnesota", abbreviation: "MN" },
			{ name: "Mississippi", abbreviation: "MS" },
			{ name: "Missouri", abbreviation: "MO" },
			{ name: "Montana", abbreviation: "MT" },
			{ name: "Nebraska", abbreviation: "NE" },
			{ name: "Nevada", abbreviation: "NV" },
			{ name: "New Hampshire", abbreviation: "NH" },
			{ name: "New Jersey", abbreviation: "NJ" },
			{ name: "New Mexico", abbreviation: "NM" },
			{ name: "New York", abbreviation: "NY" },
			{ name: "North Carolina", abbreviation: "NC" },
			{ name: "North Dakota", abbreviation: "ND" },
			{ name: "Ohio", abbreviation: "OH" },
			{ name: "Oklahoma", abbreviation: "OK" },
			{ name: "Oregon", abbreviation: "OR" },
			{ name: "Pennsylvania", abbreviation: "PA" },
			{ name: "Rhode Island", abbreviation: "RI" },
			{ name: "South Carolina", abbreviation: "SC" },
			{ name: "South Dakota", abbreviation: "SD" },
			{ name: "Tennessee", abbreviation: "TN" },
			{ name: "Texas", abbreviation: "TX" },
			{ name: "Utah", abbreviation: "UT" },
			{ name: "Vermont", abbreviation: "VT" },
			{ name: "Virginia", abbreviation: "VA" },
			{ name: "Washington", abbreviation: "WA" },
			{ name: "West Virginia", abbreviation: "WV" },
			{ name: "Wisconsin", abbreviation: "WI" },
			{ name: "Wyoming", abbreviation: "WY" },
		],
	},
	zip: {
		type: "input",
		label: "Zip",
		validations: ["min5", "max15"],
	},
	la_city_verification: {
		type: "radio",
		label: "Is Property Located in the City of L.A ?,Yes,No",
		options: ["Yes", "No"],
	},
	phone_number: {
		type: "input",
		label: "Phone Number",
		validations: ["min10", "max50", "no_letters"],
	},
	fax_number: {
		type: "input",
		label: "Fax Number",
		validations: ["no_letters", "min10", "max50"],
	},
	other_number: {
		type: "input",
		label: "Other Number",
		validations: ["no_letters", "min10", "max50"],
	},
	illi_represented: {
		type: "radio", // default Yes
		label: "illi Represented",
		options: ["Yes", "No"],
	},
	price_per_sqft: {
		type: "input",
		label: "Price Per Sqft. (Start and End)",
		validations: ["max50"],
		isDollar: true,
	},
	purchasing_stage: {
		type: "conditional select",
		label: "Purchasing Stage",
		options: [
			"Phone Call",
			"LOI/Agreement",
			"Purchase Agreement",
			"Present Offer",
			"Counter",
			"Acceptance",
			"Escrow",
			"Inspection",
			"Closing",
		],
	},
	selling_stage: {
		type: "conditional select",
		label: "Selling Stage",
		options: [
			"Property Owner - Phone Call",
			"Property Owner - 1st Appointment",
			"Property Owner - 2nd Meeting",
			"Property Owner - ERTS",
			"Property Owner - Listing Procured",
			"Property Owner - Submission Form",
			"Property Owner - Sign Request",
			"Property Owner - Client Update",
			"Buyer - Phone Call",
			"Buyer - LOI/Agreement",
			"Buyer - Escrow",
			"Buyer - Inspection",
			"Buyer - Removal of Contingincies",
			"Buyer - Closing",
		],
	},
	leasing_stage: {
		type: "conditional select",
		label: "Leasing Stage",
		options: [
			"Tenant - Phone Call",
			"Tenant - Showing",
			"Tenant - LOI/Agreement",
			"Tenant - Lease Prep",
			"Tenant - Lease Execution",
			"Owner - Phone Call",
			"Owner - Meeting",
			"Owner - Proposal",
			"Owner - ERTL",
			"Owner - Submission Form",
			"Owner - Sign Request",
			"Owner - Client Update",
			"Owner - LOI/Agreement",
			"Owner - Lease Prep",
			"Owner - Lease Execution",
		],
	},
	illi_number: {
		type: "input",
		label: "illi Number",
		validations: [],
	},
	prefixless_illi_number: {
		type: "input",
		label: "illi Number",
		validations: [],
	},
	pm_number: {
		type: "input",
		label: "PM #",
		validations: []
	},
	asking_price: {
		type: "input",
		label: "Asking Price",
		validations: ["min1"],
		isDollar: true,
	},
	term: {
		type: "input",
		label: "Listing Term",
		// options: [ // - used to be Select
		// 	"1 year",
		// 	"2 years",
		// 	"3 years",
		// 	"4 years",
		// 	"5 years",
		// 	"6 years",
		// 	"7 years",
		// 	"8 years",
		// 	"9 years",
		// 	"10 years",
		// ],
	},
	goal: {
		type: "checkbox",
		label: "Goal",
		options: ["Lease", "Sell", "Other"],
	},
	other_goal: {
		type: "input",
		label: "Other Goal",
		validations: ["min1"],
	},
	exclusions: {
		type: "input",
		label: "Exclusions",
		validations: ["min5"],
	},
	interested_in_property_mgmt: {
		type: "radio",
		label: "Interested in Property Management?",
		options: ["Yes", "No"],
	},
	spaces: {
		type: "object",
	},
	total_building_sqft: {
		type: 'input',
		label: 'Total SQFT of Building',
		validations: ["no_letters"]
	},
	total_land_sqft: {
		type: 'input',
		label: 'Total SQFT of Land',
		validations: ["no_letters"]
	},
	sale_price: {
		type: 'input',
		label: 'Last Sale Price',
		isDollar: true,
	},
	last_sale_date: {
		type: 'input',
		label: 'Last Sale Date'
	}
};

export default propertyFields;

const initialPropertyDataObj = {
	agent_id: null,
	owner_id: null,
};
Object.keys(propertyFields).forEach((field) => {
	if (field === "illi_represented") {
		initialPropertyDataObj[field] = "1"; // default Yes
	} else if (field === "spaces") {
		initialPropertyDataObj[field] = [];
	} else {
		initialPropertyDataObj[field] = "";
	}
});
export const initialPropertyData = { ...initialPropertyDataObj };

export const contactTypes = [
	{
		name: 'Property Owner',
		id: 'OWN'
	},
	{
		name: 'Buyer',
		id: 'BUY'
	},
	{
		name: 'Tenant',
		id: 'TEN'
	},
	{
		name: 'Vendor',
		id: 'VEND'
	},
	{
		name: 'Property Manager',
		id: 'PROP'
	},
	{
		name: 'Real Estate Agent',
		id: 'REA'
	},
	{
		name: 'Real Estate Broker',
		id: 'REB'
	},
	{
		name: 'Other',
		id: 'OTHER'
	}
];

export const importerFields = [
	'illi_number',
	'pm_number',
	'name',
	'street_range1',
	'street_range2',
	'street1',

	'street2_range1',
	'street2_range2',
	'street2',
	'city',
	'state',
	'zip',

	'use_type',
	'total_building_sqft',
	'property_is_for',
	'PRIMARY_AGENT',
	'SECONDARY_AGENT',
	'THIRD_AGENT',
	'FOURTH_AGENT',

	'exported_date',
	'property_id'
];

export const importerFieldNames = [
	'illi Number',
	'PM Number',
	'Name',
	'Street 1 Range Start',
	'Street 1 Range End',
	'Street 1',

	'Street 2 Range Start',
	'Street 2 Range End',
	'Street 2',
	'City',
	'State',
	'Zip',

	'Company Type',
	'Total Building Sq.ft.',
	'Property is for',
	'PRIMARY_AGENT',
	'SECONDARY_AGENT',
	'THIRD_AGENT',
	'FOURTH_AGENT',

	'Exported Date',
	'Property ID'
];

export const COSTAR_IMPORTER_FIELDS = [
	"street1",
	"city",
	"zip",
	"name",
	"use_type",

	'owner_name',
	'owner_phone',
	'owner_contact',
	'owner_address',
	'owner_city_state_zip',

	'total_land_sqft',
	'total_building_sqft'
]


export const COSTAR_IMPORTER_FIELD_NAMES = [
    "Property Address",
    "City",
    "Zip",
    "Property Name",
    "PropertyType",
	
    "True Owner Name",
    "True Owner Phone",
    "True Owner Contact",
    "True Owner Address",
    "True Owner City State Zip",

    "Land Area (SF)",
    "RBA",
];

export const findData = (data, field, fields = importerFields) => {
	const index = fields.indexOf(field);
	if (index !== -1) {
		if (data[index]) {
			const dat = typeof data[index] === 'string' ? data[index] : data[index].toString();
			const trimmed = dat.trim();
			return trimmed;
		}
	}
	return '';
}


/**
 * Sanitize the PropertyTye
 * E.g: Retail (Neighborhood Center) -> Retail
 */
export const sanitizePropertyType = (fieldText) => {
	const patterns = [
		/\s*\([^)]+\)\s*$/, // Matches "(...)"
		/\s*$/, // Matches trailing spaces
	  ];
	
	  let trimmedText = fieldText;
	  for (const pattern of patterns) {
		trimmedText = trimmedText.replace(pattern, '');
	  }
	
	  return trimmedText;
}

/**
 * Extract City State and Zip
 */
export const extractCityStateZip = (cityStateZipString) => {
	const empty = {
		city: "",
		state: "",
		zip: "",
	};
    if (!cityStateZipString) return empty;
	if (!cityStateZipString.trim()) return empty;

    const regex = /(.*),\s*([A-Z]{2})\s*([0-9]{5}(?:-[0-9]{4})?)/;
    const match = cityStateZipString.match(regex);
    if (match) {
        const city = match[1] ? match[1].trim() : '';
        const state = match[2] ? match[2].trim() : '';
        const zip = match[3] ? match[3].trim() : '';

        return {
            city,
            state,
            zip,
        };
    } else {
        return {
            city: "",
            state: "",
            zip: "",
        };
    }
};

/**
 * Get firstName and lastName from name string
 */
export const getFirstNameLastName = (name) => {
	if (!name) return {
		firstName: '',
		lastName: ''
	}
	const names = name.split(' ');

	const firstName = names[0];
	let lastName = '';
	if (names.length > 1) lastName = names.slice(1).join(' ');

	return {
		firstName,
		lastName
	};
}

/**
 * If the address is in range, then return range1, range2, street
 * Else return null
 */
export const extractAddress = (text) => {
	const regex = /(\d+)-(\d+)\s+(.+)/;
	const matches = text.match(regex);

	if (matches) {
		const range1 = parseInt(matches[1]);
		const range2 = parseInt(matches[2]);
		const street = matches[3];

		return {
		range1: range1,
		range2: range2,
		street: street
		};
	} else {
		return null; // Return null if the text doesn't match the expected format
	}
}
