import * as actionTypes from '../actions/actionTypes';
import {
    updateObject
} from '../../shared/utility';
import { get } from 'lodash';

const initialState = {
    loading: false,

    error_upload: null,
    success_upload: null,

    error_download: null,
    success_download: null,

    error_data: null,
    success_data: null,

    file_uploaded: null,
    date_file_uploaded: null,
    files_data: []
}

// CREATE_FILE_UPLOAD

const createFileUploadStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error_upload: null,
        success_upload: null,
        file_uploaded: null,
        date_file_uploaded: null,
    })
}

const createFileUploadSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success_upload: 'Success',
        file_uploaded: get(action, 'response.file_data', null),
        date_file_uploaded: get(action, 'response.date_created', null),
    })
}

const createFileUploadFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error_upload: action.err
    })
}

// GET_FILE_UPLOAD

const getFileUploadStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error_download: null,
        success_download: null
    })
}

const getFileUploadSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success_download: 'Success'
    })
}

const getFileUploadFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error_download: action.err
    })
}

// GET_FILES_DATA

const getFilesDataStart = (state, action) => {
    return updateObject(state, {
        error_data: null,
        success_data: null
    })
}

const getFilesDataSuccess = (state, action) => {
    return updateObject(state, {
        success_data: 'Success',
        files_data: action.response
    })
}

const getFilesDataFail = (state, action) => {
    return updateObject(state, {
        error_data: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_FILE_UPLOAD_START:
            return createFileUploadStart(state, action);
        case actionTypes.CREATE_FILE_UPLOAD_SUCCESS:
            return createFileUploadSuccess(state, action);
        case actionTypes.CREATE_FILE_UPLOAD_FAIL:
            return createFileUploadFail(state, action);

        case actionTypes.GET_FILE_UPLOAD_START:
            return getFileUploadStart(state, action);
        case actionTypes.GET_FILE_UPLOAD_SUCCESS:
            return getFileUploadSuccess(state, action);
        case actionTypes.GET_FILE_UPLOAD_FAIL:
            return getFileUploadFail(state, action);

        case actionTypes.GET_FILES_DATA_START:
            return getFilesDataStart(state, action);
        case actionTypes.GET_FILES_DATA_SUCCESS:
            return getFilesDataSuccess(state, action);
        case actionTypes.GET_FILES_DATA_FAIL:
            return getFilesDataFail(state, action);

        default:
            return state;
    }
};

export default reducer;