import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import ReactDOM from "react-dom";
import checkPermissions from '../../shared/checkPermissions';

import withStyles from "@material-ui/core/styles/withStyles";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
// @material-ui/icons
import Check from '@material-ui/icons/Check';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import People from "@material-ui/icons/People";
import SweetAlert from "react-bootstrap-sweetalert";

import UserList from "containers/UserPerms/UserList";
import UserPermissions from "containers/UserPerms/UserPermissions";
import UserBulkFilter from "containers/UserPerms/UserBulkFilter";
import UserPermsModal from "containers/UserPerms/UserPermsModal";
import { permsDict } from "shared/permsObj";
import { swapKeyValues, qs } from "shared/utility";

class PortalPermissions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      reload: false,
      currentUser: null,
      permObj: {},
      askRequest: null,
      askModal: false,
      activityFlag: false,
      forSaving: null,
      redAlert: '',

      bulkFilter: false,
      filters: null,
      filterObj: {},
      filteredUserList: [],

      bulk: null,
      bulkObj: {},
      removeObj: {},
      bulkModalStatus: false,
      userLoad: ''
    }
  }

  bulkAssign = (userList, perms, removes) => {
    this.props.bulkAssign(userList, perms, removes);
  }

  openBulkModal = () => {
    this.setState({
      bulkModalStatus: true
    });
  }

  closeBulkModal = () => {
    this.setState({
      bulkModalStatus: false
    });
  }

  chooseRemove = (removeObj) => {
    this.setState({ removeObj });
  }

  chooseBulk = (bulkObj) => {
    this.setState({ bulkObj });
  }

  chooseFilter = (filterObj) => {
    this.setState({ filterObj }, () => this.startFilter());
  }

  startFilter = () => {
    const permsList = Object.keys(this.state.filterObj);
    
    if (permsList.length > 0) {
      this.props.getUsersFiltered(permsList);
    }
  }

  setFilteredUserList = (filteredUserList) => {
    this.setState({ filteredUserList });
  }

  setRedAlert = (redAlert) => {
    this.setState({
      redAlert
    });
    setTimeout(() => {
      this.setState({
        redAlert: ''
      });
    }, 3000);
  }

  openAskModal = (askRequest) => {
    this.setState({
      askModal: true,
      askRequest
    });
  }

  closeAskModal = () => {
    this.setState({
      askModal: false
    });
  }

  getUserFinal = (currentUser) => {
    this.setState({
      permObj: {},
      currentUser,
      forSaving: null,
      activityFlag: false,
      bulkFilter: false
    });
    this.props.getUser(currentUser.id);
  }

  chooseUser = (currentUser) => {
    if (!this.state.activityFlag) {
      this.getUserFinal(currentUser);
    } else {
      // ask permission
      this.openAskModal(`change-user,${currentUser.id},${currentUser.name}`);
    }
  }

  choosePerm = (permObj) => {
    this.setState({
      permObj,
      activityFlag: true
    });
  }

  saveFinal = () => {
    this.props.saveUserPerms(this.state.forSaving);
    this.setState({
      activityFlag: false
    });
  }

  generateAdd = (arr, type) => {
    
    const dbDict = swapKeyValues(this.props.DBs);
    const elems = arr.map((a,i) => {
      const p = permsDict[a.perm_id];
      if (a.perm_id) {
        return <div key={`ga${i}`} className="green-text">{p.main} - {p.subgroup} - {p.name} {a.db_id ? dbDict[a.db_id] : ''}</div>
      } else {
        return null;
      }

    });
    if (arr.length > 0) {
      return (
        <div>
          <div><strong>{type}</strong></div>
          {elems}
        </div>
      )
    } else {
      return null;
    }
  }

  generateRemove= (arr, type) => {
    
    const dbDict = swapKeyValues(this.props.DBs);
    const arr2 = [];
    arr.forEach((id) => {
      this.props.userPermsOriginal.forEach((perm) => {
        if (perm.id === id) {
          arr2.push({perm_id: perm.perm_id, db_id: perm.db_id});
        }
      });
    });
    const elems = arr2.map((a,i) => {
      const p = permsDict[a.perm_id];
      if (a.perm_id) {
        return <div key={`ga${i}`} className="red-text">{p.main} - {p.subgroup} - {p.name} {a.db_id ? dbDict[a.db_id] : ''}</div>
      } else {
        return null;
      }

    });
    if (arr.length > 0) {
      return (
        <div>
          <div><strong>{type}</strong></div>
          {elems}
        </div>
      )
    } else {
      return null;
    }
  }

  save = () => {
    const permObj = this.state.permObj,
          currentUser = this.state.currentUser,
          original = this.props.userPermsOriginal;
    
    if (!original) {
      return;
    }
    // if (Object.keys(original).length === 0) {
    //   return;
    // }
    let newPermObj = {}, newOrigObj = {};
    Object.keys(permObj).forEach((po) => {
      if (permObj[po].length > 0) {
        if (permObj[po][0] !== null) {
          permObj[po].forEach((dbname) => {
            newPermObj[`${po}_${this.props.DBs[dbname]}`] = { perm_id:parseInt(po) , db_id:this.props.DBs[dbname] };
          });
        } else {
          newPermObj[`${po}_`] = { perm_id:parseInt(po) , db_id:null };
        }
      }
    });

    original.forEach(o => {
      const db = o.db_id ? o.db_id : '';
      const comp = `${o.perm_id}_${db}`;
      newOrigObj[comp] = { perm_id:parseInt(o.perm_id), db_id:db ? db : null};
    });
    let deleted = [];
    let add = [];
    let stay = [];
    original.forEach((o) => {
      const db = o.db_id ? o.db_id : '';
      const comp = `${o.perm_id}_${db}`;
      if (!newPermObj.hasOwnProperty(comp)) {
        deleted.push(o);
      }
    });
    Object.keys(newPermObj).forEach((npk) => {
      if (!newOrigObj.hasOwnProperty(npk)) {
        add.push(newPermObj[npk]);
      }
    });
    
    if (deleted.length > 0 || add.length > 0) {
      const forSaving = {
        user_id: currentUser.id,
        user_perms: {
          add,
          remove: deleted.map(d => d.id)
        }
      };
      this.setState({
        forSaving
      }, () => this.openAskModal('save-review'));
    } else {
      // notify user that no changes had been made
      this.setRedAlert('No Changes have been made, Nothing to Save');
    }
  }

  setBulkFilter = () => {
    this.setState({
      bulkFilter: !this.state.bulkFilter
    })
  }

  startBulk = () => {
    
    //this.openAskModal('start-bulk');
    this.openBulkModal();
  }

  bulkFinal = () => {
    
  }

  reload = () => {
    this.setState({
      reload: false,
      filters: null,
      filterObj: {},
      filteredUserList: [],
      bulk: null,
      bulkObj: {},
      removeObj: {},
    }, () => {
      setTimeout(() => {
        this.setState({ reload: true });
      }, 300);
    });
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    
    let params = qs(this.props.location.search);
    
    let userLoad = '';
    if (params.q) {
      userLoad = params.q;
    }
    this.setState({
      reload: true,
      userLoad
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userPerms != this.props.userPerms) {
      this.setState({
        permObj: this.props.userPerms
      });
    }

    if (prevProps.userList != this.props.userList) {
      if (this.state.userLoad) {
        
        const userToLoad = this.state.userLoad.replace('.', ' ').toLowerCase();
        const user = this.props.userList.find(u => u.name.toLowerCase() === userToLoad);
        if (user) {
          
          this.chooseUser(user);
        }
      }
    }
  }

  render() {
    if (!checkPermissions('UserPerms')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />
    
    return (
  <div>
    {this.state.reload && <Card>
      {this.state.bulkModalStatus && <UserPermsModal
                                      open={this.state.bulkModalStatus}
                                      close={this.closeBulkModal}
                                      reload={this.reload}
                                      userList={this.state.filteredUserList.length > 0 ? this.state.filteredUserList : this.props.userListO }
                                      bulkObj={this.state.bulkObj}
                                      removeObj={this.state.removeObj}
                                      DBs={this.props.DBs}
                                      bulkAssign={this.bulkAssign}
                                    />}
      {
        this.state.askModal &&
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
          cancelBtnCssClass={
            this.props.classes.button + " " + this.props.classes.info
          }
          title="Are you sure?"
          onConfirm={() => {
            if (this.state.askRequest.indexOf('change-user') !== -1) {
              const askRequest = this.state.askRequest.split(',');
              const currentUser = {
                id: askRequest[1],
                name: askRequest[2]
              }
              this.getUserFinal(currentUser);
            }
            if (this.state.askRequest.indexOf('save-review') !== -1) {
              this.saveFinal();
            }
            if (this.state.askRequest.indexOf('start-bulk') !== -1) {
              this.bulkFinal();
            }
            this.closeAskModal();
          }}
          onCancel={this.closeAskModal}
          >
          {this.state.askRequest.indexOf('change-user') !== -1 && <div>Do you want to change User? All changes will be lost if you don't SAVE.</div>}
          {this.state.askRequest.indexOf('start-bulk') !== -1 && <div>Do you want to Start Bulk Assignment to the Filtered Users?</div>}
          {this.state.askRequest.indexOf('save-review') !== -1 && <div>Do you want to Save this User's Permissions?</div>}
          {this.state.askRequest.indexOf('save-review') !== -1 && <div style={{height:'200px',overflowY:'scroll'}}>
              {this.generateAdd(this.state.forSaving.user_perms.add, 'Added Permissions')} {this.generateRemove(this.state.forSaving.user_perms.remove, 'Remove Permissions')}
          </div>}
        </SweetAlert>
      }
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <span className="relative">
                <span className="icon-blue icon-badge"><People /></span>
                <div className="badge-text"><h4>USER PERMISSIONS</h4></div>
              </span>
              <div style={{marginTop:'10px'}}>
                <UserList
                  chooseUser={this.chooseUser}
                  currentUser={this.state.currentUser}
                  filterObj={this.state.filterObj}
                  bulkObj={this.state.bulkObj}
                  setFilteredUserList={this.setFilteredUserList}
                />
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <div>
                <Button
                  color={this.state.bulkFilter ? 'white' : 'rose'}
                  onClick={this.setBulkFilter}
                >USER SPECIFIC PERMISSIONS</Button>
                {/* <Button
                  color={this.state.bulkFilter ? 'rose' : 'white'}
                  onClick={this.setBulkFilter}
                >BULK/FILTER</Button> */}
              </div>
              {!this.state.bulkFilter && <UserPermissions
                currentUser={this.state.currentUser}
                choosePerm={this.choosePerm}
                permObj={this.state.permObj}
                bulkFilter={this.state.bulkFilter}
                save={this.save}
              />}
              {this.state.bulkFilter && <UserBulkFilter
                currentUser={this.state.currentUser}
                chooseFilter={this.chooseFilter}
                chooseBulk={this.chooseBulk}
                chooseRemove={this.chooseRemove}
                removeObj={this.state.removeObj}
                filterObj={this.state.filterObj}
                bulkObj={this.state.bulkObj}
                bulkFilter={this.state.bulkFilter}
                startFilter={this.startFilter}
                startBulk={this.startBulk}
              />}
              {this.state.redAlert && <div style={{textAlign:'center',marginTop:'20px'}} className="red-text">{this.state.redAlert}</div>}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>}
    </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userList: state.userPerms.userList,
    userPerms: state.userPerms.userPerms,
    userPermsOriginal: state.userPerms.userPermsOriginal,
    DBs: state.userPerms.DBs,
    userBulkFilterPerms: state.userPerms.userBulkFilterPerms,
    userListO: state.userPerms.userList,
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    saveUserPerms: (obj) => dispatch(actions.saveUserPerms(obj)),
    getUser: user_id => dispatch(actions.getUser(user_id)),
    chooseNewPerms: (newPerms) => dispatch(actions.chooseNewPerms(newPerms)),
    getUsersFiltered: permsList => dispatch(actions.getUsersFiltered(permsList)),
    bulkAssign: (userList, perms, removes) => dispatch(actions.bulkAssign(userList, perms, removes)),
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sweetAlertStyle)(PortalPermissions));
