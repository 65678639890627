import React from "react";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { clone, get } from "lodash";

import Button from "components/CustomButtons/Button";
import CustomDialogWithInput from "components/CustomDialog/CustomDialogWithInput";

import InvoiceElement from "./InvoiceElement";
import InvoiceFormWizard from "./InvoiceFormWizard";
import InvoiceFullForm from "./InvoiceFullForm";
import { invoiceFields } from "./InvoiceModel";
import { defaultData, generateInvoiceHTML, INVOICE_STATUS } from "./InvoiceObj";
import InvoicePdfModal from "./InvoicePdfModal";
import { getUser } from "../../shared/authValidation";

const InvoiceForm = (props) => {
    const {
        action,
        closeForm,
        createInvoiceEntry,
        updateInvoiceEntry,
        getInvoicePdf,
        parentData,
        pdfData,
        getRsheet,
        rsheetData,
        clearRSheets,
        getInvoiceEntries,
        listStatus,
        clearInvoicePdfData,
        success,
        error
    } = props;

    const [tab, setTab] = useState("wizard");

    const [openedDataModal, setOpenedDataModal] = useState(false);
    const [fieldToEdit, setFieldToEdit] = useState(null);

    const [formData, setFormData] = useState(defaultData);
    const [routingSheet, setRoutingSheet] = useState(null);

    const [openedPdfView, setOpenedPdfView] = useState(false);
    const [localPdfData, setLocalPdfData] = useState(null);

    const [isUpdating, setIsUpdating] = useState(false);

    const user = getUser();
    const userInvoicePerms = Object.keys(user.permObj).filter(up => {
        if (up.indexOf('Invoice ') !== -1) {
            return true;
        }
    }).map(u => {
        const arr = u.split('Invoice ');
        if (arr[1]) {
            return arr[1];
        }
    });

    const openPdfView = () => setOpenedPdfView(true);
    const closePdfView = () => setOpenedPdfView(false);

    const handleOpenPdfView = (data) => {
        console.log('generating invoice html', data);
        const paymentScheduleIndex = get(data, 'payment_schedule_data.index', null);
        const html = generateInvoiceHTML(data, '', paymentScheduleIndex);
        getInvoicePdf({ htmlTemplate: html });
        openPdfView(true);
    };

    const rsId = get(routingSheet, "id", null);

    useEffect(() => {
        return () => {
            clearRSheets()
            getInvoiceEntries(listStatus);
        }
    }, [])

    useEffect(() => {
        if (pdfData && typeof pdfData === "string") {
            const parsedPdf = JSON.parse(pdfData);
            setLocalPdfData(parsedPdf);
            clearInvoicePdfData();
        }
    }, [pdfData]);

    useEffect(() => {
        if (action === 'Add') {
            if (rsId) {
                getRsheet(rsId);
            }
        }
        if (action === 'Edit') {
            if (rsId) {
                getRsheet(rsId);
            } else if (parentData && parentData.rs_id) {
                getRsheet(parentData.rs_id);
            }
        }
    }, [rsId, parentData]);

    useEffect(() => {
        if (rsheetData) {

            // NOTE : This is resetting the invoice data - seems to be only suited for New Invoices
            // ------
            // const newInvoiceForm = clone(formData);
            // const rsData = get(rsheetData, 'json_data', {});
            // newInvoiceForm.rsData = rsData;
            // newInvoiceForm.title = `Generated from RS illi# ${get(
            //     rsData,
            //     "illi_number",
            //     ""
            // )}`;

            // if (rsheetData.title) {
            //     const extraTitle = ` - Rev ${rsheetData.title.split('_').length}`
            //     newInvoiceForm.title = newInvoiceForm.title + extraTitle
            // }

            // newInvoiceForm.illi = get(rsData, "illi_number", "").toString();
            // newInvoiceForm.rs_id = get(rsheetData, 'id');

            setRoutingSheet(rsheetData)
        }
    }, [rsheetData]);

    const [allowToSubmit, setAllowToSubmit] = useState(false);

    const canViewPdf = parentData && parentData.status !== INVOICE_STATUS.DRAFT;

    useEffect(() => {
        if (parentData && action !== "Add") {
            const newFormData = clone(parentData.data);
            newFormData.rsType = rsheetData ? rsheetData.type : null;
            setFormData(newFormData);
        }
    }, [parentData, rsheetData]);

    const openDataModal = (field) => {
        setOpenedDataModal(true);
        setFieldToEdit(field);
    };
    const closeDataModal = () => {
        setOpenedDataModal(false);
        setFieldToEdit(null);
    };

    const getTabIndex = () => {
        if (tab === "wizard") return 0;
        if (tab === "full") return 1;
    };

    const updateDataObj = (field, val) => {
        const newData = clone(formData);
        newData[field] = val;
        setFormData(newData);
    };

    const handleSubmit = (status) => {
        let s = status;
        if (!allowToSubmit) {
            s = 0;
        }
        createInvoiceEntry({
            status: s,
            data: formData,
        });
    };

    const handleUpdate = (changes) => {
        const newData = { ...clone(parentData), ...changes };
        console.log('newData ?', newData);
        updateInvoiceEntry(newData);
    };

    useEffect(() => {
        if (isUpdating && success === 'Successfully updated invoice') {
            closeForm();
            setIsUpdating(false);
        }
    }, [success]);

    console.log('InvoiceForm', props, formData, userInvoicePerms);

    return (
        <div>
            <h2>
                {action === "Edit" ? (
                    <div>
                        Edit Invoice <strong>{parentData.title}</strong>
                    </div>
                ) : (
                    "Add Invoice"
                )}
            </h2>

            {props.action !== "View" && (
                <div>
                    <Tabs
                        TabIndicatorProps={{
                            style: {
                                background: "#da3b2f",
                                borderBottom: "1px solid #da3b2f",
                                color: "#da3b2f",
                            },
                        }}
                        style={{ marginBottom: 10 }}
                        value={getTabIndex()}
                    >
                        {
                            <Tab
                                onClick={() => {
                                    setTab("wizard");
                                }}
                                style={{
                                    color:
                                        getTabIndex() === 0
                                            ? "#da3b2f"
                                            : "rgba(0, 0, 0, 0.87)",
                                }}
                                tabIndex={0}
                                label="Invoice Wizard"
                                icon={<i className="fas fa-magic font-22"></i>}
                            />
                        }
                        {
                            <Tab
                                onClick={() => {
                                    setTab("full");
                                }}
                                style={{
                                    color:
                                        getTabIndex() === 1
                                            ? "#da3b2f"
                                            : "rgba(0, 0, 0, 0.87)",
                                }}
                                tabIndex={1}
                                label="Full Form"
                                icon={
                                    <i className="fas fa-newspaper font-22"></i>
                                }
                            />
                        }
                    </Tabs>
                </div>
            )}
            {tab === "wizard" && (
                <InvoiceFormWizard
                    action={action}
                    fieldModels={invoiceFields}
                    formData={formData}
                    setAllowToSubmit={setAllowToSubmit}
                    setFormData={setFormData}
                    setTab={setTab}
                    updateDataObj={updateDataObj}
                    routingSheet={routingSheet}
                    setRoutingSheet={setRoutingSheet}
                    closeForm={closeForm}
                    permissions={props.permissions}
                    rsheetData={props.rsheetData}
                />
            )}
            {tab === "full" && (
                <InvoiceFullForm
                    fieldModels={invoiceFields}
                    formData={formData}
                    openDataModal={openDataModal}
                    closeForm={closeForm}
                    permissions={props.permissions}
                    parentData={props.parentData}
                    invoicePayments={props.invoicePayments}
                    rsheetData={props.rsheetData}
                />
            )}

            <div className="bottom-dweller">
                {(action === "Add" || props.action === "Edit") && (
                    <React.Fragment>
                        {/* <Button
                            color="white"
                            className="mr-20"
                            onClick={() => {
                                if (action === "Add") {
                                    handleSubmit(INVOICE_STATUS.DRAFT);
                                } else if (action === "Edit") {
                                    handleUpdate({
                                        data: formData,
                                    });
                                }
                                closeForm();
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <i className="fas fa-save"></i> Save Invoice Draft
                        </Button> */}
                        {userInvoicePerms.indexOf('approve') !== -1 && <Button
                            color="danger"
                            className="mr-20"
                            onClick={() => {
                                if (action === "Add") {
                                    handleSubmit(INVOICE_STATUS.APPROVED);
                                } else if (action === "Edit") {
                                    const updateObj = {
                                        status: INVOICE_STATUS.APPROVED,
                                        data: formData
                                    };
                                    handleUpdate(updateObj);
                                    setIsUpdating(true);
                                }
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <i className="fas fa-save"></i> Approve Invoice
                        </Button>}

                        {(userInvoicePerms.indexOf('edit') !== -1 && userInvoicePerms.indexOf('approve') === -1) && <Button
                            color="danger"
                            className="mr-20"
                            onClick={() => {
                                if (action === "Add") {
                                    handleSubmit(INVOICE_STATUS.PENDING);
                                } else if (action === "Edit") {
                                    const updateObj = {
                                        status: INVOICE_STATUS.PENDING,
                                        data: formData
                                    };
                                    handleUpdate(updateObj);
                                    setIsUpdating(true);
                                }
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <i className="fas fa-save"></i> Submit Invoice
                        </Button>}

                        <Button
                            color="white"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                handleOpenPdfView(formData)
                            }}
                        >
                            VIEW PDF
                        </Button>
                    </React.Fragment>
                )}
            </div>

            <CustomDialogWithInput
                element={InvoiceElement}
                open={openedDataModal}
                close={closeDataModal}
                fieldModels={invoiceFields}
                formData={formData}
                setFormData={setFormData}
                field={fieldToEdit}
                updateDataObj={updateDataObj}
            />

            {localPdfData && openedPdfView && (
                <InvoicePdfModal
                    pdfData={localPdfData}
                    invoice={formData}
                    open={true}
                    close={() => {
                        setLocalPdfData(null);
                        closePdfView();
                    }}
                />
            )}
        </div>
    );
};

export default InvoiceForm;
