import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { clone } from '../../shared/utility';

const initialState = {
	// Regular Query
	propertiesData: [],
	propertyData: null,

	// Names Only
	illi_properties: [],
	own_properties: [],
	own_shared_properties: [],

	leasesData: [],
	leaseData: null,
	loadingLease: false,

	listingsData: [],
	listingData: null,
	loadingListing: false,

	meetingsData: [],
	meetingData: null,
	loadingMeeting: false,

	offersData: [],
	offerData: null,
	loadingOffer: false,

	error: null,
	success: null,
	loading: false,
	propertyShares: [],
	propertyUsernames: [],
	propertyContacts: null,
	propertyNames: [],
	leases: [],
	subProperties: null,
	success_data: null,
	existingAddress: null,

	options: {
		use_type: [],
	},

	propertyChangesData: null,
	propertyChangesSuccess: null,
	propertyChangesError: null,
	propertyChangesLoading: false,

	import_error: null,
	import_success: null,
	import_data: null,

	propertyOwnersContacts: [],
	updated_property: null,

	error_send_to_marketing: null,
	success_send_to_marketing: null,
	loading_send_to_marketing: false,

	property_documents: null,
	property_documents_error: null,
	property_documents_success: null,
};

// GET PROPERTIES
const getPropertiesStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
		success: null,
	});
};

const getPropertiesSuccess = (state, action) => {
	if (action.response.illi_properties) {
		return updateObject(state, {
			loading: false,
			illi_properties: action.response.illi_properties,
			own_properties: action.response.own_properties,
			own_shared_properties: action.response.own_shared_properties,
		});
	}
	return updateObject(state, {
		loading: false,
		success: 'Successfully got properties',
		propertiesData: action.response,
	});
};

const getPropertiesFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

// GET PROPERTY
const getPropertyStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
		success: null,
	});
};

const getPropertySuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		success: 'Successfully got property',
		propertyData: action.response,
	});
};

const getPropertyFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

// GET_PROPERTY_CONTACTS

const getPropertyContactsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getPropertyContactsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Properties by Contact',
		propertyContacts: action.response,
	});
};

const getPropertyContactsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_PROPERTY_NAMES

const getPropertyNamesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getPropertyNamesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got property names',
		propertyNames: action.response,
	});
};

const getPropertyNamesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE PROPERTY
const createPropertyStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
		success: null,
		success_data: null,
	});
};

const createPropertySuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		success: 'Successfully added property',
		success_data: action.response,
	});
};

const createPropertyFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

// DELETE PROPERTY
const deletePropertyStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
		success: null,
	});
};

const deletePropertySuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		success: 'Successfully deleted property.',
	});
};

const deletePropertyFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

// UPDATE PROPERTY
const updatePropertyStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
		success: null,
		updated_property: null
	});
};

const updatePropertySuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		success: 'Successfully updated property',
		updated_property: action.response
	});
};

const updatePropertyFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

// GET PROPERTY SHARES
const getPropertySharesStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
	});
};

const getPropertySharesSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		propertyShares: action.response,
	});
};

const getPropertySharesFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

// CREATE_PROPERTY_SHARE

const createPropertyShareStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const createPropertyShareSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Created property share',
	});
};

const createPropertyShareFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// DELETE_PROPERTY_SHARE

const deletePropertyShareStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deletePropertyShareSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Deleted property share',
	});
};

const deletePropertyShareFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_LEASES

const getLeasesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true,
	});
};

const getLeasesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Leases',
		loading: false,
		leasesData: action.response,
	});
};

const getLeasesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false,
	});
};

// GET_LEASE

const getLeaseStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getLeaseSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Lease',
		leaseData: action.response,
	});
};

const getLeaseFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_LEASE

const createLeaseStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingLease: true
	});
};

const createLeaseSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Created Lease',
		loadingLease: false
	});
};

const createLeaseFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingLease: false
	});
};

// UPDATE_LEASE

const updateLeaseStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingLease: true
	});
};

const updateLeaseSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Updated lease',
		loadingLease: false
	});
};

const updateLeaseFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingLease: false
	});
};

// DELETE_LEASE

const deleteLeaseStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingLease: true
	});
};

const deleteLeaseSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Deleted Lease',
		loadingLease: false
	});
};

const deleteLeaseFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingLease: false
	});
};

// GET_LISTINGS

const getListingsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true,
	});
};

const getListingsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Listings',
		loading: false,
		listingsData: action.response ? action.response : [],
	});
};

const getListingsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false,
	});
};

// GET_LISTING

const getListingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getListingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got listing',
		listingData: action.response,
	});
};

const getListingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_LISTING

const createListingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingListing: true
	});
};

const createListingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Created Listing',
		loadingListing: false
	});
};

const createListingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingListing: false
	});
};

// UPDATE_LISTING

const updateListingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingListing: true
	});
};

const updateListingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Updated Listing',
		loadingListing: false
	});
};

const updateListingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingListing: false
	});
};

// DELETE_LISTING

const deleteListingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingListing: true
	});
};

const deleteListingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Deleted Listing',
		loadingListing: false
	});
};

const deleteListingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingListing: false
	});
};

// GET_MEETINGS

const getMeetingsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true,
	});
};

const getMeetingsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Meetings',
		loading: false,
		meetingsData: action.response,
	});
};

const getMeetingsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false,
	});
};

// GET_MEETING

const getMeetingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getMeetingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Meeting',
		meetingData: action.response,
	});
};

const getMeetingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_MEETING

const createMeetingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingMeeting: true
	});
};

const createMeetingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Created Meeting',
		loadingMeeting: false
	});
};

const createMeetingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingMeeting: false
	});
};

// UPDATE_MEETING

const updateMeetingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingMeeting: true
	});
};

const updateMeetingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Updated Meeting',
		loadingMeeting: false
	});
};

const updateMeetingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingMeeting: false
	});
};

// DELETE_MEETING

const deleteMeetingStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingMeeting: true
	});
};

const deleteMeetingSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Deleted Meeting',
		loadingMeeting: false
	});
};

const deleteMeetingFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingMeeting: false
	});
};

// GET_OFFERS

const getOffersStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true,
	});
};

const getOffersSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Offers',
		loading: false,
		offersData: action.response,
	});
};

const getOffersFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false,
	});
};

// GET_OFFER

const getOfferStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getOfferSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Offer',
		offerData: action.response,
	});
};

const getOfferFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_OFFER

const createOfferStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingOffer: true
	});
};

const createOfferSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Created Offer',
		loadingOffer: false
	});
};

const createOfferFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingOffer: false
	});
};

// UPDATE_OFFER

const updateOfferStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingOffer: true
	});
};

const updateOfferSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Updated Offer',
		loadingOffer: false
	});
};

const updateOfferFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingOffer: false
	});
};

// DELETE_OFFER

const deleteOfferStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingOffer: true
	});
};

const deleteOfferSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Deleted Offer',
		loadingOffer: false
	});
};

const deleteOfferFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingOffer: false
	});
};

// GET_SUB_PROPERTIES

const getSubPropertiesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		subProperties: [],
		loading: true,
	});
};

const getSubPropertiesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got sub properties',
		subProperties: action.response ? action.response.sort((a, b) => (a.id < b.id ? 1 : -1)) : [],
		loading: false,
	});
};

const getSubPropertiesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false,
	});
};

const checkPropertyExistingAddressStart = (state, action) => {
	return updateObject(state, {
		existingAddress: null,
	});
};

const checkPropertyExistingAddressSuccess = (state, action) => {
	return updateObject(state, {
		existingAddress: null,
	});
};

const checkPropertyExistingAddressFail = (state, action) => {
	return updateObject(state, {
		existingAddress: action.err,
	});
};

// OPTIONS

const getOptionsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getOptionsSuccess = (state, action) => {
	return updateObject(state, {
		options: action.response,
	});
};

const getOptionsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CLEAR MESSAGES

const clearCRMMessages = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

// GET_PROPERTY_CHANGES

const getPropertyChangesStart = (state, action) => {
    return updateObject(state, {
        propertyChangesError: null,
        propertyChangesSuccess: null,
		propertyChangesLoading: true,
    })
}

const getPropertyChangesSuccess = (state, action) => {
    return updateObject(state, {
        propertyChangesSuccess: 'Success',
		propertyChangesData: action.response,
		propertyChangesLoading: false,
    })
}

const getPropertyChangesFail = (state, action) => {
    return updateObject(state, {
        propertyChangesError: action.err,
		propertyChangesLoading: false,
    })
}

// SAVE_PROPERTY_CHANGES

const savePropertyChangesStart = (state, action) => {
    return updateObject(state, {
        propertyChangesError: null,
        propertyChangesSuccess: null
    })
}

const savePropertyChangesSuccess = (state, action) => {
    return updateObject(state, {
        propertyChangesSuccess: 'Successfully saves property changes',
    })
}

const savePropertyChangesFail = (state, action) => {
    return updateObject(state, {
        propertyChangesError: action.err,
    })
}

const setPropertyChanges = (state, action) => {
    return updateObject(state, {
		propertyChangesData: action.data
    })
}

// IMPORT_PROPERTIES

const importPropertiesStart = (state, action) => {
    return updateObject(state, {
        import_error: null,
       	import_success: null,
		import_data: null
    })
}

const importPropertiesSuccess = (state, action) => {
    return updateObject(state, {
        import_success: 'Success',
		import_data: action.response
    })
}

const importPropertiesFail = (state, action) => {
    return updateObject(state, {
        import_error: action.err
    })
}

const setImportProperties = (state, action) => {
	return updateObject(state, {
		import_data: action.new_import_data
	})
}

// GET_PROPERTY_OWNERS_CONTACTS

const getPropertyOwnersContactsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getPropertyOwnersContactsSuccess = (state, action) => {
	let propertyOwnersContacts = action.response;
    return updateObject(state, {
        success: 'Success',
		propertyOwnersContacts,
    })
}

const getPropertyOwnersContactsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// IMPORT_COSTAR_PROPERTIES

const importCostarPropertiesStart = (state, action) => {
    return updateObject(state, {
        import_error: null,
       	import_success: null,
		import_data: null
    })
}

const importCostarPropertiesSuccess = (state, action) => {
    return updateObject(state, {
        import_success: 'Success',
		import_data: action.response
    })
}

const importCostarPropertiesFail = (state, action) => {
    return updateObject(state, {
        import_error: action.err
    })
}

// CREATE_SEND_TO_MARKETING

const createSendToMarketingStart = (state, action) => {
    return updateObject(state, {
        error_send_to_marketing: null,
        success_send_to_marketing: null,
		loading_send_to_marketing: true
    })
}

const createSendToMarketingSuccess = (state, action) => {
    return updateObject(state, {
        success_send_to_marketing: 'Success',
		loading_send_to_marketing: false
    })
}

const createSendToMarketingFail = (state, action) => {
    return updateObject(state, {
        error_send_to_marketing: action.err,
		loading_send_to_marketing: false
    })
}

// GET_PROPERTY_DOCUMENTS

const getPropertyDocumentsStart = (state, action) => {
    return updateObject(state, {
        property_documents_error: null,
        property_documents_success: null,
		property_documents: null
    })
}

const getPropertyDocumentsSuccess = (state, action) => {
    return updateObject(state, {
        property_documents_success: 'Success',
		property_documents: action.response
    })
}

const getPropertyDocumentsFail = (state, action) => {
    return updateObject(state, {
        property_documents_error: action.err
    })
}

// CHECK_ILLI_NUMBER_FOR_PROPERTY

const checkIlliNumberForPropertyStart = (state, action) => {
    return updateObject(state, {
        error_illi_number_check: null,
        success_illi_number_check: null
    })
}

const checkIlliNumberForPropertySuccess = (state, action) => {
    return updateObject(state, {
        success_illi_number_check: 'Success'
    })
}

const checkIlliNumberForPropertyFail = (state, action) => {
    return updateObject(state, {
        error_illi_number_check: action.err,
    })
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROPERTIES_START:
			return getPropertiesStart(state, action);
		case actionTypes.GET_PROPERTIES_SUCCESS:
			return getPropertiesSuccess(state, action);
		case actionTypes.GET_PROPERTIES_FAIL:
			return getPropertiesFail(state, action);

		case actionTypes.GET_PROPERTY_START:
			return getPropertyStart(state, action);
		case actionTypes.GET_PROPERTY_SUCCESS:
			return getPropertySuccess(state, action);
		case actionTypes.GET_PROPERTY_FAIL:
			return getPropertyFail(state, action);

		case actionTypes.GET_PROPERTY_CONTACTS_START:
			return getPropertyContactsStart(state, action);
		case actionTypes.GET_PROPERTY_CONTACTS_SUCCESS:
			return getPropertyContactsSuccess(state, action);
		case actionTypes.GET_PROPERTY_CONTACTS_FAIL:
			return getPropertyContactsFail(state, action);

		case actionTypes.GET_PROPERTY_NAMES_START:
			return getPropertyNamesStart(state, action);
		case actionTypes.GET_PROPERTY_NAMES_SUCCESS:
			return getPropertyNamesSuccess(state, action);
		case actionTypes.GET_PROPERTY_NAMES_FAIL:
			return getPropertyNamesFail(state, action);

		case actionTypes.CREATE_PROPERTY_START:
			return createPropertyStart(state, action);
		case actionTypes.CREATE_PROPERTY_SUCCESS:
			return createPropertySuccess(state, action);
		case actionTypes.CREATE_PROPERTY_FAIL:
			return createPropertyFail(state, action);

		case actionTypes.DELETE_PROPERTY_START:
			return deletePropertyStart(state, action);
		case actionTypes.DELETE_PROPERTY_SUCCESS:
			return deletePropertySuccess(state, action);
		case actionTypes.DELETE_PROPERTY_FAIL:
			return deletePropertyFail(state, action);

		case actionTypes.UPDATE_PROPERTY_START:
			return updatePropertyStart(state, action);
		case actionTypes.UPDATE_PROPERTY_SUCCESS:
			return updatePropertySuccess(state, action);
		case actionTypes.UPDATE_PROPERTY_FAIL:
			return updatePropertyFail(state, action);

		case actionTypes.GET_PROPERTY_SHARES_START:
			return getPropertySharesStart(state, action);
		case actionTypes.GET_PROPERTY_SHARES_SUCCESS:
			return getPropertySharesSuccess(state, action);
		case actionTypes.GET_PROPERTY_SHARES_FAIL:
			return getPropertySharesFail(state, action);

		case actionTypes.CREATE_PROPERTY_SHARE_START:
			return createPropertyShareStart(state, action);
		case actionTypes.CREATE_PROPERTY_SHARE_SUCCESS:
			return createPropertyShareSuccess(state, action);
		case actionTypes.CREATE_PROPERTY_SHARE_FAIL:
			return createPropertyShareFail(state, action);

		case actionTypes.DELETE_PROPERTY_SHARE_START:
			return deletePropertyShareStart(state, action);
		case actionTypes.DELETE_PROPERTY_SHARE_SUCCESS:
			return deletePropertyShareSuccess(state, action);
		case actionTypes.DELETE_PROPERTY_SHARE_FAIL:
			return deletePropertyShareFail(state, action);

		case actionTypes.GET_LEASES_START:
			return getLeasesStart(state, action);
		case actionTypes.GET_LEASES_SUCCESS:
			return getLeasesSuccess(state, action);
		case actionTypes.GET_LEASES_FAIL:
			return getLeasesFail(state, action);

		case actionTypes.GET_LEASE_START:
			return getLeaseStart(state, action);
		case actionTypes.GET_LEASE_SUCCESS:
			return getLeaseSuccess(state, action);
		case actionTypes.GET_LEASE_FAIL:
			return getLeaseFail(state, action);

		case actionTypes.CREATE_LEASE_START:
			return createLeaseStart(state, action);
		case actionTypes.CREATE_LEASE_SUCCESS:
			return createLeaseSuccess(state, action);
		case actionTypes.CREATE_LEASE_FAIL:
			return createLeaseFail(state, action);

		case actionTypes.UPDATE_LEASE_START:
			return updateLeaseStart(state, action);
		case actionTypes.UPDATE_LEASE_SUCCESS:
			return updateLeaseSuccess(state, action);
		case actionTypes.UPDATE_LEASE_FAIL:
			return updateLeaseFail(state, action);

		case actionTypes.DELETE_LEASE_START:
			return deleteLeaseStart(state, action);
		case actionTypes.DELETE_LEASE_SUCCESS:
			return deleteLeaseSuccess(state, action);
		case actionTypes.DELETE_LEASE_FAIL:
			return deleteLeaseFail(state, action);

		case actionTypes.GET_LISTINGS_START:
			return getListingsStart(state, action);
		case actionTypes.GET_LISTINGS_SUCCESS:
			return getListingsSuccess(state, action);
		case actionTypes.GET_LISTINGS_FAIL:
			return getListingsFail(state, action);

		case actionTypes.GET_LISTING_START:
			return getListingStart(state, action);
		case actionTypes.GET_LISTING_SUCCESS:
			return getListingSuccess(state, action);
		case actionTypes.GET_LISTING_FAIL:
			return getListingFail(state, action);

		case actionTypes.CREATE_LISTING_START:
			return createListingStart(state, action);
		case actionTypes.CREATE_LISTING_SUCCESS:
			return createListingSuccess(state, action);
		case actionTypes.CREATE_LISTING_FAIL:
			return createListingFail(state, action);

		case actionTypes.UPDATE_LISTING_START:
			return updateListingStart(state, action);
		case actionTypes.UPDATE_LISTING_SUCCESS:
			return updateListingSuccess(state, action);
		case actionTypes.UPDATE_LISTING_FAIL:
			return updateListingFail(state, action);

		case actionTypes.DELETE_LISTING_START:
			return deleteListingStart(state, action);
		case actionTypes.DELETE_LISTING_SUCCESS:
			return deleteListingSuccess(state, action);
		case actionTypes.DELETE_LISTING_FAIL:
			return deleteListingFail(state, action);

		case actionTypes.GET_MEETINGS_START:
			return getMeetingsStart(state, action);
		case actionTypes.GET_MEETINGS_SUCCESS:
			return getMeetingsSuccess(state, action);
		case actionTypes.GET_MEETINGS_FAIL:
			return getMeetingsFail(state, action);

		case actionTypes.GET_MEETING_START:
			return getMeetingStart(state, action);
		case actionTypes.GET_MEETING_SUCCESS:
			return getMeetingSuccess(state, action);
		case actionTypes.GET_MEETING_FAIL:
			return getMeetingFail(state, action);

		case actionTypes.CREATE_MEETING_START:
			return createMeetingStart(state, action);
		case actionTypes.CREATE_MEETING_SUCCESS:
			return createMeetingSuccess(state, action);
		case actionTypes.CREATE_MEETING_FAIL:
			return createMeetingFail(state, action);

		case actionTypes.UPDATE_MEETING_START:
			return updateMeetingStart(state, action);
		case actionTypes.UPDATE_MEETING_SUCCESS:
			return updateMeetingSuccess(state, action);
		case actionTypes.UPDATE_MEETING_FAIL:
			return updateMeetingFail(state, action);

		case actionTypes.DELETE_MEETING_START:
			return deleteMeetingStart(state, action);
		case actionTypes.DELETE_MEETING_SUCCESS:
			return deleteMeetingSuccess(state, action);
		case actionTypes.DELETE_MEETING_FAIL:
			return deleteMeetingFail(state, action);

		case actionTypes.GET_OFFERS_START:
			return getOffersStart(state, action);
		case actionTypes.GET_OFFERS_SUCCESS:
			return getOffersSuccess(state, action);
		case actionTypes.GET_OFFERS_FAIL:
			return getOffersFail(state, action);

		case actionTypes.GET_OFFER_START:
			return getOfferStart(state, action);
		case actionTypes.GET_OFFER_SUCCESS:
			return getOfferSuccess(state, action);
		case actionTypes.GET_OFFER_FAIL:
			return getOfferFail(state, action);

		case actionTypes.CREATE_OFFER_START:
			return createOfferStart(state, action);
		case actionTypes.CREATE_OFFER_SUCCESS:
			return createOfferSuccess(state, action);
		case actionTypes.CREATE_OFFER_FAIL:
			return createOfferFail(state, action);

		case actionTypes.UPDATE_OFFER_START:
			return updateOfferStart(state, action);
		case actionTypes.UPDATE_OFFER_SUCCESS:
			return updateOfferSuccess(state, action);
		case actionTypes.UPDATE_OFFER_FAIL:
			return updateOfferFail(state, action);

		case actionTypes.DELETE_OFFER_START:
			return deleteOfferStart(state, action);
		case actionTypes.DELETE_OFFER_SUCCESS:
			return deleteOfferSuccess(state, action);
		case actionTypes.DELETE_OFFER_FAIL:
			return deleteOfferFail(state, action);

		case actionTypes.GET_SUB_PROPERTIES_START:
			return getSubPropertiesStart(state, action);
		case actionTypes.GET_SUB_PROPERTIES_SUCCESS:
			return getSubPropertiesSuccess(state, action);
		case actionTypes.GET_SUB_PROPERTIES_FAIL:
			return getSubPropertiesFail(state, action);

		case actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS_START:
			return checkPropertyExistingAddressStart(state, action);
		case actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS_SUCCESS:
			return checkPropertyExistingAddressSuccess(state, action);
		case actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS_FAIL:
			return checkPropertyExistingAddressFail(state, action);

		case actionTypes.GET_OPTIONS_START:
			return getOptionsStart(state, action);
		case actionTypes.GET_OPTIONS_SUCCESS:
			return getOptionsSuccess(state, action);
		case actionTypes.GET_OPTIONS_FAIL:
			return getOptionsFail(state, action);

		case actionTypes.CLEAR_PROPERTY_CRM_MESSAGES:
			return clearCRMMessages(state, action);

		case actionTypes.GET_PROPERTY_CHANGES_START:
			return getPropertyChangesStart(state, action);
		case actionTypes.GET_PROPERTY_CHANGES_SUCCESS:
			return getPropertyChangesSuccess(state, action);
		case actionTypes.GET_PROPERTY_CHANGES_FAIL:
			return getPropertyChangesFail(state, action);

		case actionTypes.SAVE_PROPERTY_CHANGES_START:
			return savePropertyChangesStart(state, action);
		case actionTypes.SAVE_PROPERTY_CHANGES_SUCCESS:
			return savePropertyChangesSuccess(state, action);
		case actionTypes.SAVE_PROPERTY_CHANGES_FAIL:
			return savePropertyChangesFail(state, action);

		case actionTypes.SET_PROPERTY_CHANGES:
			return setPropertyChanges(state, action);
		case actionTypes.GET_PROPERTY_OWNERS_CONTACTS_START:
			return getPropertyOwnersContactsStart(state, action);
		case actionTypes.GET_PROPERTY_OWNERS_CONTACTS_SUCCESS:
			return getPropertyOwnersContactsSuccess(state, action);
		case actionTypes.GET_PROPERTY_OWNERS_CONTACTS_FAIL:
			return getPropertyOwnersContactsFail(state, action);

		case actionTypes.IMPORT_PROPERTIES_START:
			return importPropertiesStart(state, action);
		case actionTypes.IMPORT_PROPERTIES_SUCCESS:
			return importPropertiesSuccess(state, action);
		case actionTypes.IMPORT_PROPERTIES_FAIL:
			return importPropertiesFail(state, action);
		case actionTypes.SET_IMPORT_PROPERTIES:
			return setImportProperties(state, action);

		case actionTypes.IMPORT_COSTAR_PROPERTIES_START:
			return importCostarPropertiesStart(state, action);
		case actionTypes.IMPORT_COSTAR_PROPERTIES_SUCCESS:
			return importCostarPropertiesSuccess(state, action);
		case actionTypes.IMPORT_COSTAR_PROPERTIES_FAIL:
			return importCostarPropertiesFail(state, action);

		case actionTypes.CREATE_SEND_TO_MARKETING_START:
			return createSendToMarketingStart(state, action);
		case actionTypes.CREATE_SEND_TO_MARKETING_SUCCESS:
			return createSendToMarketingSuccess(state, action);
		case actionTypes.CREATE_SEND_TO_MARKETING_FAIL:
			return createSendToMarketingFail(state, action);

		case actionTypes.GET_PROPERTY_DOCUMENTS_START:
			return getPropertyDocumentsStart(state, action);
		case actionTypes.GET_PROPERTY_DOCUMENTS_SUCCESS:
			return getPropertyDocumentsSuccess(state, action);
		case actionTypes.GET_PROPERTY_DOCUMENTS_FAIL:
			return getPropertyDocumentsFail(state, action);

		case actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY_START:
			return checkIlliNumberForPropertyStart(state, action);
		case actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY_SUCCESS:
			return checkIlliNumberForPropertySuccess(state, action);
		case actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY_FAIL:
			return checkIlliNumberForPropertyFail(state, action);

		default:
			return state;
	}
};

export default reducer;
