import React, { useState, useEffect, useRef } from 'react';
import Close from '@material-ui/icons/Close';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { clone } from 'shared/utility';
import { getUsername } from 'shared/utility';
import { getUserID } from 'shared/authValidation';
import download from 'downloadjs';
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { IconButton, TextField } from '@material-ui/core';
import Notification from 'components/Notification/Notification';

const ContactW9Upload = (props) => {

    const { success, error, contactData, createCrmFile, downloadedFile,
        fileList, formDataObj, setFormDataObj, action, dataObj } = props;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [successStatus, setSuccessStatus] = useState(null);
    const [fileNames, setFileNames] = useState([]);
    const [errorFile, setErrorFile] = useState(null);
    const [downloadFileName, setDownloadFileName] = useState(null);
    const [w9Array, setW9Array] = useState([]);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSuccess = usePrevious(success);
    const prevError = usePrevious(error);

    useEffect(() => {
        if (success === 'Successfully uploaded document') {
            setSuccessStatus(success);
        }
        if (error === 'Failed to upload document') {
            setSuccessStatus('Failed to upload document');
        }
        if (success === 'Successfully deleted CRM file.') {
            let name = contactData.id + '-' + contactData.first_name + ' ' + contactData.last_name;
            props.getCrmFileList({ id: name, userId: getUserID() });
        }
    }, [success, error]);

    useEffect(() => {
        setDownloadFileName(null);
        if (contactData) {
            let name = contactData.id + '-' + contactData.first_name + ' ' + contactData.last_name;
            if (props.getCrmFileList) props.getCrmFileList({ id: name, userId: getUserID() });
        }
    }, [])

    useEffect(() => {
        if (downloadedFile && downloadedFile.data && downloadedFile.data.length && downloadFileName) {
            let fileBuffer = Buffer.from(props.downloadedFile, 'ArrayBuffer').toString('binary');
            download(fileBuffer, downloadFileName);
        }
    }, [downloadedFile])

    useEffect(() => {
        if (fileList && fileList.length) {
            let w9Array = fileList.filter(file => file.name.includes('W9'));
            setW9Array(w9Array);
            setFileNames([]);
        }
    }, [fileList])

    const cancel = () => {
        props.closeForm()
    }

    const handleMultipleFileChange = (e) => {
        setSelectedFiles([])
        const allowableFileExtensions = ['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg'];
        let files = e.target.files;
        let selectedArray = clone(selectedFiles);
        let namesArr = [];
        let formData = new FormData();

        Object.keys(files).forEach((key) => {
            let file = files[key];
            let extension = file.name.match(/(?:\.([^.]+))?$/);
            let originalName = file.name.replace(extension[0], '');
            if (allowableFileExtensions.includes(extension[0]) && (contactData || dataObj)) {
                const createdDate = new Date().toISOString().split('T')[0];
                let currentFileName = 'W9-' + createdDate + extension[0];
                namesArr.push(currentFileName);
                setFileNames(namesArr);
                let user = getUsername().replace(/ /g, '.');
                formData.append('User', user);
                if (contactData && contactData.first_name && contactData.last_name) {
                    formData.append('name', contactData.first_name + ' ' + contactData.last_name);
                }
                if (dataObj && dataObj.first_name && dataObj.last_name) {
                    formData.append('name', dataObj.first_name + ' ' + dataObj.last_name);
                }
                formData.append('Folder', 'Contacts');
                formData.append('SystemNumber', dataObj.id)
                formData.append('UserId', getUserID());
                formData.append('file', files[key], currentFileName);
                selectedArray.push(formData);
                setSelectedFiles(formData);
                let newFormDataObj = clone(formDataObj) || {};
                for (const key in newFormDataObj) {
                    if (key.substring(0, key.indexOf("-")) === currentFileName.substring(0, currentFileName.indexOf("-"))) {
                        delete newFormDataObj[key];
                    }
                }
                newFormDataObj[currentFileName] = formData;
                setFormDataObj(newFormDataObj)
            } else {
                document.getElementById('multi_docs').value = ''
                files = null;
                e.target.files = null;
                setSelectedFiles(null);
                setErrorFile(true);
            }
        });
        if (namesArr && namesArr.length && action === "Edit") {
            createCrmFile(formData)
        }
    }

    const closeAlert = () => {
        setSuccessStatus(null);
        setSelectedFiles([]);
        setErrorFile(null);
        if (contactData) {
            let name = contactData.id + '-' + contactData.first_name + ' ' + contactData.last_name;
            props.getCrmFileList({ id: name, userId: getUserID() });
        }
    }

    

    return (
        <div>
            {props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}

            <GridContainer spacing={0}>
                {action === 'Edit' &&
                    <GridItem xs={12} sm={12} md={12}>
                        <input type='file' name="file" id="multi_docs" onChange={handleMultipleFileChange}
                            accept="application/pdf, image/jpeg, image/png" multiple
                            style={{display:'none'}}
                        />
                        <label htmlFor="multi_docs">
                            <Button variant="raised" component="span" style={{marginRight:20}}>
                                Choose File
                            </Button>
                        </label>
                        {<div>
                            {fileNames.map((m,i) => {
                                return <div key={`file-${i}`}>{m}</div>
                            })}
                        </div>}
                    </GridItem>
                }
                {action === 'Add' &&
                    <GridItem xs={12} sm={12} md={12}>
                        <input type='file' name="file" id="multi_docs" onChange={handleMultipleFileChange}
                            accept="application/pdf, image/jpeg, image/png" multiple
                            style={{marginRight:20,display:'none'}}
                        />
                        <label htmlFor="multi_docs">
                            <Button variant="raised" component="span" style={{marginRight:20}}>
                                Choose File
                            </Button>
                        </label>
                    </GridItem>
                }
                {w9Array && !w9Array.length && !formDataObj &&
                    <GridItem>
                        <p><em>No files to display.</em></p>
                    </GridItem>
                }
                {w9Array && (w9Array.length > 0) &&
                    <GridItem container xs={6} sm={6} md={6} direction="column">
                        <h3>Uploaded Files</h3>
                        <List>
                            {
                                w9Array.map((file, index) => {
                                    if (file) {
                                        if (file.name) {
                                            if (file.name.indexOf('W9') === -1) return null;
                                        }
                                    }
                                    return (
                                        <ListItem
                                            key={`${index}-${file.name}`}
                                            role={undefined}
                                            dense
                                        >
                                            {action === 'Edit' &&
                                                <div>
                                                    <ListItemIcon>
                                                        <IconButton
                                                            onClick={() => {
                                                                props.deleteCrmFile(file.id);
                                                            }}
                                                        >
                                                            <i className="fas fa-trash-alt red-text"></i>
                                                        </IconButton>
                                                    </ListItemIcon>
                                                    <ListItemIcon>
                                                        <IconButton
                                                            onClick={() => {
                                                                // Download the file from backend / google drive
                                                                setDownloadFileName(file.name);
                                                                props.getCrmFile({ name: file.name, id: file.id });
                                                            }}
                                                        >
                                                            <i className="fas fa-arrow-down blue-text"></i>
                                                        </IconButton>
                                                    </ListItemIcon>
                                                </div>
                                            }
                                            <ListItemText primary={`${file.name}`} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </GridItem>
                }
                {formDataObj && Object.keys(formDataObj).length > 0 && action === 'Add' &&
                    <GridItem container xs={6} sm={6} md={6} justify="center" alignItems="center" direction="column">
                        <h3>Uploaded Files</h3>
                        <List>
                            {
                                Object.keys(formDataObj).map((key, index) => {
                                    return (
                                        <ListItem
                                            key={`${index}-${key}`}
                                            role={undefined}
                                            dense
                                        >
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        let newFormDataObj = clone(formDataObj) || {};
                                                        delete newFormDataObj[key];
                                                        setFormDataObj(newFormDataObj);
                                                    }}
                                                >
                                                    <i className="fas fa-trash-alt red-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        download(formDataObj[key].get('file'), key);
                                                    }}
                                                >
                                                    <i className="fas fa-arrow-down blue-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText primary={`${key}`} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </GridItem>
                }
                <GridItem xs={12} sm={12} md={12}>
                    {successStatus === 'Successfully uploaded document' &&
                        <Notification
                            open={true}
                            message="Successfully uploaded document"
                            close={closeAlert}
                        />
                    }
                    {successStatus === 'Failed to upload document' &&
                        <Notification
                            open={true}
                            message="Failed to upload document"
                            close={closeAlert}
                        />
                    }
                    {errorFile &&
                        <Notification
                            open={true}
                            message="Please upload a valid file with the following extensions: .pdf, .doc, .docx, .png, .jpg, or .jpeg"
                            close={closeAlert}
                        />
                    }
                </GridItem>
            </GridContainer>
        </div>
    )

}

export default ContactW9Upload;