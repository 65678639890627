import React, { useState } from "react";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import PaymentScheduleForm from "./PaymentScheduleForm";
import { getPaymentAmount, shouldGetPaymentAmount } from '../RSheetsLogic';

const paymentScheduleOptions = [
    "",
    "i. Due in Full",
    "ii. Due in Full Upon Lessee Obtaining its License",
    "iii. Due in Full Upon Removal of Contingencies",
    "iv. First Half Due Now",
    "v. First Half Due Upon Contingency Waiver",
    "vi. First Half Due Upon Lease Execution",
    "vii. Second Half Due Upon Lessee Opening for Business",
    "viii. Second Half Due Upon Contingency Removal",
    "ix. Second Half Due Upon Rent Commencement",
    "x. Second Half Due Upon Lease Commencement",
    "xi. Second Half Due On Later of Rent Commencement or Opening of Business",
    "xii. Second Half Due As Owner Receives Rent From Tenant",
    "xiii. Other -- Input Box To Specify",
];

const PaymentSchedule = (props) => {
    const {
        data,
        field,
        invoiceList,
        iv_index,
        total,
        totalPayment,
        handleUpdatePayment,
        payments,
        handleAddNewPayment,
        handleDeletePayment,
        updateDataObj,
    } = props;

    const [editIndex, setEditIndex] = useState(null);

    const isEditMode = (pm_index) => editIndex === pm_index;
    const setEditMode = (pm_index) => () => setEditIndex(pm_index);
    const closeEditMode = () => setEditIndex(null);

    const currentOptions = payments.map((pm) => pm.option).filter((op, index) => index !== editIndex);

    return (
        <div className="bordered-list">
            <PaymentScheduleForm
                currentOptions={currentOptions}
                data={data}
                field={field}
                handleAddNewPayment={handleAddNewPayment}
                paymentScheduleOptions={paymentScheduleOptions}
                totalPayment={totalPayment}
                total={total}
                invoiceList={invoiceList}
                updateDataObj={updateDataObj}
            />
            <div style={{ padding: 20 }}>
                {payments.length === 0 && (
                    <li className="text-center">
                        <em>No entries</em>
                    </li>
                )}
                {payments.map((p, i) => {
                    if (isEditMode(i)) {
                        return (
                            <PaymentScheduleForm
                                closeEditMode={closeEditMode}
                                currentOptions={currentOptions}
                                data={data}
                                field={field}
                                initValues={p}
                                invoiceList={invoiceList}
                                handleAddNewPayment={handleAddNewPayment}
                                handleUpdatePayment={handleUpdatePayment}
                                paymentScheduleOptions={paymentScheduleOptions}
                                pm_index={i}
                                iv_index={iv_index}
                                total={total}
                                totalPayment={totalPayment}
                                updateDataObj={updateDataObj}
                                editMode
                            />
                        );
                    }
                    
                    const shouldGetPayment = shouldGetPaymentAmount(p.option);
                    const paymentAmount = getPaymentAmount(data, p.option);
                    return (
                        <div className="boxed" key={`${i}-paymentss`}>
                            <GridContainer key={`pym-${i}`}>
                                <GridItem xs={12} sm={1} md={1}>
                                    <div style={{ marginTop: 33 }}>{`#${i + 1}`}</div>
                                </GridItem>
                                <GridItem xs={12} sm={2} md={2}>
                                    <CustomInput label="Payment Value" value={shouldGetPayment ? paymentAmount : p.value} readonly isDollar />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={8}>
                                    <CustomInput label="Option" value={p.option} readonly />
                                </GridItem>
                                <GridItem xs={12} sm={1} md={1}>
                                    <div className="hoverable mt-20" onClick={() => handleDeletePayment(i)}>
                                        <i className="fas fa-trash-alt red-text"></i>
                                    </div>
                                    <div className="hoverable mt-20" onClick={setEditMode(i)}>
                                        <i className="fa fa-edit"></i>
                                    </div>
                                </GridItem>
                            </GridContainer>
                            {p.option === "xiii. Other -- Input box to specify" && (
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}></GridItem>
                                    <GridItem xs={12} sm={7} md={7}>
                                        <div>
                                            <CustomInput label="Other Option" value={p.misc} readonly />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            )}
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div><strong>Notes :</strong> {p.notes}</div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PaymentSchedule;
