import React, { useEffect, useState } from "react";
import { defaultData, generateInvoiceHTML } from "containers/Invoices/InvoiceObj";
import InvoicePdfModal from "../../Invoices/InvoicePdfModal";
import { connect } from "react-redux";
import * as actions from "store/actions";
import Loader from "../../../components/Loader/Loader";

const InvoicePreview = props => {
    const [localPdfData, setLocalPdfData] = useState(null);
    const [openedPdfView, setOpenedPdfView] = useState(false);
    const [loading, setLoading] = useState(false);
    const { invoicePdf } = props;

    useEffect(() => {
        if (props.data) {
            const html = generateInvoiceHTML(props.data.data, 'isPreview');
            props.getInvoicePdf({ htmlTemplate: html });
            setOpenedPdfView(true);
            setLoading(true);
        }
    }, []);

    useEffect(() => {
        if (invoicePdf && typeof invoicePdf === "string") {
            const parsedPdf = JSON.parse(invoicePdf);
            setLocalPdfData(parsedPdf);
            setLoading(false);
        } else {
            setLocalPdfData(null);
        }
    }, [invoicePdf]);

    return <div>
        {localPdfData && openedPdfView && (
                <InvoicePdfModal
                    pdfData={localPdfData}
                    invoice={props.data}
                    open={true}
                    close={() => {
                        setLocalPdfData(null);
                        setLoading(false);
                        setOpenedPdfView(false);
                        props.close();
                    }}
                />
            )}
        {loading && <div><Loader /> Loading PDF...</div>}
    </div>
}

const mapStateToProps = (state) => {
    return {
        entriesData: state.invoice.entriesData,
        error: state.invoice.error,
        success: state.invoice.success,
        loading: state.invoice.loading,
        invoicePdf: state.invoice.invoicePdf,
        rsheetData: state.rs.rsheetData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoiceEntries: (status) => {
            dispatch(actions.getInvoiceEntries(status));
        },
        createInvoiceEntry: (data) => {
            dispatch(actions.createInvoiceEntry(data));
        },
        updateInvoiceEntry: (data) => {
            dispatch(actions.updateInvoiceEntry(data));
        },
        deleteInvoiceEntry: (id) => {
            dispatch(actions.deleteInvoiceEntry(id));
        },
        getInvoicePdf: (html) => {
            dispatch(actions.getInvoicePdf(html));
        },
        clearInvoicePdfData: () => {
            dispatch(actions.clearInvoicePdfData());
        },
        resendInvoiceEmail: (data) => {
            dispatch(actions.resendInvoiceEmail(data))
        },
        getRsheet: (id) => dispatch(actions.getRsheet(id)),
        resetRsData: () => dispatch(actions.getRsheetStart()),
        resetInvoiceID: () => dispatch(actions.resetInvoiceID()),
        clearRSheets: () => dispatch(actions.clearRSheets()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicePreview);