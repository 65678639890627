import React, { useEffect, useState, useRef } from "react";
import GridContainer from "../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../components/Grid/GridItem";
import Button from "../../../../../components/CustomButtons/Button";
import TermEstimatedMainForm from "./TermEstimatedMainForm";
import TermEstimatedMainAbatedForm from "./TermEstimatedMainAbatedForm";
import { cloneJ, formatDollar, reverseCalculateDays } from "../../../../../shared/utility";
import NotificationAsk from 'components/Notification/NotificationAsk';
import { get } from "lodash";
import { 
    calculateDays, calculateYearCompleteness, sortMonthsRange, round2dec
} from 'shared/utility';

const TermEstimatedMain = props => {
    const [scrollRef, setScrollRef] = useState(null);

    // Lease Term
    const [openedAddNewRange, setOpenedAddNewRange] = useState(0);
    const [editRange, setEditRange] = useState({
        year: -1,
        range: -1
    });
    const [openedAskDelete, setOpenedAskDelete] = useState({
        year: -1,
        range: -1
    });

    // Abated
    const [openedAddNewAbatedRange, setOpenedAddNewAbatedRange] = useState(0);
    const [editAbatedRange, setEditAbatedRange] = useState({
        year: -1,
        range: -1
    });
    const [openedAskAbatedDelete, setOpenedAskAbatedDelete] = useState({
        year: -1,
        range: -1
    });
    console.log('TermEstimatedMain', props);
    const ref = useRef(null);

    const handleScrollClick = () => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    useEffect(() => {
        if (scrollRef) {
            handleScrollClick();
            setScrollRef(null);
        }
    }, [scrollRef]);

    const { calcs, data, setData } = props;
    const { lease_term, abated_rent_ranges } = data;
    if (!lease_term || !abated_rent_ranges) return null;

    return <>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {lease_term.map((yr,y) => {
                    console.log('yrrr', yr, y);
                    const yearIsComplete = calculateYearCompleteness(yr.ranges, y, data);
                    const year_abated_rent = get(data, `abated_rent_ranges[${y}].ranges`, []);
                    const abatedYearIsComplete = false; //calculateYearCompleteness(year_abated_rent, y, data);
                    const yearCalculated = get(calcs, `lease_term_calculations.years[${y}]`, {});
                    const yearAbatedCalculated = get(calcs, `abated_term_calculations.years[${y}]`, {});
                    return <div key={`yr-${y}`}>
                        {y !== 0 && <hr style={{color:'red',borderTop:'1px solid red',height:2,marginTop:40}} />}
                        <h3 style={{marginTop:40}}>Lease Year {y + 1}</h3>
                        <Button
                            disabled={yearIsComplete ? true : false}
                            color="primary"
                            onClick={() => {
                                setOpenedAddNewRange(y+1);
                                setScrollRef(`${y}-add`);
                            }}
                        >ADD NEW RANGE</Button>
                        {!yearIsComplete && <div className="red-text">This range is not complete</div>}
                        {yr.ranges.length === 0 && <div style={{padding:40}}><em>No Ranges</em></div>}
                        {yr.ranges.map((range,r) => {
                            const { value, commissionRate, flat_fee, month1, month2, day1, day2 } = range;
                            const showEditRange = editRange.year === y && editRange.range === r ? true : false;
                            const totalValue = parseFloat(range.value);
                            const yearCalculatedRange = get(yearCalculated, `ranges[${r}]`, {});
                            const totalCommission = formatDollar(yearCalculatedRange.total_commission);
                            const totalRentCollected = formatDollar(yearCalculatedRange.total_term_rent);
                            return <div className="boxed mt-20 white-bg" 
                                    key={`range-${r}`}
                                    ref={scrollRef === `${y}-${r}` ? ref : null}
                                >
                                {!showEditRange && <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h4 style={{display:'inline-block',marginRight:20}}>Lease Range # {r+1}</h4>
                                        <Button
                                            size="sm"
                                            color="primary"
                                            onClick={() => {
                                                setEditRange({
                                                    year: y,
                                                    range: r
                                                })
                                            }}
                                        >EDIT</Button>
                                        <Button
                                            size="sm"
                                            color="white"
                                            onClick={() => {
                                                setOpenedAskDelete({
                                                    year: y,
                                                    range: r
                                                })
                                            }}
                                        >DELETE</Button>
                                    </GridItem>
                                    <GridItem xs={12} sm={2} md={3}>
                                        <div>
                                            Monthly $
                                            <br />
                                            Value
                                        </div>
                                        <strong>{formatDollar(value)}</strong>
                                    </GridItem>
                                    {data.flat_fee_commission === "Yes" && (
                                        <GridItem xs={12} sm={2} md={3}>
                                            <div>Flat Fee</div>
                                            <strong>{formatDollar(flat_fee)}</strong>
                                        </GridItem>
                                    )}
                                    {data.flat_fee_commission !== "Yes" && (
                                            <GridItem xs={12} sm={2} md={3}>
                                                <div>
                                                    Commission
                                                    <br />
                                                    Rate
                                                </div>
                                                <strong>{commissionRate} %</strong>
                                            </GridItem>
                                        )}
                                    <GridItem xs={12} sm={2} md={3}>
                                        <div style={{marginLeft:20,marginRight:20,marginBottom:10}}>{month1} - {day1}</div>
                                        <div>to</div>
                                    </GridItem>
                                    <GridItem xs={12} sm={2} md={3}>
                                        <div>Range's Total Rent Collected :</div>
                                        <strong>{totalRentCollected}</strong>
                                    </GridItem>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <div style={{marginTop:20,marginBottom:20}}>
                                            Day {calculateDays(month1, day1)} to Day {calculateDays(month2, day2)}
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} sm={2} md={3}>
                                        <div style={{marginLeft:20,marginRight:20,marginBottom:10}}>{month2} - {day2}</div>
                                    </GridItem>
                                    <GridItem xs={12} sm={2} md={3}>
                                        <div>Total Commission :</div>
                                        <strong>{totalCommission}</strong>
                                    </GridItem>
                                </GridContainer>}
                                {showEditRange && <div className="boxed"
                                    style={{border:'3px solid black'}}
                                >
                                    <TermEstimatedMainForm
                                        data={data}
                                        current_year={y}
                                        current_range={r+1}
                                        close={() => {
                                            setEditRange({
                                                year: -1,
                                                range: -1
                                            });
                                        }}
                                        save={(localData) => {
                                            const clonedData = cloneJ(data);
                                            clonedData.lease_term[y].ranges[r] = localData;
                                            // sort ranges 
                                            clonedData.lease_term[y].ranges.sort(sortMonthsRange);
                                            console.log('setting data ->', clonedData);
                                            setData(clonedData);
                                        }}
                                        localDataLoad={range}
                                    />
                                </div>}
                                <hr />
                            </div>;
                        })}
                        {(openedAddNewRange === (y+1)) && <div className="boxed"
                            style={{border:'3px solid black'}}
                            ref={scrollRef === `${y}-add` ? ref : null}
                        >
                            <TermEstimatedMainForm
                                data={data}
                                current_year={y}
                                close={() => {
                                    setOpenedAddNewRange(0);
                                }}
                                save={(localData) => {
                                    const clonedData = cloneJ(data);
                                    console.log('setting data 2a ->', clonedData, data);
                                    clonedData.lease_term[y].ranges.push(localData);
                                    clonedData.lease_term[y].ranges.sort(sortMonthsRange);
                                    console.log('setting data 2b ->', clonedData);
                                    setData(clonedData);
                                }}
                                suggestion={true}
                            />
                        </div>}

                        <h4>Year {y+1} Total Annual Amount :{formatDollar(yearCalculated.year_total_term_rent)}, Total Commission Amount: {formatDollar(yearCalculated.year_total_commission)}</h4>
                        
                        <hr />
                        <Button
                            disabled={abatedYearIsComplete ? true : false}
                            color="primary"
                            onClick={() => {
                                setOpenedAddNewAbatedRange(y+1);
                                setScrollRef(`${y}-add-abated`);
                            }}
                        >ADD NEW ABATED RANGE</Button>
                        {year_abated_rent.length === 0 && <div style={{padding:40}}><em>No Abated Ranges</em></div>}
                        {year_abated_rent.map((abated_range,ar) => {
                            const calcs_abated_range = get(calcs, `abated_term_calculations.years[${y}].ranges[${ar}]`, null);
                            const showEditAbatedRange = editAbatedRange.year === y && editAbatedRange.range === ar ? true : false;
                            const abated_value = abated_range.value;
                            const { month1, month2, day1, day2 } = abated_range;
                            return <div className="boxed mt-20 white-bg" 
                                key={`a-range-${ar}`}
                                ref={scrollRef === `a${y}-${ar}` ? ref : null}
                            >
                            {!showEditAbatedRange && <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <h4 style={{display:'inline-block',marginRight:20}}>Abated Range # {ar+1}</h4>
                                    <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() => {
                                            setEditAbatedRange({
                                                year: y,
                                                range: ar
                                            })
                                        }}
                                    >EDIT</Button>
                                    <Button
                                        size="sm"
                                        color="white"
                                        onClick={() => {
                                            setOpenedAskAbatedDelete({
                                                year: y,
                                                range: ar
                                            })
                                        }}
                                    >DELETE</Button>
                                </GridItem>
                                <GridItem xs={12} sm={2} md={3}>
                                    <div>
                                        Monthly $
                                        <br />
                                        Value
                                    </div>
                                    <strong>{formatDollar(abated_value)}</strong>
                                </GridItem>
                                <GridItem xs={12} sm={2} md={3}>
                                    <div style={{marginLeft:20,marginRight:20,marginBottom:10}}>{month1} - {day1}</div>
                                    <div>to</div>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <div>Total Rent Abated :</div>
                                    <strong>{formatDollar(calcs_abated_range.total_rent_abated)}</strong>
                                </GridItem>
                                <GridItem xs={12} sm={3} md={3}>
                                    <div style={{marginTop:20,marginBottom:20}}>
                                        Day {calculateDays(month1, day1)} to Day {calculateDays(month2, day2)}
                                    </div>
                                </GridItem>
                                <GridItem xs={12} sm={2} md={3}>
                                    <div style={{marginLeft:20,marginRight:20,marginBottom:10}}>{month2} - {day2}</div>
                                </GridItem>
                                <GridItem xs={12} sm={6} md={6}>
                                    <div>Total Abated Commission :</div>
                                    <strong>{formatDollar(calcs_abated_range.total_abated_commission)}</strong>
                                </GridItem>
                            </GridContainer>}
                            {showEditAbatedRange && <div className="boxed"
                                style={{border:'3px solid black'}}
                            >
                                <TermEstimatedMainAbatedForm
                                    data={data}
                                    current_year={y}
                                    current_range={ar+1}
                                    close={() => {
                                        setEditAbatedRange({
                                            year: -1,
                                            range: -1
                                        });
                                    }}
                                    save={(localData) => {
                                        const clonedData = cloneJ(data);
                                        clonedData.abated_rent_ranges[y].ranges[ar] = localData;
                                        // sort ranges 
                                        clonedData.abated_rent_ranges[y].ranges.sort(sortMonthsRange);
                                        setData(clonedData);
                                    }}
                                    localDataLoad={abated_range}
                                />
                            </div>}
                            <hr />
                        </div>;
                        })}

                        {(openedAddNewAbatedRange === (y+1)) && <div className="boxed"
                            style={{border:'3px solid black'}}
                            ref={scrollRef === `${y}-add-abated` ? ref : null}
                        >
                            <TermEstimatedMainAbatedForm
                                data={data}
                                current_year={y}
                                close={() => {
                                    setOpenedAddNewAbatedRange(0);
                                }}
                                save={(localData) => {
                                    const clonedData = cloneJ(data);
                                    clonedData.abated_rent_ranges[y].ranges.push(localData);
                                    clonedData.abated_rent_ranges[y].ranges.sort(sortMonthsRange);
                                    setData(clonedData);
                                }}
                                suggestion={true}
                            />
                        </div>}

                        <h4>Year {y+1} Total Abated Amount :{formatDollar(yearAbatedCalculated.year_rent_abated)}</h4>

                        <hr />

                        <h4><strong>YEAR {y+1}</strong></h4>
                        <p>{reverseCalculateDays(yearCalculated.start, true)} to {reverseCalculateDays(yearCalculated.end, true)}</p>
                        <h4>Gross Rent Collected: <strong>{formatDollar(yearCalculated.year_total_term_rent)}</strong></h4>
                        <h4>Rent Abated: <strong>({formatDollar(yearAbatedCalculated.year_rent_abated)})</strong></h4>
                        <h4>Net Rent Collected: <strong>{formatDollar(yearCalculated.year_net_rent_collected)}</strong></h4>
                        <h4>Gross Commission: <strong>{formatDollar(yearCalculated.year_total_commission)}</strong></h4>
                        <h4>Abated Commission: <strong>({formatDollar(yearAbatedCalculated.year_abated_commission)})</strong></h4>
                        <h4>Net Commission: <strong>{formatDollar(yearCalculated.year_net_commission)}</strong></h4>
                    </div>
                })}
            </GridItem>

            {openedAskDelete.year !== -1 && <NotificationAsk 
                open={openedAskDelete.year !== -1 ? true : false}
                title="Are you sure?"
                message="Do you want to delete this lease term range?"
                success={() => {
                    const clonedData = cloneJ(data);
                    clonedData.lease_term[openedAskDelete.year].ranges = clonedData.lease_term[openedAskDelete.year].ranges.filter((range,r) => {
                        if (r === openedAskDelete.range) return false;
                        return true;
                    })
                    setData(clonedData);
                    setOpenedAskDelete({
                        year: -1,
                        range: -1
                    })
                }}
                close={() => {
                    setOpenedAskDelete({
                        year: -1,
                        range: -1
                    })
                }}
            />}

            {openedAskAbatedDelete.year !== -1 && <NotificationAsk 
                open={openedAskAbatedDelete.year !== -1 ? true : false}
                title="Are you sure?"
                message="Do you want to delete this abated range?"
                success={() => {
                    const clonedData = cloneJ(data);
                    clonedData.abated_rent_ranges[openedAskAbatedDelete.year].ranges = clonedData.abated_rent_ranges[openedAskAbatedDelete.year].ranges.filter((range,r) => {
                        if (r === openedAskAbatedDelete.range) return false;
                        return true;
                    })
                    setData(clonedData);
                    setOpenedAskAbatedDelete({
                        year: -1,
                        range: -1
                    })
                }}
                close={() => {
                    setOpenedAskAbatedDelete({
                        year: -1,
                        range: -1
                    })
                }}
            />}
        </GridContainer>
    </>
}

export default TermEstimatedMain;