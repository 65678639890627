import React, { useEffect, useState } from "react";
import { subLeaseFields, subFields } from "./RSheetsSubleaseModel";
import Button from "components/CustomButtons/Button";
import RSheetsSubleaseFormActions from "./RSheetsSubleaseFormActions";
import FormElement from "../RSheetsFormElement";
import { Dialog } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { commatize, formatDollar } from "shared/utility";
import {
    calculateAbatedValues,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    calculateTermsTotal,
    generateCommissionSplitData,
    getPaymentAmount,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    shouldGetPaymentAmount,
    calculateAbatedRanges_RS as calculateAbatedRanges,
    calculateStartingRent,
    getDateDifference,
    getAbatedTotal,
    newYearlyCalculation,
    calculateYearAbatedRanges,
} from "../RSheetsLogic";
import RSheetSignRequestForm from "../RSheetSignRequestForm";
import { get, isEmpty, sortBy } from "lodash";

import Lessees from './CustomElements/Lessees';
import Lessors from './CustomElements/Lessors';

import InvoicePayment from '../CommonCustomElements/InvoicePayment';
import FileLoading from '../CommonCustomElements/FileLoading';
import FileUpload from '../CommonCustomElements/FileUpload';
import Term from '../CommonCustomElements/Term/Term';
import CommissionAdjustmentTable from '../CommonCustomElements/CommissionAdjustmentTable';
import termLogic from '../CommonCustomElements/Term/TermLogicMain';
import { RentTotals, TermElement, AbatedElement } from "../CommonCustomElements/Term/TermLogicMain";
import OtherReferrals from "../CommonCustomElements/OtherReferrals";

const renderLabel = (field, data, subType, overrideLabel) => {
    if (overrideLabel) {
        return (
            <div>
                <strong>{overrideLabel}</strong> : {data[field]}
            </div>
        );
    }
    return (
        <div>
            <strong>{subFields[subType][field] ? subFields[subType][field].label : ''}</strong> : {data[field]}
        </div>
    );
};

const RSheetsSubleaseFormFull = (props) => {
    const [fieldEdit, setFieldEditValue] = useState(null);
    const [calculatedAbatedRanges, setCalculatedAbatedRanges] = useState(null);
    const [editOtherReferrals, setEditOtherReferrals] = useState(null);
    const { data, updateDataObj, updateDataMultiObj, setData } = props;

    const calcs = termLogic.calculateLeaseTerm(data);
    const diffObj = getDateDifference(data);

    // FIXME - DEPRECATED
    const isFlatFee = data.flat_fee_commission === 'Yes';

    const shouldShowRanges = !isFlatFeeCommissionEnabled(data);

    const hideStartingRentPerSqft = get(data, 'flat_fee_space', '') === 'Yes';

    const hasLesseeAssociate = get(data, 'illi_represented_lessee', '') !== 'No'
    const hasLessorAssociate = get(data, 'illi_represented_lessor', '') !== 'No'

    useEffect(() => {
        generateCommissionSplitData(data, setData)
    }, [])

    useEffect(() => {
        const calc = calculateAbatedRanges({
            lease_term: data.lease_term,
            abated: data.abated_rent_ranges,
        });
        setCalculatedAbatedRanges(calc);
    }, [data.lease_term, data.abated_rent_ranges]);

    const renderRentTotals = () => {
        const lease_term = get(data, "lease_term", []);
        const abated = get(data, "abated_rent_ranges", []);
	    const isActualDays = "Actual Days";

        let grossRent = 0
        let grossCommission = 0;
        let abatedRent = 0;
        let abatedCommission  = 0;

        lease_term.forEach((lt, i) => {
            const yearAbated = get(data, `abated_rent_ranges[${i}].ranges`, []);
            const abated_ranges = calculateYearAbatedRanges({ lease_term, year_abated: yearAbated });

            const yearResult = newYearlyCalculation(lt.ranges, isEstimated, isActualDays)
            const abatedYearResult = newYearlyCalculation(abated_ranges, isEstimated, isActualDays);

            grossRent += yearResult.termRent;
            grossCommission += yearResult.commission;
            abatedRent += abatedYearResult.termRent;
            abatedCommission += abatedYearResult.commission;
        })


        const net_commission = grossRent - abatedRent;
        const net_rent = grossCommission - abatedCommission;

        const commission_adjustment = get(data, "commission_adjustment", null);
        const commission_adjustment_details = get(data, "commission_adjustment_details", []);

        const hasCommissionAdjustments = (commission_adjustment === 'Yes' && !isEmpty(commission_adjustment_details))

        const renderCommissionAdjustments = () => {
            if (hasCommissionAdjustments) {
                return commission_adjustment_details.map((item, index) => {
                    return <h4 key={`${index}-adjustment`}>
                        #{index + 1} - {item.option} - Adjusted Commission: <strong>{item.value}</strong>
                    </h4>
                })
            } else {
                return null;
            }
        }

        return (
            <div>
                <h4>
                    Gross Rent Collected : <span className="bold">{formatDollar(grossRent)}</span>
                </h4>
                <h4>
                    Rent Abated : <span className="bold">{formatDollar(abatedRent, true, true)}</span>
                </h4>
                <h4>
                    Net Rent Collected : <span className="bold">{formatDollar(net_rent)}</span>
                </h4>
                <h4>
                    Gross Commission : <span className="bold">{formatDollar(grossCommission)}</span>
                </h4>
                <h4>
                    Abated Commission : <span className="bold">{formatDollar(abatedCommission, true, true)}</span>
                </h4>
                <h4>
                    Net Commission : <span className="bold">{formatDollar(net_commission)}</span>
                </h4>

                {hasCommissionAdjustments && <>
                <hr />
                {renderCommissionAdjustments()}
                <h4>
                    Total Adjusted Commission:{" "}
                    <span className="bold">{formatDollar(net_commission + calculateAdjustedCommission(data))}</span>
                </h4>
                </>}
            </div>
        );
    };

    const renderEstimatedRentTotals = () => {
        const terms = get(data, "lease_term", []);
        const abated_rent_ranges = get(data, "abated_rent_ranges", []);
        let grossRent = 0;
        let abatedRent = 0;
        let grossCommission = 0;
        let abatedCommission = 0;

        const abated_ranges = calculateEstimatedAbatedRanges({ lease_term: terms, abated_rent_ranges });
        const abatedTotal = calculateEstimatedTermsTotal(abated_ranges);

        if (abatedTotal) {
            abatedRent = abatedTotal.totalTermRent;
            abatedCommission = abatedTotal.totalCommission;
        }

        terms.forEach((term) => {
            const total = calculateEstimatedTermsTotal(term.ranges, isFlatFee);
            grossRent += total.totalTermRent;
            grossCommission += total.totalCommission;
        });

        const commission_adjustment = get(data, "commission_adjustment", null);
        const commission_adjustment_details = get(data, "commission_adjustment_details", []);

        const hasCommissionAdjustments = (commission_adjustment === 'Yes' && !isEmpty(commission_adjustment_details))

        const renderCommissionAdjustments = () => {
            if (hasCommissionAdjustments) {
                return commission_adjustment_details.map((item, index) => {
                    return <h4 key={`${index}-adjustment`}>
                        #{index + 1} - {item.option} - Adjusted Commission: <strong>{item.value}</strong>
                    </h4>
                })
            } else {
                return null;
            }
        }

        return (
            <div>
                <h4>
                    Gross Rent Collected (Estimated): <span className="bold">{formatDollar(grossRent)}</span>
                </h4>
                <h4>
                    Rent Abated (Estimated): <span className="bold">{formatDollar(abatedRent, true)}</span>
                </h4>
                <h4>
                    Net Rent Collected (Estimated): <span className="bold">{formatDollar(grossRent + abatedRent)}</span>
                </h4>
                <h4>
                    Gross Commission (Estimated): <span className="bold">{formatDollar(grossCommission)}</span>
                </h4>
                <h4>
                    Abated Commission (Estimated): <span className="bold">{formatDollar(abatedCommission, true)}</span>
                </h4>
                <h4>
                    Net Commission (Estimated):{" "}
                    <span className="bold">{formatDollar(grossCommission + abatedCommission)}</span>
                </h4>

                {hasCommissionAdjustments && <>
                <hr />
                {renderCommissionAdjustments()}
                <h4>
                    Total Adjusted Commission (Estimated):{" "}
                    <span className="bold">{formatDollar(grossCommission + abatedCommission + calculateAdjustedCommission(data))}</span>
                </h4>
                </>}
            </div>
        );
    };

    const setFieldEdit = (field) => {
        if (!props.viewOnly) setFieldEditValue(field);
    };
    const make = (field, showLabel = true) => {
        const prefix =
            subLeaseFields[field] !== undefined && subLeaseFields[field].prefix && subLeaseFields[field].prefix !== "$" ? (
                <span>{subLeaseFields[field].prefix}</span>
            ) : (
                ""
            );
        let value = get(data, field, "");

        if (subLeaseFields[field] !== undefined && subLeaseFields[field].type === "date2") {
            const date_is_estimated = !!data[`${field}_estimated`];
            const tbd = subLeaseFields[`${field}_estimated_tbd`];
            const dataArr = data[`${field}_estimated_tbd`] ? data[`${field}_estimated_tbd`].split(",") : [];
            value =
                data[`${field}_estimated`] && dataArr.indexOf("TBD") !== -1 ? "TBD" : data[field] ? data[field] : "";
            return (
                <span className="hoverable fullform">
                    {showLabel && (
                        <strong>
                            {subLeaseFields[field].label} : {prefix}
                        </strong>
                    )}
                    <span className="editables">
                        {value} {date_is_estimated && value !== "TBD" ? "(Estimated)" : ""}
                    </span>
                </span>
            );
        }

        return (
            <span className="hoverable fullform">
                {subLeaseFields[field] !== undefined && showLabel && (
                    <strong>
                        {subLeaseFields[field].label} : {prefix}
                    </strong>
                )}
                <span className="editables">{value}</span>
            </span>
        );
    };
    const makeCheckboxes = (field) => {
        return (
            <span>
                {subLeaseFields[field] !== undefined && subLeaseFields[field].options.map((o, i) => {
                    if (!o) return null;
                    if (data[field] === 0) {
                        return (
                            <span className="grayed ml-10 hoverable fullform" key={`${field}-${o}-${i}`}>
                                <input type="checkbox" key={`${o}-checkbox-${field}`} checked={true} readOnly />
                                &nbsp;{o}
                            </span>
                        );
                    }
                    if (data[field]) {
                        if (data[field].indexOf(o) !== -1) {
                            return (
                                <span className="grayed ml-10 hoverable fullform" key={`${field}-${o}-${i}`}>
                                    <input type="checkbox" key={`${o}-checkbox-${field}`} checked={true} readOnly />
                                    &nbsp;{o}
                                </span>
                            );
                        }
                    }
                    return (
                        <span className="grayed ml-10 hoverable fullform" key={`${field}-${o}-${i}`}>
                            <input type="checkbox" key={`${o}-checkbox-${field}`} checked={false} readOnly />
                            &nbsp;{o}
                        </span>
                    );
                })}
            </span>
        );
    };

    const makeArray = (field, key) => {
        if (!data[field]) return null;
        if (key === "multi-dates") {
            return (
                <ul className="list-unstyled">
                    {data[field].map((m, i) => {
                        let all_total = 0;
                        let total_abated = 0;
                        if (field === "lease_term") {
                            if (data.abated_rent_ranges) {
                                if (data.abated_rent_ranges[i]) {
                                    const tot = getAbatedTotal(data.abated_rent_ranges[i]);
                                    total_abated += tot;
                                }
                            }
                        }
                        return (
                            <li key={`${i}-${field}-year`}>
                                <div>
                                    <h4>Year {m.year_num}</h4>
                                    {m.ranges.length === 0 && (
                                        <div>
                                            <em>None</em>
                                        </div>
                                    )}
                                    {m.ranges.map((mr, j) => {
                                        const { value, month1, month2, day1, day2, date1, date2, commissionRate } = mr;

                                        const lease_term = get(data, "lease_term", []);

                                        const abatedRanges = calculateAbatedValues({
                                            lease_term,
                                            abated_range: mr,
                                        });
                    
                                        const totalAbated = calculateTermsTotal(abatedRanges);

                                        const term = isEstimated ? (
                                            <span>
                                                {month1} {day1} - {month2} - {day2}
                                            </span>
                                        ) : (
                                            <span>
                                                {mr.date1} to {mr.date2}
                                            </span>
                                        );

                                        return (
                                            <div key={`${j}-range-${i}`}>
                                                {term} {formatDollar(totalAbated.totalTermRent, true)}
                                                {field === "lease_term" && (
                                                    <div>
                                                        {commissionRate ? commatize(commissionRate, true) : 0}%
                                                        commission rate
                                                    </div>
                                                )}
                                                <hr />
                                            </div>
                                        );
                                    })}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        } else if (key === "multi-contact") {
            if (field === "lessee_contacts" || field === "lessor_contacts") {
                return (
                    <ul className="list-unstyled">
                        {data[field].map((m, i) => {
                            const a = m;
                            let contactPhone = renderLabel("main_phone", a, field, 'Main Phone');
                            if (a.phone_type === 'Mobile') {
                                contactPhone = renderLabel("mobile_phone", a, field, 'Mobile Phone');
                            } else if (a.phone_type === 'Work') {
                                contactPhone = renderLabel("work_phone", a, field, 'Work Phone');
                            }
                            return (
                                <li key={`${i}-${field}`}>
                                    <div className="bordered-thin">
                                        {field === "lessee_contacts" && (
                                            <div>
                                                {renderLabel("name", a, "lessee")}
                                                {renderLabel("last_name", a, "lessee")}
                                                {renderLabel("address1", a, "lessee")}
                                                {renderLabel("address2", a, "lessee")}
                                                {renderLabel("city", a, "lessee")}
                                                {renderLabel("state", a, "lessee")}
                                                {renderLabel("zip", a, "lessee")}
                                                {renderLabel("email", a, "lessee")}
                                                {contactPhone}
                                            </div>
                                        )}
                                        {field === "lessor_contacts" && (
                                            <div>
                                                {renderLabel("name", a, "lessor")}
                                                {renderLabel("last_name", a, "lessor")}
                                                {renderLabel("address1", a, "lessor")}
                                                {renderLabel("address2", a, "lessor")}
                                                {renderLabel("city", a, "lessor")}
                                                {renderLabel("state", a, "lessor")}
                                                {renderLabel("zip", a, "lessor")}
                                                {renderLabel("email", a, "lessor")}
                                                {contactPhone}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <ul className="list-unstyled">
                        {data[field].map((m, i) => {
                            return (
                                <li key={`${i}-${field}`}>
                                    <div className="bordered-thin">
                                        Name: {m.name} {m.last_name}
                                        <br />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        } else if (key === "name") {
            let html = ``;
            if (data[field]) {
                html = data[field]
                    .map((m) => {
                        return m.name + " " + m.last_name;
                    })
                    .join(", ");
            }
            return html;
        } else {
            let html = ``;
            if (data[field]) {
                html = data[field].map((m) => m[key]).join(", ");
            }
            return html;
        }
    };

    const renderElement = (field, calcs) => {
        return (
            <FormElement
                field={field}
                data={data}
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                sheetType="SubLease"
                setData={setData}
                components={{
                    FileLoading: FileLoading,
                    FileUpload: FileUpload,
                    InvoicePayment: InvoicePayment,
                    Lessees: Lessees,
                    Lessors: Lessors,
                    Term: Term,
                }}
                calcs={calcs}
                canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
            />
        );
    };

    const renderLessors = () => {
        const model = subFields.lessor;
        const sort = [
            "company_name",
            "name",
            "last_name",
            "phone",
            "email",
            "address1",
            "address2",
            "state",
            "city",
            "zip",
        ];
        return (
            <div>
                {data.lessors.map((dl, i) => {
                    const sortedKey = sortBy(Object.keys(dl), (item) => {
                        const index = sort.indexOf(item);
                        return index === -1 ? Infinity : index;
                    });
                    return (
                        <div key={`${i}-lessor`}>
                            <h4>Sub-Lessor {i + 1}</h4>
                            {sortedKey.map((field) => {
                                // HIDE UNSELECTED
                                if (dl.phone_type) {
                                    if (dl.phone_type === 'Work') {
                                        if (field === 'mobile_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Mobile') {
                                        if (field === 'work_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Main') {
                                        if (field === 'work_phone' || field === 'mobile_phone') return null;
                                    }
                                } else {
                                    if (field === 'work_phone' || field === 'mobile_phone') return null;
                                }

                                if (field === "id" || !model[field]) return null;
                                if (dl.company_name) {
                                    if (field === "name" || field === "last_name") return null;
                                } else {
                                    if (field === "company_name") return null;
                                }
                                return (
                                    <div key={`${field}-lfield`}>
                                        <strong>{model[field].label}</strong> :{" "}
                                        <span>{dl[field] ? dl[field] : "n/a"}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderLessees = () => {
        const model = subFields.lessee;
        const sort = [
            "company_name",
            "name",
            "last_name",
            "phone",
            "email",
            "address1",
            "address2",
            "state",
            "city",
            "zip",
        ];
        return (
            <div>
                {data.lessees.map((dl, i) => {
                    const sortedKey = sortBy(Object.keys(dl), (item) => {
                        const index = sort.indexOf(item);
                        return index === -1 ? Infinity : index;
                    });
                    return (
                        <div key={`${i}-lessee`}>
                            <h4>Sub-Lessee {i + 1}</h4>
                            {sortedKey.map((field) => {
                                // HIDE UNSELECTED
                                if (dl.phone_type) {
                                    if (dl.phone_type === 'Work') {
                                        if (field === 'mobile_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Mobile') {
                                        if (field === 'work_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Main') {
                                        if (field === 'work_phone' || field === 'mobile_phone') return null;
                                    }
                                } else {
                                    if (field === 'work_phone' || field === 'mobile_phone') return null;
                                }

                                if (field === "id" || !model[field]) return null;
                                if (dl.company_name) {
                                    if (field === "name" || field === "last_name") return null;
                                } else {
                                    if (field === "company_name") return null;
                                }
                                return (
                                    <div key={`${field}-lfield`}>
                                        <strong>{model[field].label}</strong> :{" "}
                                        <span>{dl[field] ? dl[field] : "n/a"}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderCommissionsLessor = () => {
        const total =
            data.commission_splits_agent
                .map((acc) => {
                    if (acc.Percentage === "" || isNaN(acc.Percentage)) {
                        acc.Percentage = "0";
                        return acc;
                    } else {
                        return acc;
                    }
                })
                .reduce((acc, curr) => {
                    return acc + parseInt(curr.Percentage);
                }, 0) + "%";

        const totalText = () => (total === "100%" ? total : <span className="red-text">{total}</span>);
        return (
            <tbody>
                <tr>
                    <td className="col-50">
                        <strong>Name</strong>
                    </td>
                    <td className="col-50">
                        <strong>Percentage</strong>
                    </td>
                </tr>
                {data.commission_splits_agent.map((c, i) => {
                    return (
                        <tr key={`${i}-agent`}>
                            <td className="col-50">{c.Name}</td>
                            <td className="col-50">{c.Percentage}</td>
                        </tr>
                    );
                })}
                <tr>
                    <td className="col-50">
                        <strong>Total</strong>
                    </td>
                    <td className="col-50">{totalText()}</td>
                </tr>
            </tbody>
        );
    };

    const renderCommissionsLessee = () => {
        const total =
            data.commission_splits_agent2
                .map((acc) => {
                    if (acc.Percentage === "" || isNaN(acc.Percentage)) {
                        acc.Percentage = "0";
                        return acc;
                    } else {
                        return acc;
                    }
                })
                .reduce((acc, curr) => {
                    return acc + parseInt(curr.Percentage);
                }, 0) + "%";

        const totalText = () => (total === "100%" ? total : <span className="red-text">{total}</span>);
        return (
            <tbody>
                <tr>
                    <td className="col-50">
                        <strong>Name</strong>
                    </td>
                    <td className="col-50">
                        <strong>Percentage</strong>
                    </td>
                </tr>
                {data.commission_splits_agent2.map((c, i) => {
                    return (
                        <tr key={`${i}-agent`}>
                            <td className="col-50">{c.Name}</td>
                            <td className="col-50">{c.Percentage}</td>
                        </tr>
                    );
                })}
                <tr>
                    <td className="col-50">
                        <strong>Total</strong>
                    </td>
                    <td className="col-50">{totalText()}</td>
                </tr>
            </tbody>
        );
    };

    const renderCommissionsOtherLessor = () => {
        return (
            <tbody>
                <tr>
                    <td className="col-50">
                        <strong>Name</strong>
                    </td>
                    <td className="col-50">
                        <strong>Value</strong>
                    </td>
                </tr>
                <tr>
                    <td className="col-50">Sub-Lessor Referral</td>
                    <td className="col-50">
                        {data.referral_firm_fee_amount_choice === "Value"
                            ? data.referral_firm_fee_amount
                            : data.referral_firm_fee_amount_percentage + " %"}
                    </td>
                </tr>
                <tr>
                    <td className="col-50">Sub-Lessor - Outside Broker</td>
                    <td className="col-50">{data.lessor_outside_broker_commission}</td>
                </tr>
            </tbody>
        );
    };

    const renderCommissionsOtherLessee = () => {
        return (
            <tbody>
                <tr>
                    <td className="col-50">
                        <strong>Name</strong>
                    </td>
                    <td className="col-50">
                        <strong>Value</strong>
                    </td>
                </tr>
                <tr>
                    <td className="col-50">Sub-Lessee Referral</td>
                    <td className="col-50">
                        {data.lessee_referral_firm_fee_amount_choice === "Value"
                            ? data.lessee_referral_firm_fee_amount
                            : data.lessee_referral_firm_fee_amount_percentage + " %"}
                    </td>
                </tr>
                <tr>
                    <td className="col-50">Sub-Lessee - Outside Broker</td>
                    <td className="col-50">{data.outside_broker_commission}</td>
                </tr>
            </tbody>
        );
    };

    const renderCommissions2 = () => {
        return (
            <tbody>
                <tr>
                    <td className="col-50">
                        <strong>Name</strong>
                    </td>
                    <td className="col-50">
                        <strong>Value</strong>
                    </td>
                </tr>
                <tr>
                    <td className="col-50">
                        <strong>Sub-Lessee Referral</strong>
                    </td>
                    <td className="col-50">
                        <strong>{data.lessee_referral_firm_fee_amount}</strong>
                    </td>
                </tr>
                <tr>
                    <td className="col-50">
                        <strong>Sub-Lessor Referral</strong>
                    </td>
                    <td className="col-50">
                        <strong>{data.referral_firm_fee_amount}</strong>
                    </td>
                </tr>
                <tr>
                    <td className="col-50">
                        <strong>Outside Broker</strong>
                    </td>
                    <td className="col-50">
                        <strong>{data.outside_broker_commission}</strong>
                    </td>
                </tr>
            </tbody>
        );
    };

    
    const isEstimated = isEstimatedLease(data);

    return (
        <div style={{ padding: 40, maxWidth: 900, margin: "0 auto" }}>
            <RSheetsSubleaseFormActions
                data={data}
                id={null}
                viewOnly={props.viewOnly}
                setOpenedForm={props.setOpenedForm}
                setFormDataObj={props.setFormDataObj}
                updateDataObj={props.updateDataObj}
                setActivityTracker={props.setActivityTracker}
                closeTheForm={props.closeTheForm}
                level={props.level}
                tab={props.tab}
                historyPush={props.historyPush}
                rsheetData={props.rsheetData}
                save={props.save}
            />
            <div>
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td className="col-10">
                                <img
                                    src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png"
                                    style={{ width: 60 }}
                                />
                            </td>
                            <td className="col-90">
                                <div className="text-center">
                                    <h3>
                                        ROUTING SHEET - SUBLEASE
                                        {props.rsheetData ? (
                                            props.rsheetData.title ? (
                                                <span style={{ color: "red" }}>
                                                    {" "}
                                                    - Revision {props.rsheetData.title.split("_").length}
                                                </span>
                                            ) : (
                                                ""
                                            )
                                        ) : (
                                            ""
                                        )}
                                    </h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setFieldEdit("date");
                                }}
                            >
                                {make("date")}
                            </td>
                            <td
                                className="col-60"
                                onClick={() => {
                                    setFieldEdit("project_name");
                                }}
                            >
                                {make("project_name")}
                            </td>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setFieldEdit("illi_number");
                                }}
                            >
                                {make("illi_number")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-80 no-bt"
                                onClick={() => {
                                    setFieldEdit("project_address");
                                }}
                            >
                                {make("project_address")}
                            </td>
                            {data.pm_number ? (
                                <td
                                    className="col-20 no-bt"
                                    onClick={() => {
                                        setFieldEdit("pm_number");
                                    }}
                                >
                                    {make("pm_number")}
                                </td>
                            ) : (
                                <td className="col-20 no-bt"></td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-80 no-bt bold"
                                onClick={() => {
                                    setFieldEdit("la_city_verification");
                                }}
                            >
                                Is Property Located in the County of L.A ?
                            </td>
                            <td
                                className="col-20 no-bt"
                                onClick={() => {
                                    setFieldEdit("la_city_verification");
                                }}
                            >
                                {makeCheckboxes("la_city_verification")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-30 no-bt"
                                onClick={() => {
                                    setFieldEdit("listings_procured_by");
                                }}
                            >
                                <strong>Listings Procured By : </strong>
                                {makeArray("listings_procured_by", "name")}
                            </td>
                            <td
                                className="col-30 no-bt"
                                onClick={() => {
                                    setFieldEdit("lead_source");
                                }}
                            >
                                {make("lead_source")}
                            </td>
                            <td
                                className="col-40 no-bt"
                                onClick={() => {
                                    setFieldEdit("deal_generated_by");
                                }}
                            >
                                <strong>Deal Generated By : </strong>
                                {makeCheckboxes("deal_generated_by")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("can_deal_be_submitted_to_public_relations");
                                }}
                            >
                                <strong>Can deal be submitted to Public Relations ? : </strong>
                                {makeCheckboxes("can_deal_be_submitted_to_public_relations")}
                            </td>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("can_deal_be_submitted_to_public_relations_comment");
                                }}
                            >
                                {make("can_deal_be_submitted_to_public_relations_comment")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-40 no-bt"
                                onClick={() => {
                                    setFieldEdit("illi_represented_lessor");
                                }}
                            >
                                <strong>illi Represented Sub-Lessor :</strong>
                                {makeCheckboxes("illi_represented_lessor")}
                            </td>
                            <td
                                className="col-60 no-bt"
                                onClick={() => {
                                    setFieldEdit("illi_represented_lessor_agents");
                                }}
                            >
                                <strong>Agent(s)</strong> : {makeArray("illi_represented_lessor_agents", "name")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-40 no-bt"
                                onClick={() => {
                                    setFieldEdit("illi_represented_lessee");
                                }}
                            >
                                <strong>illi Represented Sub-Lessee : </strong>
                                {makeCheckboxes("illi_represented_lessee")}
                            </td>
                            <td
                                className="col-60 no-bt"
                                onClick={() => {
                                    setFieldEdit("illi_represented_lessee_agents");
                                }}
                            >
                                <strong>Agent(s)</strong> : {makeArray("illi_represented_lessee_agents", "name")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-100 no-bt"
                                onClick={() => {
                                    setFieldEdit("type_of_lease");
                                }}
                            >
                                <strong>Deal Type : </strong>
                                {makeCheckboxes("type_of_lease")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br/>
            <br/>

            <div>
                <h4 style={{ textDecoration: "underline" }}>PROPERTY INFORMATION</h4>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-60"
                                onClick={() => {
                                    setFieldEdit("premises_address");
                                }}
                            >
                                {make("premises_address")}
                            </td>
                            <td
                                className="col-40"
                                onClick={() => {
                                    setFieldEdit("window_signs_returned");
                                }}
                            >
                                <strong>Window Sign Returned ? : </strong>
                                {makeCheckboxes("window_signs_returned")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-60 no-bt"
                                onClick={() => {
                                    setFieldEdit("unit_number");
                                }}
                            >
                                {make("unit_number")}
                            </td>
                            <td
                                className="col-40 no-bt"
                                onClick={() => {
                                    setFieldEdit("window_sign_number");
                                }}
                            >
                                {make("window_sign_number")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-20 no-bt"
                                onClick={() => {
                                    setFieldEdit("city");
                                }}
                            >
                                {make("city")}
                            </td>
                            <td
                                className="col-20 no-bt"
                                onClick={() => {
                                    setFieldEdit("zip");
                                }}
                            >
                                {make("zip")}
                            </td>
                            <td
                                className="col-60 no-bt"
                                onClick={() => {
                                    setFieldEdit("sign_removal_requested");
                                }}
                            >
                                <strong>Sign Removal Requested? : </strong>
                                {makeCheckboxes("sign_removal_requested")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-60 no-bt"
                                onClick={() => {
                                    setFieldEdit("county");
                                }}
                            >
                                {make("county")}
                            </td>
                            <td
                                className="col-40 no-bt"
                                onClick={() => {
                                    setFieldEdit("loopnet_marketing_updated");
                                }}
                            >
                                <strong>Loopnet/Marketing Updated? : </strong>
                                {makeCheckboxes("loopnet_marketing_updated")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

			<div className="the-table">
				<table>
					<tbody>
						<tr>
							<td className="col-100 no-bt" onClick={() => { setFieldEdit("property_type") }}>
								<strong>Use Type : </strong>
								{makeCheckboxes("property_type")}
							</td>
						</tr>
					</tbody>
				</table>
          	</div>

            <br/>
            <br/>
            
			<div>
                <h4 style={{ textDecoration: "underline" }}>LEASE INFORMATION</h4>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50"
                                onClick={() => {
                                    setFieldEdit("business_name");
                                }}
                            >
                                {make("business_name")}
                            </td>
                            <td
                                className="col-50"
                                onClick={() => {
                                    setFieldEdit("date_lease_signed");
                                }}
                            >
                                {make("date_lease_signed")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("type_of_business");
                                }}
                            >
                                {make("type_of_business")}
                            </td>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("occupancy_date");
                                }}
                            >
                                {make("occupancy_date")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("lease_start_date");
                                }}
                            >
                                {make("lease_start_date")}
                            </td>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("option_renewal");
                                }}
                            >
                                <strong>Option Renewal</strong>
                                {makeCheckboxes("option_renewal")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("lease_end_date");
                                }}
                            >
                                {make("lease_end_date")}
                            </td>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("option_commission");
                                }}
                            >
                                <strong>Option Commission</strong>
                                {makeCheckboxes("option_commission")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("total_sf_leased");
                                }}
                            >
                                {make("total_sf_leased")}
                            </td>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("lease_type");
                                }}
                            >
                                <strong>Lease Type</strong>
                                {makeCheckboxes("lease_type")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {!hideStartingRentPerSqft && <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="col-50 no-bt" onClick={() => {}}>
                                <div>
                                    <strong>Starting Rent Per Sqft.</strong>{" "}
                                    {calculateStartingRent(data) ? <span>$ {calculateStartingRent(data)}</span> : ""}
                                </div>
                            </td>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("rent_commencement");
                                }}
                            >
                                {make("rent_commencement")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>}
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("early_possession");
                                }}
                            >
                                <strong>Early Possession</strong>
                                {makeCheckboxes("early_possession")}
                            </td>
                            <td className="col-50 no-bt" onClick={() => {}}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("contingency");
                                }}
                            >
                                <div>
                                    <strong>Contingency</strong> {makeCheckboxes("contingency")}
                                </div>
                            </td>
                            {data.contingency === "Yes" ? (
                                <td
                                    className="col-50 no-bt"
                                    onClick={() => {
                                        setFieldEdit("contingency_detail");
                                    }}
                                >
                                    {make("contingency_detail")}
                                </td>
                            ) : (
                                <td className="col-50 no-bt">
                                    <strong>Contingency Detail:</strong>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("flat_fee_commission");
                                }}
                            >
                                <div>
                                    <strong>Flat Fee Commission</strong> {makeCheckboxes("flat_fee_commission")}
                                </div>
                            </td>
                            {data.flat_fee_commission === "Yes" ? (
                                <td
                                    className="col-50 no-bt"
                                    onClick={() => {
                                        setFieldEdit("flat_fee_commission_amount");
                                    }}
                                >
                                    {make("flat_fee_commission_amount")}
                                </td>
                            ) : (
                                <td className="col-50 no-bt">
                                    <strong>Flat Fee Commission Amount:</strong> N/A
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
			<div className='the-table'>
				<table>
					<tbody>
						<tr>
							<td
								className='col-50 no-bt'
								onClick={() => {
									setFieldEdit('rate_type');
								}}
							>
								<div>
									<strong>Rental Increases</strong> {makeCheckboxes('rate_type')}
								</div>
							</td>
							{data.rate_type === 'Fixed Percentage' ? (
								<td
									className='col-50 no-bt'
									onClick={() => {
										setFieldEdit('rate_type_cpi_percentage');
									}}
								>
									{make('rate_type_cpi_percentage')}
								</td>
							) : (
								<td className='col-50 no-bt'>
									<strong>Percentage Rate:</strong> N/A
								</td>
							)}
						</tr>
					</tbody>
				</table>
			</div>
            {shouldShowRanges && (data.abated_rent_ranges || data.lease_term) && (
                <div className="the-table">
                    <table>
                        <tbody>
                            <tr>
                                <td
                                    className="col-50 no-bt align-top-left"
                                    onClick={() => {
                                        //setFieldEdit("lease_term");
                                    }}
                                >
                                    <strong>Lease Term : </strong>
                                    {!isEstimated && diffObj ? (
                                        <div>
                                            {diffObj.years} Years, {diffObj.months} Months, {diffObj.days} Days
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {isEstimated && get(data, "lease_length", "")
                                        ? `${get(data, "lease_length", "")} Months`
                                        : ""}
                                    <div>
                                        <TermElement 
                                            calcs={calcs}
                                        />
                                    </div>
                                </td>
                                <td
                                    className="col-50 no-bt align-top-left"
                                    onClick={() => {
                                        //setFieldEdit("abated_rent_ranges");
                                    }}
                                >
                                    <strong>Abated Rent : </strong> {data.abated_rent_ranges.length > 0 ? "Yes" : "No"}
                                    <div>
                                        <AbatedElement 
                                            calcs={calcs}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}
            {shouldShowRanges && (data.abated_rent_ranges || data.lease_term) && (
                <div className="the-table">
                    <table>
                        <tbody>
                            <tr>
                                <td
                                    className="col-100 no-bt align-top-left"
                                    onClick={() => {
                                        //setFieldEdit("lease_term");
                                    }}
                                >
                                    <div>
                                        <div><RentTotals calcs={calcs} /></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            )}

            {!shouldShowRanges && <CommissionAdjustmentTable data={data} />}

            <br />
            <br />
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSORS</h4>
                            </td>
                            <td className="col-5 no-bt no-bb no-br"></td>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSEE</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* {Lessee and Lessor} */}
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-45"
                                style={{ verticalAlign: "top" }}
                                onClick={() => {
                                    setFieldEdit("lessors");
                                }}
                            >
                                {renderLessors()}
                            </td>
                            <td className="col-5 no-bt no-bb"></td>
                            <td
                                className="col-45"
                                style={{ verticalAlign: "top" }}
                                onClick={() => {
                                    setFieldEdit("lessees");
                                }}
                            >
                                {renderLessees()}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <br />

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSOR CONTACTS</h4>
                            </td>
                            <td className="col-5 no-bt no-bb no-br"></td>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSEE CONTACTS</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            {data.lessor_contacts && (
                                <td
                                    className="col-45"
                                    onClick={() => {
                                        setFieldEdit("lessor_contacts");
                                    }}
                                >
                                    {makeArray("lessor_contacts", "multi-contact")}
                                    {data.lessor_contacts.length === 0 && (
                                        <div className="text-center">
                                            <em>N/A</em>
                                        </div>
                                    )}
                                </td>
                            )}
                            <td className="col-5 no-bt no-bb"></td>
                            {data.lessee_contacts && (
                                <td
                                    className="col-45"
                                    onClick={() => {
                                        setFieldEdit("lessee_contacts");
                                    }}
                                >
                                    {makeArray("lessee_contacts", "multi-contact")}
                                    {data.lessee_contacts.length === 0 && (
                                        <div className="text-center">
                                            <em>N/A</em>
                                        </div>
                                    )}
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />
            <div>
                <h4 style={{ textDecoration: "underline" }}>COMMISSION DISTRIBUTION SUB-LESSOR</h4>
            </div>
            {hasLessorAssociate && <div className="the-table">
                <table>{renderCommissionsLessor()}</table>
            </div>}
            <div className="the-table mt-10">
                <table>{renderCommissionsOtherLessor()}</table>
            </div>
            <br />
            <br />
            <div>
                <h4 style={{ textDecoration: "underline" }}>COMMISSION DISTRIBUTION SUB-LESSEE</h4>
            </div>
            {hasLesseeAssociate && <div className="the-table">
                <table>{renderCommissionsLessee()}</table>
            </div>}
            <div className="the-table mt-10">
                <table>{renderCommissionsOtherLessee()}</table>
            </div>
            <br />

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSOR - OUTSIDE BROKER</h4>
                            </td>
                            <td className="col-5 no-bt no-bb no-br"></td>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSEE - OUTSIDE BROKER</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {[
                "company_name",
                "name",
                "lastname",
                "address1",
                "city",
                "state",
                "zip",
                "contact",
                "main_phone",
                "fax",
                "email",
                "taxid",
                "w9",
            ].map((ff, i) => {
                const r = `lessor_outside_broker_${ff}`;
                const o = `outside_broker_${ff}`;
                const add = i === 0 ? `` : `no-bt`;
                if (ff === "w9") {
                    return (
                        <div className="the-table" key={`${ff}-rto`}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td
                                            className={`col-45 ${add}`}
                                            onClick={() => {
                                                setFieldEdit(r);
                                            }}
                                        >
                                            <strong>W9 : </strong>
                                            {makeCheckboxes(r)}
                                        </td>
                                        <td className="col-5 no-bt no-bb"></td>
                                        <td
                                            className={`col-45 ${add}`}
                                            onClick={() => {
                                                setFieldEdit(o);
                                            }}
                                        >
                                            <strong>W9 : </strong>
                                            {makeCheckboxes(o)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                }
                return (
                    <div className="the-table" key={`${ff}-rto`}>
                        <table>
                            <tbody>
                                <tr>
                                    <td
                                        className={`col-45 ${add}`}
                                        onClick={() => {
                                            setFieldEdit(r);
                                        }}
                                    >
                                        {make(r)}
                                    </td>
                                    <td className="col-5 no-bt no-bb"></td>
                                    <td
                                        className={`col-45 ${add}`}
                                        onClick={() => {
                                            setFieldEdit(o);
                                        }}
                                    >
                                        {make(o)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            })}

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSOR REFERRAL</h4>
                            </td>
                            <td className="col-5 no-bt no-bb no-br"></td>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: "underline" }}>SUB-LESSEE REFERRAL</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {[
                "name",
                "lastname",
                "address1",
                "city",
                "state",
                "zip",
                "contact",
                "main_phone",
                "fax",
                "email",
                "taxid",
                "w9",
            ].map((ff, i) => {
                const r = `referral_firm_${ff}`;
                const o = `lessee_referral_firm_${ff}`;
                const add = i === 0 ? `` : `no-bt`;
                if (ff === "w9") {
                    return (
                        <div className="the-table" key={`${ff}-rto`}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td
                                            className={`col-45 ${add}`}
                                            onClick={() => {
                                                setFieldEdit(r);
                                            }}
                                        >
                                            <strong>W9 : </strong>
                                            {makeCheckboxes(r)}
                                        </td>
                                        <td className="col-5 no-bt no-bb"></td>
                                        <td
                                            className={`col-45 ${add}`}
                                            onClick={() => {
                                                setFieldEdit(o);
                                            }}
                                        >
                                            <strong>W9 : </strong>
                                            {makeCheckboxes(o)}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    );
                }
                return (
                    <div className="the-table" key={`${ff}-rto`}>
                        <table>
                            <tbody>
                                <tr>
                                    <td
                                        className={`col-45 ${add}`}
                                        onClick={() => {
                                            setFieldEdit(r);
                                        }}
                                    >
                                        {make(r)}
                                    </td>
                                    <td className="col-5 no-bt no-bb"></td>
                                    <td
                                        className={`col-45 ${add}`}
                                        onClick={() => {
                                            setFieldEdit(o);
                                        }}
                                    >
                                        {make(o)}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                );
            })}
            <br />
            
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: 'underline' }}>OTHER LESSOR REFERRAL(S)</h4>
                            </td>
                            <td className="col-5 no-bt no-bb no-br"></td>
                            <td className="col-45 no-bt no-br no-bl no-bb">
                                <h4 style={{ textDecoration: 'underline' }}>OTHER LESSEE REFERRAL(S)</h4>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td className="col-45" onClick={() => { data.referral_firm_id && setEditOtherReferrals('lessor') }}>
                                {data?.lessor_referrals && data?.lessor_referrals.map((ref, i) => {
                                    return <div>
                                        <h4>Referral #{i + 2}</h4>
                                        {['company_name', 'name', 'lastname', 'contact_dba_name', 'address1', 'city', 'state', 'zip', 'contact', 'main_phone', 'fax', 'email', 'taxid', 'w9'].map((fld) => {
                                            const field = `referral_firm_${fld}`;
                                            return <div>
                                                <strong>{subLeaseFields[field].label}</strong> : {ref[fld]}
                                            </div>
                                        })}
                                    </div>
                                })}
                                {(!data?.lessor_referrals || data?.lessor_referrals.length === 0) && <div className="text-center"><em>N/A</em></div>}
                            </td>
                            <td className="col-5 no-bt no-bb"></td>
                                <td className="col-45" onClick={() => { data.lessee_referral_firm_id && setEditOtherReferrals('lessee') }}>
                                {data?.lessee_referrals && data?.lessee_referrals.map((ref, i) => {
                                    return <div>
                                        <h4>Referral #{i + 2}</h4>
                                        {['company_name', 'name', 'lastname', 'contact_dba_name', 'address1', 'city', 'state', 'zip', 'contact', 'main_phone', 'fax', 'email', 'taxid', 'w9'].map((fld) => {
                                            const field = `lessee_referral_firm_${fld}`;
                                            return <div>
                                                <strong>{subLeaseFields[field].label}</strong> : {ref[fld]}
                                            </div>
                                        })}
                                    </div>
                                })}
                                {(!data?.lessee_referrals || data?.lessee_referrals.length === 0) && <div className="text-center"><em>N/A</em></div>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />
            <div>
                <h4 style={{ textDecoration: "underline" }}>BILLING INFORMATION</h4>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50"
                                onClick={() => {
                                    setFieldEdit("billing_attention");
                                }}
                            >
                                {make("billing_attention")}
                            </td>
                            <td
                                className="col-50"
                                onClick={() => {
                                    setFieldEdit("billing_attention_lastname");
                                }}
                            >
                                {make("billing_attention_lastname")}
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="col-50"
                                onClick={() => {
                                    setFieldEdit("billing_company");
                                }}
                            >
                                {make("billing_company")}
                            </td>
                            <td className="col-50"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_address1");
                                }}
                            >
                                {make("billing_address1")}
                            </td>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_address2");
                                }}
                            >
                                {make("billing_address2")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-40 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_city");
                                }}
                            >
                                {make("billing_city")}
                            </td>
                            <td
                                className="col-40 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_state");
                                }}
                            >
                                {make("billing_state")}
                            </td>
                            <td
                                className="col-20 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_zip");
                                }}
                            >
                                {make("billing_zip")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-20 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_phone");
                                }}
                            >
                                {make("billing_phone")}
                            </td>
                            <td
                                className="col-20 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_fax");
                                }}
                            >
                                {make("billing_fax")}
                            </td>
                            <td
                                className="col-60 no-bt"
                                onClick={() => {
                                    setFieldEdit("billing_email");
                                }}
                            >
                                {make("billing_email")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />
            <br />
            <div>
                <h4 style={{ textDecoration: "underline" }}>COPY OF INVOICE</h4>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-100"
                                onClick={() => {
                                    setFieldEdit("copy_of_invoice");
                                }}
                            >
                                <strong>Copy of Invoice : </strong>
                                {makeCheckboxes("copy_of_invoice")}
                            </td>
                        </tr>
                        {data.copy_of_invoice.indexOf("Other") !== -1 && (
                            <tr>
                                <td
                                    className="col-100"
                                    onClick={() => {
                                        setFieldEdit("copy_of_invoice_other");
                                    }}
                                >
                                    <strong>Other : </strong>
                                    {make("copy_of_invoice_other")}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <br />
            <br />
            {data.payment_schedule && (
                <div
                    onClick={() => {
                        setFieldEdit("payment_schedule");
                    }}
                >
                    <h4 style={{ textDecoration: "underline" }}>PAYMENT SCHEDULE</h4>
                    {data.payment_schedule.length === 0 && (
                        <div
                            style={{ marginTop: 20 }}
                            onClick={() => {
                                setFieldEdit("payment_schedule");
                            }}
                        >
                            <div className="the-table">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="col-100">No Payment Schedule Entries</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div
                className="the-table"
                onClick={() => {
                    setFieldEdit("payment_schedule");
                }}
            >
                <table>
                    <tbody>
                        {data.payment_schedule.map((iv, iv_index) => {
                            if (!iv) return null;
                            if (!Array.isArray(iv)) return null;
                            return iv.map((ps, i) => {
                                return (
                                    <tr key={`ps-${i}`}>
                                        <td className="col-20">
                                            <strong>Invoice {iv_index + 1}</strong><br />
                                            <strong>Payment {i + 1}</strong>
                                        </td>
                                        <td className="col-80">
                                            <strong>Value:</strong>{" "}
                                            {shouldGetPaymentAmount(ps.option) ? formatDollar(getPaymentAmount(data, ps.option)) : ps.value}
                                            <br />
                                            <strong>Option:</strong> {ps.option} <br />
                                            {ps.misc && (
                                                <div>
                                                    <strong>Other Option:</strong> {ps.misc}
                                                </div>
                                            )}
                                            {ps.notes && <div><strong>Notes:</strong> {ps.notes}</div>}
                                        </td>
                                    </tr>
                                );
                            });
                        })}
                    </tbody>
                </table>
            </div>

            <br />
            <br />
            <div>
                <h4 style={{ textDecoration: "underline" }}>COMMENTS AND SPECIAL INSTRUCTIONS</h4>
                <div
                    style={{ marginTop: 20 }}
                    onClick={() => {
                        setFieldEdit("comments_and_special_instructions");
                    }}
                >
                    <div className="the-table">
                        <table>
                            <tbody>
                                <tr>
                                    {data.comments_and_special_instructions &&
                                    data.comments_and_special_instructions.length === 0 ? (
                                        <td className="col-100">No Comments and Special Instructions</td>
                                    ) : (
                                        <td className="col-100">{data.comments_and_special_instructions}</td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <br />
            <br />
            {data.sign_removal_requested === "Yes" && (
                <>
                    <br />
                    <br />
                    <hr />
                    <br />

                    <RSheetSignRequestForm
                        data={data}
                        setData={setData}
                        updateDataObj={updateDataObj}
                        make={make}
                        makeCheckboxes={makeCheckboxes}
                        fieldEdit={fieldEdit}
                        setFieldEditValue={setFieldEditValue}
                        setFieldEdit={setFieldEdit}
                    />
                </>
            )}

            {fieldEdit && (
                <Dialog
                    open={fieldEdit ? true : false}
                    onClose={() => setFieldEdit(null)}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <div
                        style={{
                            padding: 40,
                            margin: "0 auto",
                            width: "100%",
                            maxWidth: "1400px",
                            minHeight: 500,
                        }}
                    >
                        <div
                            className="close-btn"
                            onClick={() => {
                                setFieldEdit(null);
                            }}
                        >
                            <Close />
                        </div>
                        {subLeaseFields[fieldEdit] ? (
                            <h2>{subLeaseFields[fieldEdit].label}</h2>
                        ) : (
                            <h2></h2>
                        )}
                        {renderElement(fieldEdit)}
                        <hr />
                        <Button
                            color="primary"
                            onClick={() => {
                                setFieldEdit(null);
                            }}
                        >
                            OK
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setFieldEdit(null);
                            }}
                        >
                            CLOSE
                        </Button>
                    </div>
                </Dialog>
            )}

        {editOtherReferrals && <Dialog
            open={editOtherReferrals ? true : false}
            onClose={() => setEditOtherReferrals(null)}
            fullWidth={true}
            maxWidth="md"
        >
            <div style={{ padding: 40, margin: '0 auto', width: '100%', maxWidth: '1400px', minHeight: 500 }}>
                <div className="close-btn" onClick={() => { setEditOtherReferrals(null) }}><Close /></div>
                <OtherReferrals data={data} setData={setData} title={`OTHER ${editOtherReferrals.toUpperCase()} REFERRAL(S)`} name={`${editOtherReferrals}_referrals`} />
                <Button color="primary" onClick={() => { setEditOtherReferrals(null) }}>OK</Button>
                <Button color="white" onClick={() => { setEditOtherReferrals(null) }}>CLOSE</Button>
            </div>
        </Dialog>}

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        error: state.rs.error,
        success: state.rs.success,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createRsheet: (data) => {
            dispatch(actions.createRsheet(data));
        },
        getRsheets: (params) => {
            dispatch(actions.getRsheets(params));
        },
        updateRsheet: (params) => {
            dispatch(actions.updateRsheet(params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsSubleaseFormFull);
