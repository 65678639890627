import { put, call } from 'redux-saga/effects';
import axios from '../axios/axios-userperms';
import axios2 from '../axios/axios-user-tokened';
import * as actions from '../actions/userperms';

export function* bulkSyncSaga(action) { // saga for getting data from GMP endpoint
    
    // for (var i = 0; i < action.users.length; i++) {
      yield put(actions.bulkSyncStart());
      try {
        const response = yield axios2.post('/bulksync', action.users);
        yield put(actions.bulkSyncSuccess({
          queue: response.data.queue,
          message: response.data.message
        }));
      } catch (err) {
        
        yield put(actions.bulkSyncFail(err));
      }
    // }

}

function syncAPI2(users) {
  return axios2.post('/bulksync', users);
}

function syncAPI(user) {
  return axios2.post('/sync', user);
}

export function* syncUsersSaga(action) { // saga for getting data from GMP endpoint
    
    for (var i = 0; i < action.users.length; i++) {
      yield put(actions.syncUserStart());
      try {
        const response = yield call(syncAPI, action.users[i]);
        yield put(actions.syncUserSuccess({
          queue: i+1,
          message: response.data.message
        }));
      } catch (err) {
        
        yield put(actions.syncUserFail(err));
      }
    }
}

export function* getUsersSaga(action) { // saga for getting data from GIN endpoint
    yield put(actions.getUsersStart());
    
    try {
        const response = yield axios.get('/users');
        const userList = response.data;
        
        yield put(actions.getUsersSuccess(userList));
    }
    catch (err) {
        
        yield put(actions.getUsersFail(err));
    }
}

export function* getUsersFilteredSaga(action) { // saga for getting data from GIN endpoint
    yield put(actions.getUsersFilteredStart());
    
    try {
        const response = yield axios.post('/users_filtered', {
          permsList: action.permsList
        });
        const userList = response.data;
        
        yield put(actions.getUsersFilteredSuccess(userList));
    }
    catch (err) {
        
        yield put(actions.getUsersFilteredFail(err));
    }
}

export function* getUserSaga(action) {
  yield put(actions.getUserStart());
  
  try {
      const response = yield axios.get('/user/'+action.user_id);
      const userPerms = response.data;
      yield put(actions.getUserSuccess(userPerms));
  }
  catch (err) {
      
      yield put(actions.getUserFail(err));
  }
}

export function* getUserByNameSaga(action) {
  yield put(actions.getUserByNameStart());
  
  try {
      const response = yield axios.get('/username/'+action.username);
      const userPerms = response.data;
      yield put(actions.getUserByNameSuccess(userPerms));
  }
  catch (err) {
      
      yield put(actions.getUserByNameFail(err));
  }
}

export function* saveUserPermsSaga(action) {
  yield put(actions.saveUserPermsStart());
  
  try {
    const response = yield axios.post('/user', action.obj);
    const resp = response.data;
    yield put(actions.saveUserPermsSuccess(resp));
  }
  catch (err) {
    
    yield put(actions.saveUserPermsFail(err));
  }
}

function bulkCheckAPI(user_id) {
  return axios.get('/user/'+user_id);
}

function bulkAssignAPI(saveUserObj) {
  return axios.post('/user', saveUserObj);
}

export function* bulkAssignSaga(action) { // saga for getting data from GMP endpoint
    
    for (var i = 0; i < action.userList.length; i++) {
      yield put(actions.bulkAssignStart());
      try {
        const response = yield call(bulkCheckAPI, action.userList[i].id);
        
        const userPerms = response.data;
        
        let add = [], remove = [];
        action.perms.forEach((p) => {
          let found = false;
          userPerms.forEach(u => {
            // check if user perm is already existent
            if (u.perm_id == p.perm_id && u.db_id == p.db_id) {
              found = true;
            }
          });
          if (!found) {
            add.push({
              perm_id: p.perm_id,
              db_id: p.db_id
            })
          }
        });

        action.removes.forEach((p) => {
          let found = false;
          userPerms.forEach(u => {
            // check if user perm is already existent
            if (u.perm_id == p.perm_id && u.db_id == p.db_id) {
              found = true;
              remove.push(u.id);
            }
          });
        });

        const saveUserObj = {
          user_id: action.userList[i].id,
          user_perms: {
            add,
            remove
          }
        }

        if (add.length > 0 || remove.length > 0) {
          const response2 = yield call(bulkAssignAPI, saveUserObj);
          const resp = response2.data;
          
        }
        yield put(actions.bulkAssignSuccess(action.userList[i].name));
        if (i === (action.userList.length-1)) {
          
          yield put(actions.bulkAssignEnd());
        }
      } catch (err) {
        
        yield put(actions.bulkAssignFail(err));
      }
    }
}

export function* maintenanceModeSaga(action) {
  
  try {
      const response = yield axios.post('/maintenance', {
        value: action.value
      });
      const res = response.data;
      
      yield put(actions.maintenanceModeSuccess({
        value: action.value
      }));
  }
  catch (err) {
      
  }
}

export function* checkMaintenanceModeSaga(action) {
  try {
      const response = yield axios.get('/maintenance_check');
      const res = response.data;
      yield put(actions.checkMaintenanceModeSuccess(res));
  }
  catch (err) {
      
  }
}

export function* notifyMailSaga(action) {
  
  try {
      const response = yield axios.post('/notify_mail', {
        to: action.to,
        subject: action.subject,
        message: action.message
      });
      const res = response.data;
      
  }
  catch (err) {
      
  }
}
