import { put } from 'redux-saga/effects';
import axios from '../axios/axios-commission-splits';
import * as actions from '../actions/commission_splits';

export function* getCommissionSplitsSaga(action) {
    yield put(actions.getCommissionSplitsStart());
    try {
        if (action.data?.id) {
            const own = action.data.own ? '&own=true' : '';
            const response = yield axios.get('/entries?id='+action.data+own);
            yield put(actions.getCommissionSplitsSuccess(response.data));
        } else {
            const own = action.data.own ? '&own=true' : '';
            const response = yield axios.get('/entries?status='+action.data.status+own);
            yield put(actions.getCommissionSplitsSuccess(response.data));
        }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCommissionSplitsFail(e.response.data));
            }
          }
        yield put(actions.getCommissionSplitsFail(e));
    }
}

export function* createCommissionSplitSaga(action) {
    yield put(actions.createCommissionSplitStart());
    try {
        const response = yield axios.post('/entry', action.data);
        yield put(actions.createCommissionSplitSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createCommissionSplitFail(e.response.data));
            }
          }
        yield put(actions.createCommissionSplitFail(e));
    }
}

export function* deleteCommissionSplitSaga(action) {
    yield put(actions.deleteCommissionSplitStart());
    try {
        const response = yield axios.delete('/entry?id='+action.data);
        yield put(actions.deleteCommissionSplitSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteCommissionSplitFail(e.response.data));
            }
          }
        yield put(actions.deleteCommissionSplitFail(e));
    }
}

export function* updateCommissionSplitSaga(action) {
    yield put(actions.updateCommissionSplitStart());
    try {
        const response = yield axios.put('/entry', action.data);
        yield put(actions.updateCommissionSplitSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateCommissionSplitFail(e.response.data));
            }
          }
        yield put(actions.updateCommissionSplitFail(e));
    }
}

export function* getCommissionSplitsPropertyExistenceSaga(action) {
    yield put(actions.getCommissionSplitsPropertyExistenceStart());
    try {
        const response = yield axios.get('/entries?property_id='+action.data);
        yield put(actions.getCommissionSplitsPropertyExistenceSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCommissionSplitsPropertyExistenceFail(e.response.data));
            }
          }
        yield put(actions.getCommissionSplitsPropertyExistenceFail(e));
    }
}

export function* createCommissionSplitsHistorySaga(action) {
    yield put(actions.createCommissionSplitsHistoryStart());
    try {
        for (let i = 0; i < action.data.length; i++) {
            const ad = action.data[i];
            yield axios.post('/history', ad);
        }
        if (action.notifications) {
          for (let i = 0; i < action.notifications.length; i++) {
            const an = action.notifications[i];
            yield axios.post('/send_notification', an);
          }
        }
        yield put(actions.createCommissionSplitsHistorySuccess('Success'));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createCommissionSplitsHistoryFail(e.response.data));
            }
          }
        yield put(actions.createCommissionSplitsHistoryFail(e));
    }
}

export function* getCommissionSplitsHistorySaga(action) {
    yield put(actions.getCommissionSplitsHistoryStart());
    try {
        const response = yield axios.get('/history?cs_id='+action.data);
        yield put(actions.getCommissionSplitsHistorySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCommissionSplitsHistoryFail(e.response.data));
            }
          }
        yield put(actions.getCommissionSplitsHistoryFail(e));
    }
}

export function* getCommissionSplitsReviewersSaga(action) {
    yield put(actions.getCommissionSplitsReviewersStart());
    try {
        const response = yield axios.get('/reviewers');
        yield put(actions.getCommissionSplitsReviewersSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCommissionSplitsReviewersFail(e.response.data));
            }
          }
        yield put(actions.getCommissionSplitsReviewersFail(e));
    }
}

export function* createCommissionSplitsPdfSaga(action) {
  yield put(actions.createCommissionSplitsPdfStart());
  try {
      const response = yield axios.post('/pdf', action.data);
      yield put(actions.createCommissionSplitsPdfSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createCommissionSplitsPdfFail(e.response.data));
          }
        }
      yield put(actions.createCommissionSplitsPdfFail(e));
  }
}

export function* getCommissionSplitsApproversSaga(action) {
  yield put(actions.getCommissionSplitsApproversStart());
  try {
      const response = yield axios.get('/approvers');
      yield put(actions.getCommissionSplitsApproversSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getCommissionSplitsApproversFail(e.response.data));
          }
        }
      yield put(actions.getCommissionSplitsApproversFail(e));
  }
}

export function* getCsSigningUrlSaga(action) {
  yield put(actions.getCsSigningUrlStart());
  try {
      const response = yield axios.get('/signing_url?id='+action.data);
      yield put(actions.getCsSigningUrlSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getCsSigningUrlFail(e.response.data));
          }
        }
      yield put(actions.getCsSigningUrlFail(e));
  }
}

export function* getCommissionSplitSaga(action) {
  yield put(actions.getCommissionSplitStart());
  try {
      const response = yield axios.get('/entry?id='+action.data);
      yield put(actions.getCommissionSplitSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getCommissionSplitFail(e.response.data));
          }
        }
      yield put(actions.getCommissionSplitFail(e));
  }
}

