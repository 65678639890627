import React, { useEffect, useMemo, useState } from "react";
import { concat, every, flatMap, flattenDeep, get, some, sum } from "lodash";
import Button from "../../../components/CustomButtons/Button";
import { clone, currencyToNumber, formatDollar, get2DecimalRound } from "../../../shared/utility";
import PaymentSchedule from "./PaymentSchedule";
import { IconButton } from "@material-ui/core";
import { useRef } from "react";
import {
    calculateAbatedRanges,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    calculateTermsTotal,
    getFlatFeeCommission,
    getPaymentAmount,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    newYearlyCalculation,
    shouldGetPaymentAmount,
} from "../RSheetsLogic";

const InvoicePayment = (props) => {
    const { updateDataObj, field, data, commissionValue = undefined } = props;
    const [scrollRef, setScrollRef] = useState(null);
    const ref = useRef(null);

    const payments = data[field] ? data[field] : [];
    const lease_term = get(data, "lease_term", []);
    const abated_rent_ranges = get(data, "abated_rent_ranges", []);
    const isEstimated = isEstimatedLease(data);

    const flatFeeEnabled = isFlatFeeCommissionEnabled(data) || commissionValue;
    const flatFeeCommission = currencyToNumber(commissionValue) || getFlatFeeCommission(data);
	const isActualDays = get(data, "commission_based_off", "") === "Actual Days";

    // Range calculation happens everywhere, so minimize the calculation as much as possible
    const total = useMemo(() => {
        if (flatFeeEnabled) {
            return flatFeeCommission + calculateAdjustedCommission(data);
        } else {
            const abated_ranges = isEstimated
                ? calculateEstimatedAbatedRanges({ lease_term, abated_rent_ranges })
                : calculateAbatedRanges({ lease_term, abated: abated_rent_ranges });
            const ranges = lease_term.flatMap((item) => item.ranges);
            const combinedRanges = concat(abated_ranges, ranges);

            if (isEstimated) {
                const totalCalc = calculateEstimatedTermsTotal(combinedRanges)
                return parseFloat(totalCalc.totalCommission) + calculateAdjustedCommission(data);
            } else {
                const grossCommission = newYearlyCalculation(ranges, false, isActualDays).commission;
                const abatedCommission = newYearlyCalculation(abated_ranges, false, isActualDays).commission;

                return grossCommission - abatedCommission + calculateAdjustedCommission(data);
            }
        }
    }, [lease_term, abated_rent_ranges, flatFeeEnabled, flatFeeCommission]);

    // Refactor payments for old data
    useEffect(() => {
        if (payments.length > 0 && !Array.isArray(payments[0])) {
            const newPayments = [clone(payments)];
            updateDataObj(field, newPayments);
        }
    }, []);

    // Scroll
    useEffect(() => {
        if (scrollRef) {
            handleScrollClick();
            setScrollRef(null);
        }
    }, [scrollRef]);

    const handleScrollClick = () => {
        ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
        });
    };

    const canRender = payments.length === 0 || (payments.length > 0 && Array.isArray(payments[0]));

    const renderPayments = () => {
        return payments.map((pm, index) => {
            return (
                <div className="boxed" key={index} ref={scrollRef === index ? ref : null}>
                    <div style={{ textAlign: "end" }}>
                        <span className="red-text bold">Invoice #{index + 1}</span>
                        <IconButton onClick={() => handleDeleteInvoice(index)}>
                            <i className="fas fa-trash-alt red-text"></i>
                        </IconButton>
                    </div>
                    <PaymentSchedule
                        data={data}
                        field={field}
                        handleAddNewPayment={handleAddNewPayment(index)}
                        handleUpdatePayment={handleUpdatePayment(index)}
                        handleDeletePayment={handleDeletePayment(index)}
                        iv_index={index}
                        payments={pm}
                        invoiceList={payments}
                        totalPayment={totalPayment}
                        total={total}
                        updateDataObj={updateDataObj}
                    />
                </div>
            );
        });
    };

    const handleAddNewPayment = (iv_index) => (pm) => {
        const newPms = clone(payments);
        newPms[iv_index] = newPms[iv_index].concat(pm);
        updateDataObj(field, newPms);
    };

    const handleUpdatePayment = (iv_index) => (pm_index, newPm) => {
        const newPms = clone(payments);
        newPms[iv_index][pm_index] = newPm;
        updateDataObj(field, newPms);
    };

    const handleDeletePayment = (iv_index) => (pm_index) => {
        const newPms = clone(payments);
        newPms[iv_index].splice(pm_index, 1);

        const currentOptions = flattenDeep(newPms).map((pm) => pm.option);

        // When remove payment with First Half, make payment with Second Half EMPTY
        if (
            every(currentOptions, (item) => !item.toLowerCase().includes("first half")) &&
            some(currentOptions, (item) => item.toLowerCase().includes("second half"))
        ) {
            for (let i in newPms) {
                for (let j in newPms[i]) {
                    if (newPms[i][j].option && newPms[i][j].option.toLowerCase().includes("second half")) {
                        newPms[i][j].option = "";
                        newPms[i][j].value = "";
                    }
                }
            }
        }

        updateDataObj(field, newPms);
    };

    const handleAddInvoice = () => {
        const newPms = clone(payments);
        newPms.push([]);
        updateDataObj(field, newPms);
        setScrollRef(newPms.length - 1);
    };

    const handleDeleteInvoice = (iv_index) => {
        const newPms = clone(payments);
        newPms.splice(iv_index, 1);

        const currentOptions = flattenDeep(newPms).map((pm) => pm.option);

        // When remove payment with First Half, make payment with Second Half EMPTY
        if (
            every(currentOptions, (item) => !item.toLowerCase().includes("first half")) &&
            some(currentOptions, (item) => item.toLowerCase().includes("second half"))
        ) {
            for (let i in newPms) {
                for (let j in newPms[i]) {
                    if (newPms[i][j].option && newPms[i][j].option.toLowerCase().includes("second half")) {
                        newPms[i][j].option = "";
                    }
                }
            }
        }

        updateDataObj(field, newPms);
    };

    // Calculate total payments from ALL invoices
    const totalPayment = sum(flatMap(payments, (item) => item.map((i) => {
        const shouldGetPayment = shouldGetPaymentAmount(i.option);
        if (shouldGetPayment) {
            return getPaymentAmount(data, i.option);
        } else {
            return currencyToNumber(i.value);
        }
    })));

	const commissionRemaining = formatDollar(parseFloat(get2DecimalRound(total) - get2DecimalRound(totalPayment)));
	const commissionRemainingClass = commissionRemaining == '$0.00' ? 'green' : 'red'

    return (
        <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
            <div style={{display: 'flex', alignItems: 'canter'}}>
                <Button disabled={totalPayment >= total} color="primary" onClick={handleAddInvoice}>
                    <i className="fas fa-plus"></i> ADD INVOICE
                </Button>

                <div style={{marginLeft: '20px'}}>
                    <div className='red'>Total Commission: {formatDollar(total)}</div>
					<div className={commissionRemainingClass}>Total Commission Remaining to bill: {commissionRemaining}</div>
                </div>
            </div>

            {canRender && (
                <div style={{ padding: 20 }}>
                    {payments.length === 0 && (
                        <li className="text-center">
                            <em>No entries</em>
                        </li>
                    )}
                    {renderPayments()}
                </div>
            )}
        </div>
    );
};

export default InvoicePayment;
