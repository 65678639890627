import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import { get } from "lodash";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { INVOICE_STATUS } from "./InvoiceObj";
import { downloadPDF, printPdf } from 'shared/utility';
import InvoiceApplyPaymentDialog from './InvoiceApplyPaymentDialog';
import InvoicePaymentHistory from './InvoicePaymentHistory';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const InvoicePdfModal = (props) => {
    const {
        // canApprove,
        viewOnly,
        pdfData,
        open,
        close,
        action,
        handleDecline,
        handleApprove,
        handleFinalApprove,
        invoice,
    } = props;

    const [openApplyPayment, setOpenApplyPayment] = useState(false);
    const [openPaymentHistory, setOpenPaymentHistory] = useState(false);

    const handlePaymentFormOpen = () => setOpenApplyPayment(true);
    const handlePaymentFormClose = () => setOpenApplyPayment(false);

    const handlePaymentHistoryOpen = () => setOpenPaymentHistory(true);
    const handlePaymentHistoryClose = () => setOpenPaymentHistory(false);

    const canApprove = get(invoice, "status", null) === INVOICE_STATUS.PENDING;

    const isApproved = get(invoice, 'status') === INVOICE_STATUS.APPROVED
    
    return (
        <div className="parentable">
            <Dialog
                open={open}
                onClose={close}
                keepMounted
                maxWidth={false}
                fullWidth={false}
            >
                <div
                    className="close-btn"
                    onClick={() => {
                        close();
                    }}
                >
                    <Close />
                </div>
                <div style={{ margin: 20 }}>
                    <InvoicePdfViewer pdfData={pdfData} />
                    <br />
                    <div className="text-center bottom-dweller">
                        <Button
                            color="primary"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                printPdf(pdfData.data);
                            }}
                        >
                            PRINT PDF
                        </Button>
                        <Button
                            color="white"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                downloadPDF(pdfData.data, `invoice-entry-${new Date().getTime()}.pdf`);
                            }}
                        >
                            DOWNLOAD PDF
                        </Button>
                        {canApprove && (
                            <React.Fragment>
                                {handleApprove && <Button
                                    color="danger"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        handleApprove();
                                        close();
                                    }}
                                >
                                    APPROVE
                                </Button>}
                                {handleDecline && <Button
                                    color="white"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        handleDecline();
                                        close();
                                    }}
                                >
                                    DECLINE
                                </Button>}
                            </React.Fragment>
                        )}
                        {isApproved && (
                            <Button
                            color="rose"
                            style={{ marginRight: 20 }}
                            onClick={handlePaymentHistoryOpen}
                        >
                            VIEW PAYMENTS
                        </Button>
                        )}
                    </div>
                </div>
            </Dialog>

            {isApproved && openPaymentHistory && (
                <InvoicePaymentHistory invoice={invoice} open={openPaymentHistory} close={handlePaymentHistoryClose} />
            )}
        </div>
    );
};

const InvoicePdfViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (dat) => {
        const { numPages } = dat;
        
        setNumPages(numPages);
    };

    const renderNav = () => {
        return (
            <div className="text-center">
                <div>
                    Page {pageNumber} of {numPages}
                </div>
                <hr />
                <Button
                    onClick={() => {
                        setPageNumber(pageNumber - 1);
                    }}
                    disabled={pageNumber === 1 ? true : false}
                    className="mr-20"
                >
                    <i className="fas fa-arrow-left"></i>
                </Button>
                <Button
                    onClick={() => {
                        setPageNumber(pageNumber + 1);
                    }}
                    disabled={pageNumber === numPages ? true : false}
                >
                    <i className="fas fa-arrow-right"></i>
                </Button>
            </div>
        );
    };

    
    return (
        <Document file={props.pdfData} onLoadSuccess={onDocumentLoadSuccess}>
            {renderNav()}
            <div className="light-border">
                <Page pageNumber={pageNumber} width={1000} />
            </div>
            {renderNav()}
        </Document>
    );
};

export default InvoicePdfModal;
