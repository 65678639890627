import * as actionTypes from './actionTypes';

// CREATE_FILE_UPLOAD 

export const createFileUpload = (data) => {
    return {
        type: actionTypes.CREATE_FILE_UPLOAD,
        data
    }
}

export const createFileUploadStart = () => {
    return {
        type: actionTypes.CREATE_FILE_UPLOAD_START
    }
}

export const createFileUploadSuccess = (response) => {
    return {
        type: actionTypes.CREATE_FILE_UPLOAD_SUCCESS,
        response
    }
}

export const createFileUploadFail = (err) => {
    return {
        type: actionTypes.CREATE_FILE_UPLOAD_FAIL,
        err
    }
}

// GET_FILE_UPLOAD 

export const getFileUpload = (data) => {
    return {
        type: actionTypes.GET_FILE_UPLOAD,
        data
    }
}

export const getFileUploadStart = () => {
    return {
        type: actionTypes.GET_FILE_UPLOAD_START
    }
}

export const getFileUploadSuccess = (response) => {
    return {
        type: actionTypes.GET_FILE_UPLOAD_SUCCESS,
        response
    }
}

export const getFileUploadFail = (err) => {
    return {
        type: actionTypes.GET_FILE_UPLOAD_FAIL,
        err
    }
}

// GET_FILES_DATA 

export const getFilesData = (data) => {
    return {
        type: actionTypes.GET_FILES_DATA,
        data
    }
}

export const getFilesDataStart = () => {
    return {
        type: actionTypes.GET_FILES_DATA_START
    }
}

export const getFilesDataSuccess = (response) => {
    return {
        type: actionTypes.GET_FILES_DATA_SUCCESS,
        response
    }
}

export const getFilesDataFail = (err) => {
    return {
        type: actionTypes.GET_FILES_DATA_FAIL,
        err
    }
}