import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { connect } from 'react-redux';
import ReactTable from "react-table";
import * as actions from '../../store/actions';
import GridContainer from "../../components/Grid/GridContainer.jsx";
import GridItem from "../../components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import Button2 from '@material-ui/core/Button';
import { FadeLoader, PulseLoader } from 'react-spinners';
import SweetAlert from "react-bootstrap-sweetalert";
import TagsInput from "react-tagsinput";

import CustomMultipleSelect from 'components/CustomSelect/CustomMultipleSelect';
import CustomSelect from 'components/CustomSelect/CustomMultipleSelect';
import { permsList, permsDict } from 'shared/permsObj';
import { arraysEqual } from 'shared/utility';
import calculateFilteredUserList from './calculateFilteredUserList';

const createForFilter = (filterObj, DBs) => {
  let obj = {};
  Object.keys(filterObj).forEach((fo) => {
    obj[fo] = filterObj[fo].map((dbname) => {
      return DBs[dbname];
    });
  });
  return obj;
}

class UserList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null
    }
  }

  generateFilters = () => {
    const filterObj = this.props.filterObj;
    const filters = Object.keys(filterObj).map((perm_id,i) => {
      const p = permsDict[perm_id];
      return (
        <span
          className="blue-pill"
          key={`fii${i}`}
          onClick={() => {
            
          }}
        >
          {p.main} - {p.subgroup} - {p.name}
        </span>
      )
    });
    return filters;
  }

  componentDidMount() {
    this.props.getUsers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.userBulkFilterPerms !== this.props.userBulkFilterPerms || prevProps.userBulkFilterList != this.props.userBulkFilterList || prevProps.filterObj !== this.props.filterObj) {
      
      this.props.setFilteredUserList(calculateFilteredUserList(this.props.filterObj, this.props.DBs, this.props.userBulkFilterPerms, this.props.userList));
    }
  }

  render() {
    const userList = this.props.userList;
    

    let offices = [],
        departments = [];

    userList.forEach((u) => {
      if (offices.indexOf(u.office) === -1) {
        offices.push(u.office);
      }
      if (departments.indexOf(u.department) === -1) {
        departments.push(u.department);
      }
    });
    return (
    <div className="table-adjust2">
      <ReactTable
        minRows={0}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e) => {
                this.setState({
                  selected: rowInfo.index
                });
                
                if (rowInfo) {
                  if (rowInfo.row) {
                    if (rowInfo.row._original) {
                      const usr = rowInfo.row._original;
                      this.props.chooseUser({
                        id: usr.id,
                        name: usr.name
                      });
                    }
                  }
                }
              },
              style: {
                background: rowInfo.index === this.state.selected ? '#da3b2f' : 'white',
                color: rowInfo.index === this.state.selected ? 'white' : 'black'
              },
            }
          } else {
            return {}
          }
        }}
        data={
            userList.map((usr, key) => { // map users to table rows
                return ({
                    id: usr.id,
                    name: usr.name,
                    office: usr.office ? usr.office.substring(1) : '',
                    email: usr.mail
                })
            })
        }
        filterable
        columns={[
            {
                Header: "Name",
                accessor: "name",
                filterMethod: (filter, row) => {
                  if (!filter.value) return true;
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      if (!row[filter.id]) return false;
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            },
            {
                Header: "Group",
                accessor: "office",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><CustomMultipleSelect
                        options={offices}
                        label={''}
                        choose={onChange}
                    /></div>
                }
            },
            {
                Header: "Email",
                accessor: "email",
                filterMethod: (filter, row) => {
                  var filterValue = filter.value.toLowerCase();
                  if (filterValue.length > 0) {
                      return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                  } else {
                      return true;
                  }
                }
            },
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom
        className="-striped -highlight"
    >
    {(state, makeTable, instance) => {
        let recordsInfoText = "";

        const { filtered, pageRows, pageSize, sortedData, page } = state;

        if (sortedData && sortedData.length > 0) {
          let isFiltered = filtered.length > 0;

          let totalRecords = sortedData.length;

          let recordsCountFrom = page * pageSize + 1;

          let recordsCountTo = recordsCountFrom + pageRows.length - 1;

          if (isFiltered)
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} filtered users`;
          else
            recordsInfoText = `Showing ${recordsCountFrom}-${recordsCountTo} of ${totalRecords.toLocaleString()} users`;
        } else recordsInfoText = "No users";

        return (
          <div className="main-grid" style={{height:'1000px',overflowY:'scroll'}}>
            {makeTable()}
            <div style={{marginTop:'30px'}}>
              <div style={{textAlign:'center'}}>{recordsInfoText}</div>
            </div>
          </div>
        );
      }}
    </ReactTable>
    <div style={{marginTop:'10px'}}>{this.generateFilters()}</div>
  </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userList: state.userPerms.userList,
    userBulkFilterPerms: state.userPerms.userBulkFilterPerms,
    DBs: state.userPerms.DBs
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getUsers: () => dispatch(actions.getUsers()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
