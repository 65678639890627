import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Edit from "@material-ui/icons/Edit";
import Lock from "@material-ui/icons/Lock";
import Close from "@material-ui/icons/Close";
import ReactTable from "react-table";
import withStyles from "@material-ui/core/styles/withStyles";
import SweetAlert from "react-bootstrap-sweetalert";
import { FadeLoader } from 'react-spinners';
import Tooltip from "@material-ui/core/Tooltip";
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx";
import { css } from 'react-emotion';
import Button from "../../components/CustomButtons/Button.jsx";
import CustomSelect from "../../components/CustomSelect/CustomSelect.jsx";
import UserAdminModal from "./UserAdminModal";
import UserAdminModalExistingUser from './UserAdminModalExistingUser';
import NotificationAsk from "../../components/Notification/NotificationAsk";
import Notification from "../../components/Notification/Notification";
import { Cached } from "@material-ui/icons";
import { get } from "lodash";

const override = css`
            display: block;
            margin: 0 auto;
            border-color: red;
        `;

class UserAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        addUserModal: false,
        alert: null,
        action: 'Add',
        openModal: false,
        userEdit: null,
        changePassword: false,
        askDetails: null,
        openedAskModal: false,
        openedAddExistingWorkspaceUser: null,
        message: ''
    }
  }

  openModal = (action, userEdit, changePassword) => {
      
      this.setState({ action, openModal:true, userEdit, changePassword });
  }

  closeModal = () => {
      this.setState({ action:null,openModal:false, userEdit: null, changePassword: false });
  }

  openAlert = (alert) => {
      this.setState({ alert });
  }

  closeAlert = () => {
    this.setState({ alert:null });
  }

  openAddUserModal = () => {
    this.setState({addUserModal:true});
  }

  closeAddUserModal = () => {
    this.setState({addUserModal:false});
  }

  openAskModal = (askDetails) => {
    this.setState({
        askDetails,
        openedAskModal: true
    })
  }

  closeAskModal = () => {
      this.setState({
        askDetails: null,
        openedAskModal: false
      });
  }

  renderTable = () => {
      
      if (this.props.userList) {
          this.props.userList.forEach(ul => {
              if (ul.phones) {
                  
              }
          })
      }
       return <ReactTable
        data={
            this.props.userList.map((user, key) => { // map users to table rows
                const workspaceUserType = get(user, `customSchemas.custom_fields_data.is_illi_employee`, '');
                const illiBackendUserType = get(user, `illi_backend_data.type`, ``) === 'E' ? 'Employee' : 'Associate';
                const illi_user_id = get(user, `illi_backend_data.id`, key);
                const illi_user_exists = get(user, `illi_backend_data.id`, '') ? true : false;
                const illi_user_is_active = get(user, `illi_backend_data.active`, '') ? true : false;
                return ({
                    id: key,
                    illi_user_id,
                    string_name: user.name ? `${user.name.givenName} ${user.name.familyName}` : '',
                    name: user.name ? <div>{user.name.givenName} {user.name.familyName}{workspaceUserType !== illiBackendUserType ? 
                        <sup>**</sup> : ''}</div> : '',
                    usertype: workspaceUserType !== illiBackendUserType ? 
                        illiBackendUserType
                    : 
                        workspaceUserType,
                    email: user.primaryEmail,
                    organization: user.orgUnitPath ? user.orgUnitPath.substring(1) : '',
                    status: user.suspended || !illi_user_is_active ? 'Inactive' : 'Active',
                    actions: (
                    <div>
                        {(illi_user_exists && illi_user_is_active) && <div className="actions-right">
                            <Tooltip
                                id="tooltip-top-start1"
                                title="Edit Password"
                                placement="top"
                                classes={{ tooltip: this.props.classes.tooltip }}
                            >
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        this.openModal('Edit', user, true);
                                    }}
                                    color="primary"
                                >
                                    <Lock />
                                </Button>
                            </Tooltip>
                            <Tooltip
                                id="tooltip-top-start2"
                                title="Edit Details"
                                placement="top"
                                classes={{ tooltip: this.props.classes.tooltip }}
                            >
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        this.openModal('Edit', user);
                                    }}
                                    color="rose"
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>
                                {" "}
                                { /* use this button to remove the data row */}
                            <Tooltip
                                id="tooltip-top-start3"
                                title="Remove"
                                placement="top"
                                classes={{ tooltip: this.props.classes.tooltip }}
                            >
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        this.openAskModal(user);
                                    }}
                                    color="danger"
                                    disabled={user.isAdmin ? 
                                        true 
                                    : 
                                        illi_user_exists ? 
                                            false
                                        :
                                            true}
                                    style={{opacity:user.isAdmin || !illi_user_exists ? 0.2 : 1}}
                                >
                                    <Close />
                                </Button>
                            </Tooltip>
                        </div>}
                        {(!illi_user_is_active) && <div style={{height:51,paddingTop:15}}>
                            Google Workspace User Only
                        </div>}
                        {!illi_user_exists && <Button
                                color="white"
                                onClick={() => {
                                    this.setState({
                                        openedAddExistingWorkspaceUser: user
                                    })
                                }}
                            >ADD USER</Button>}
                    </div>
                    )
                })
            })
        }
        filterable
        columns={[
            {
                Header: "Name",
                accessor: "name",
                filterMethod: (filter, row) => {
                var filterValue = filter.value.toLowerCase();
                if (filterValue.length > 0) {
                    if (row._original) {
                        if (row._original.string_name) {
                            return row._original.string_name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                        }
                    }
                } else {
                    return true;
                }
                }
            },
            {
                Header: "Type",
                accessor: "usertype",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen && chosen !== 'All') {
                        return chosen == row[filter.id];
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><CustomSelect
                        options={['All','Associate','Employee']}
                        label={''}
                        choose={onChange}
                    /></div>
                }
            },
            {
                Header: "Email",
                accessor: "email",
                filterMethod: (filter, row) => {
                var filterValue = filter.value.toLowerCase();
                if (filterValue.length > 0 && row[filter.id]) {
                    return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
                } else if (filterValue.length > 0 && !row[filter.id]) {
                    return false;
                } else {
                    return true;
                }
                }
            },
            {
                Header: "Group",
                accessor: "organization",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen.length > 0) {
                        return chosen.indexOf(row[filter.id]) !== -1;
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter">

                    </div>
                }
            },
            {
                Header: "Status",
                accessor: "status",
                filterMethod: (filter, row) => {
                    const chosen = filter.value;
                    if (chosen && chosen !== 'All') {
                        return chosen == row[filter.id];
                    } else {
                        return true;
                    }
                },
                Filter: ({filter, onChange}) => {
                    return <div className="gmp-filter"><CustomSelect
                        options={['All','Active','Inactive']}
                        label={''}
                        choose={onChange}
                    /></div>
                }
            },
            {
                Header: "",
                accessor: "actions",
                sortable: false,
                filterable: false,
            }
        ]}
        defaultPageSize={10}
        showPaginationTop
        showPaginationBottom={false}
        className="-striped"
    />
  }

  componentDidUpdate(prevProps) {
    if (prevProps.syncSuccess != this.props.syncSuccess) {
        if (this.props.syncSuccess) {
            this.openAlert(this.props.syncSuccess);
        }
    }
    if (prevProps.popupMessage != this.props.popupMessage) {
        if (this.props.popupMessage === 'Successfully Created New User') {
            this.closeModal();
            this.openAlert('Successfully Created New User');
        } else if (this.props.popupMessage) {
            this.openAlert(this.props.popupMessage);
        }
    }
    if (prevProps.user_success === null && this.props.user_success) {
        this.openAlert('Successfully Created New User');
    }
    if (prevProps.user_error === null && this.props.user_error) {
        this.openAlert('Something went wrong creating New User, please contact tech support if the issue persists.');
    }
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    this.props.getWorkspaceUsers();
  }

  render() {
    
    if (!checkPermissions('UserAdmin')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    const loader = <FadeLoader
        className={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    />

    const alert = this.state.alert ? <Notification
        open={true}
        close={() => {
            this.closeAlert('')
            this.props.getWorkspaceUsers();
        }}
        message={this.state.alert.indexOf('Successfully') !== -1 ? 
            <div>{this.state.alert}<hr /><p>* Sometimes Google syncing will take an extra moment to update.<br /> Please hit reload if the update does not show yet.</p></div> 
            : 
            <div style={{color:'red'}}>{this.state.alert}</div>}
        /> : null;

    const askModal = <NotificationAsk 
            open={true}
            success={(() => {
                console.log('this.state.askDetails', this.state.askDetails);
                const user_id = get(this.state.askDetails, 'illi_backend_data.id', '');
                if (user_id) {
                    this.props.deleteWorkspaceUser(user_id);
                    this.closeAskModal()
                }
            })}
            close={() => { this.closeAskModal() }}
            title="Delete User?"
            success_button_text="OK, DELETE"
            message={<div>
                <p>Are you sure you want to delete this user?</p>
                <strong>{this.state.askDetails ? 
                    this.state.askDetails.name ? 
                        <div>{this.state.askDetails.name.fullName}</div> 
                    : 
                        '' 
                : 
                    ''}</strong>
                <small><em>
                NOTE: This will only delete the user from the illi server backend,<br /> 
                    Please delete the Google Workspace User in Google Workspace Admin    
                </em></small>
            </div>}
    />

    const table = this.renderTable();

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
            color="rose"
            round
            onClick={() => this.openModal('Add')}>
            Add User
        </Button>
        <Tooltip
            title="Reload"
            placement="top"
            classes={{ tooltip: this.props.classes.tooltip }}
        >
            <Button
                justIcon
                round
                simple
                onClick={() => {
                    this.props.getWorkspaceUsers();
                }}
                color="primary"
            >
                <Cached />
            </Button>
        </Tooltip>
        {/* TO DO: FIX syncing, might be altering User Type */}
        {/* <Button
            color="white"
            round
            onClick={() => {
                this.props.syncUsers(this.props.userList);
            }}>
            Sync Users
        </Button> */}
        {this.props.loading && loader}
        {!this.props.loading && table}
      </GridItem>
      {this.state.alert && alert}
      {this.state.openModal && <UserAdminModal 
            open={this.state.openModal}
            close={this.closeModal}
            action={this.state.action}
            userEdit={this.state.userEdit}
            changePassword={this.state.changePassword}
            createWorkspaceUser={this.props.createWorkspaceUser}
            updateWorkspaceUser={this.props.updateWorkspaceUser}
            userList={this.props.userList}
            groupList={this.props.groupList}
        />}
        {this.state.openedAskModal && askModal}
        {this.state.openedAddExistingWorkspaceUser && <UserAdminModalExistingUser
            close={() => { this.setState({ openedAddExistingWorkspaceUser: null })}}
            user={this.state.openedAddExistingWorkspaceUser}
            createUserFromWorkspace={this.props.createUserFromWorkspace}
        />}
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode,
    userList: state.user.userList ? state.user.userList : [],
    loading: state.user.loading,
    syncSuccess: state.user.syncSuccess,
    popupMessage: state.user.popupMessage,
    user_error: state.user.user_error,
    user_success: state.user.user_success
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    syncUsers: (userList) => {
      dispatch(actions.syncUsers(userList));
    },
    createWorkspaceUser: (userData) => {
        dispatch(actions.createWorkspaceUser(userData));
    },
    deleteWorkspaceUser: (id) => {
        dispatch(actions.deleteWorkspaceUser(id));
    },
    updateWorkspaceUser: (userDetails) => {
        dispatch(actions.updateWorkspaceUser(userDetails));
    },
    createUserFromWorkspace: (user) => {
        dispatch(actions.createUserFromWorkspace(user));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(sweetAlertStyle)(UserAdmin));
